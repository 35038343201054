<template>
  <i :class="['iconfont', `${type}`]"></i>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
@import url("//at.alicdn.com/t/c/font_4750694_k2ppowig51.css");
</style>