<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->

<template>
  <!-- <div>
    <p>当前窗口宽度: {{ windowWidth }}px</p>
    <p>当前窗口高度: {{ windowHeight }}px</p>
  </div> -->
  <!-- <a-drawer :title="drawerTitle" :mask="false" placement="left" :closable="true" v-model:open="showModal.test"
    width="200">

  </a-drawer> -->
  <a-modal :maskClosable="false"
           title="设备信息"
           width="600px"
           v-model:open="showModal.deviceShow"
           footer=''>
    <table class="customerTable">
      <thead>
        <tr>
          <td>品牌</td>
          <td>设备名称</td>
          <td>数量</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='item in deviceMessage'
            :key='item.id'>
          <td>{{item.device_brand}}</td>
          <td>{{item.device_name}}</td>
          <td>{{item.quantity}}</td>
        </tr>
      </tbody>
    </table>

  </a-modal>
  <a-modal :maskClosable="false"
           v-model:open="showModal.show"
           title="客户信息"
           width="1000px"
           @ok="handleOk">
    <div>
      <a-form>
        <table class="customerTable"
               cellspacing="0"
               cellpadding="0">

          <tr>
            <td class="column"
                rowspan="7">基本信息</td>
            <td class="column">品牌<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-select :showArrow="false"
                        :disabled="dynamicValidateForm.product_services.length > 0"
                        v-model:value="baseData.brand"
                        :bordered="false"
                        class="td-select"
                        style="width:150px"
                        show-search
                        :placeholder="`请选择品牌`"
                        :options="options['brand']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('brand',$event)"></a-select>

            </td>
            <td colspan="2"></td>

          </tr>

          <tr>
            <td class="column">门店名称<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-input @blur="storeBlur($event)"
                       v-model:value="baseData.storeName"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入门店名称">
                <!-- <template #prefix><smile-outlined /></template> -->
              </a-input>
            </td>
            <td class="column">

              <a-tooltip>
                <template #title>门店的公司名称</template>
                品牌公司
              </a-tooltip>

            </td>
            <td>
              <a-input v-model:value="baseData.officialName"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入品牌公司">
                <!-- <template #prefix><smile-outlined /></template> -->
              </a-input>
            </td>
          </tr>

          <tr>

            <td class="column">区域<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-cascader :showArrow="false"
                          :bordered="false"
                          class="td-select"
                          @change="regionOnChange"
                          v-model:value="baseData['region']"
                          :show-search="{ filter }"
                          :options="options['region']"
                          :placeholder="`请选择区域`" />

            </td>
            <td class="column">商圈</td>
            <td>
              <a-select :showArrow="false"
                        v-model:value="baseData['business_cluster_id']"
                        :bordered="false"
                        class="td-select"
                        style="width:150px"
                        show-search
                        :placeholder="`请选择商圈`"
                        :options="options['businessCluster']"
                        :filter-option="filterOption"
                        @change='businessClusterChange'>
              </a-select>

            </td>

          </tr>
          <tr>
            <td class="column">门店地址<span style="color: red;margin-left: 0px;">*</span></td>
            <td colspan="3">
              <div style="display: flex; align-items: center;">
                <span style="flex-shrink: 0; white-space: nowrap;">{{ dataSource.messageRegion }}</span>

                <a-input v-model:value="baseData.address"
                         :bordered="false"
                         :class='td-input'
                         placeholder="请输入门店地址" />
              </div>

            </td>

          </tr>

          <tr>

            <td class="column">门店固话</td>
            <td>
              <a-input v-model:value="baseData.telephone"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入门店固话" />
            </td>
            <td class="column">联系人<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-input v-model:value="baseData.contactName"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入联系人" />
            </td>
          </tr>

          <tr>

            <td class="column">联系方式<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-input v-model:value="baseData.phone"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入联系方式" />
            </td>
            <td class="column">计划开通时间<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-config-provider :locale="locale">
                <a-date-picker class="td-select"
                               valueFormat="YYYY-MM-DD"
                               :bordered="false"
                               @change="time=>onOpenChange(time,'planBeginDate')"
                               v-model:value="dataSource.planBeginDate" />
                <!-- @openChange="status=>openChange(status,'planBeginDate')" -->
              </a-config-provider>

              <!-- <TimePickerComponent class="td-select" v-model:value="baseData['planBeginDate']">
              </TimePickerComponent> -->

            </td>
          </tr>
          <tr>

            <td class="column">实际开通时间</td>
            <td>
              <a-config-provider :locale="locale">
                <a-date-picker class="td-select"
                               :bordered="false"
                               valueFormat="YYYY-MM-DD"
                               @change="time=>onOpenChange(time,'actualDate')"
                               v-model:value="dataSource.actualDate"
                               @openChange="status=>openChange(status,'actualDate')" />
              </a-config-provider>
            </td>
            <td class="column">实际结束时间</td>
            <td>
              <a-config-provider :locale="locale">
                <a-date-picker class="td-select"
                               :bordered="false"
                               valueFormat="YYYY-MM-DD"
                               @change="time=>onOpenChange(time,'endDate')"
                               v-model:value="dataSource.endDate"
                               @openChange="status=>openChange(status,'endDate')" />
              </a-config-provider>
            </td>
          </tr>
          <tr>
            <td colspan="5"
                style="height: 5px;"></td>
          </tr>
          <tr>

            <td class="column">产品服务</td>
            <td colspan="4">

              <thead>
                <tr>
                  <td v-if="dynamicValidateForm.product_services.length >0"
                      style="width:200px"
                      class='th-product'>
                    产品名称<span style="color: red;margin-left: 0px;">*</span></td>
                  <td v-if="dynamicValidateForm.product_services.length >0"
                      class='th-product'>计量单位</td>
                  <td v-if="dynamicValidateForm.product_services.length >0"
                      class='th-product'>单价（元）<span style="color: red;margin-left: 0px;">*</span></td>
                  <td v-if="dynamicValidateForm.product_services.length >0"
                      class='th-product'>数量（个）<span style="color: red;margin-left: 0px;">*</span></td>
                  <td style="width:150px;"><a style="color:#1884ea"
                       @click="addProduct">增加产品</a>
                  </td>

                </tr>
              </thead>
              <tbody v-for="(item,index) in dynamicValidateForm.product_services"
                     :key="item.id">
                <tr>
                  <td style="width:200px">
                    <!-- <a-cascader :showArrow="false"
                                class='td-select'
                                :bordered="false"
                                v-model:value="item.product_id"
                                style="width:200px"
                                :show-search="{ filter }"
                                :options="options.productService"
                                placeholder="选择产品"
                                @change="(value,selectedOptions) => handleChangeServer(value,selectedOptions,index)" /> -->
                    <a-select :showArrow="false"
                              v-model:value="item.product_id"
                              :bordered="false"
                              class="td-select"
                              style="width:150px"
                              show-search
                              :placeholder="`选择产品`"
                              :options="options.productService"
                              :filter-option="filterOption"
                              @change='(value,selectedOptions) => handleChangeServer(value,selectedOptions,index)'>
                    </a-select>

                  </td>
                  <td>

                    <div style="display:flex">

                      {{item.billing_mode}}
                    </div>
                  </td>
                  <td>
                    <a-input-number :step="0.01"
                                    :disabled="item.details && item.details.length != 0"
                                    style="width: auto;margin-left:-10px"
                                    :bordered="false"
                                    v-model:value="item.unit_price "
                                    :controls="false"
                                    placeholder="价格"
                                    @change="(e) => handleChangePrice(e)" />
                  </td>
                  <td>
                    <div style="display:flex">
                      <a-input-number style="width: auto;margin-left:-10px"
                                      :disabled="item.details && item.details.length != 0"
                                      :bordered="false"
                                      v-model:value="item.quantity"
                                      :controls="false"
                                      placeholder="数量"
                                      @change="(e) => handleChangePrice(e)" />
                      <!-- {{item.billing_mode}} -->
                    </div>
                  </td>

                  <td>
                    <div>
                      <a style="color:#1884ea"
                         @click="removeUser(item)">删除</a>
                    </div>
                  </td>
                </tr>

                <tr v-if='item.details'>
                  <td colspan="5">
                    <table v-if='item.details.length != 0'>
                      <tbody>
                        <tr>
                          <td class='th-sub-product'>子产品名称</td>
                          <td class='th-sub-product'>类型</td>
                          <td class='th-sub-product'>计费单位</td>
                          <td class='th-sub-product'>子产品价格</td>
                          <td class='th-sub-product'> 子产品数量</td>
                          <td><a style="color:#1884ea;white-space: nowrap;"
                               @click="addProduct('details',index)">增加子产品</a></td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr v-for="child,childrenIndex in item.details"
                            :key="child.id">
                          <td>
                            <!-- <a-cascader :showArrow="false"
                                        class='td-select'
                                        :bordered="false"
                                        v-model:value="child.product_id"
                                        style="width:200px"
                                        :show-search="{ filter }"
                                        :options="options.productSubService"
                                        @change="(value,selectedOptions) => handleChangeServer(value,selectedOptions,index,'details',childrenIndex,)"
                                        placeholder="选择产品" /> -->
                            <!-- <a-input v-model:value="child.name"
                                     class='td-input'
                                     :bordered="false"
                                     placeholder="请输入产品名称" /> -->

                            <a-select v-model:value="child.name"
                                      placeholder="请输入或选择"
                                      show-search
                                      allow-clear
                                      style="width: 200px"
                                      :filter-option="filterOption"
                                      :options='options.subTemplate'
                                      @change="(value,option)=>templateChange(value,option,index,childrenIndex,'sub')"
                                      @search="(value)=>onSearch(value,index,childrenIndex,'sub')"
                                      @blur="(value,option)=>onBlur(value,index,childrenIndex,'sub')">
                            </a-select>

                          </td>
                          <td>
                            <a-select v-model:value="child.product_sub_type"
                                      placeholder="请输入或选择"
                                      show-search
                                      allow-clear
                                      style="width: 150px"
                                      :filter-option="filterOption"
                                      :options='options.subProductType'>
                            </a-select>
                          </td>

                          <td>
                            <!-- {{ child.billing_mode }} -->
                            <a-select style="width:100px"
                                      :bordered="false"
                                      v-model:value="child.billing_mode"
                                      show-search
                                      :placeholder="`请选择收费类型`"
                                      :options="options.billing_mode"
                                      :filter-option="filterOption"></a-select>
                          </td>
                          <td> <a-input-number :step="0.01"
                                            style="width: auto;margin-left:-10px"
                                            :bordered="false"
                                            v-model:value="child.unit_price"
                                            :controls="false"
                                            placeholder="价格"
                                            @change="(e) => handleChangePrice(e,'details',childrenIndex)" /></td>
                          <td> <a-input-number style="width: auto;margin-left:-10px"
                                            :bordered="false"
                                            v-model:value="child.quantity"
                                            :controls="false"
                                            placeholder="数量"
                                            @change="(e) => handleChangePrice(e,'details')" /></td>
                          <td><a style="color:#1884ea;white-space: nowrap;"
                               @click="removeUser(child,'details',index)">删除子产品</a>
                            <a v-if='child.devices'
                               style="color:#1884ea;white-space: nowrap;"
                               @click="lookDevices(child.devices)">查看设备</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

              </tbody>
          <tr v-if="dynamicValidateForm.product_services.length >0">

            <td colspan="4">
              <div style="display: flex; justify-content: flex-end;  margin-right:45px">
                <div>总价：<strong>{{baseData.total_price}}</strong> 元</div>
              </div>
            </td>
            <td></td>
          </tr>

          </td>

          <!-- <td colspan="4" v-else></td> -->
          </tr>
          <tr>
            <td colspan="5"
                style="height: 5px;"></td>
          </tr>
          <tr>
            <td class="column"
                rowspan="4">签约信息</td>
            <td class="column">付费方式<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <a-select class="td-select"
                        :showArrow="false"
                        v-model:value="baseData.paymentType"
                        show-search
                        :bordered="false"
                        :placeholder="`请选择付费方式`"
                        :options="options['paymentType']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('paymentType',$event)"></a-select>
            </td>
            <td class="column">合同签约周期<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              <div style="display:flex; align-items: center; /* 使子元素垂直居中对齐 */">
                <a-input-number style="width:100px auto;margin-left:-10px;margin:2px;vertical-align: middle"
                                v-model:value="baseData.duration"
                                :bordered="false"
                                :controls="false"
                                placeholder="请输入合同签约周期(数字)" />
                <a-select style="width:50px;margin:2px;vertical-align: middle"
                          :showArrow="false"
                          v-model:value="baseData.duration_unit"
                          :bordered="false"
                          show-search
                          :placeholder="`请选择`"
                          :options="options['duration_unit']"
                          :filter-option="filterOption"
                          @change="handleChangeSelect('duration_unit',$event)"></a-select>
              </div>

            </td>
          </tr>

          <tr>
            <!-- <td class="column">发票类型</td>
            <td> <a-select :showArrow="false" class="td-select" style="width:190px" :bordered="false"
                v-model:value="baseData.invoice_type" show-search :placeholder="`请选择发票类型`"
                :options="options['invoice_type']" :filter-option="filterOption"
                @change="handleChangeSelect('invoice_type',$event)"></a-select></td> -->

            <!-- <td class="column">发票号</td>
            <td><a-input v-model:value="baseData.invoice_number" class='td-input' :bordered="false"
                placeholder="请输入发票号" /></td> -->
          </tr>
          <tr>
            <td class="column">
              签约公司<span style="color: red;margin-left: 0px;">*</span>
            </td>
            <td>
              <a-input v-model:value="baseData.signed_company"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入签约公司" />
            </td>
            <td class="column">
              签约人<span style="color: red;margin-left: 0px;">*</span>
            </td>
            <td>
              <a-input v-model:value="baseData.signed_person"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入签约人" />
            </td>
          </tr>
          <tr>

            <td class="column">付费金额(元)</td>
            <td>

              <a-input-number :step="0.01"
                              :controls="false"
                              style="width: auto;margin-left:-10px"
                              :bordered="false"
                              v-model:value="baseData.paymentAmount"
                              placeholder="请输入(数字)" />

            </td>
            <!-- <td class="column">付款状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>

              <a-select :showArrow="false" class='td-select' :bordered="false" v-model:value="baseData.payment_status"
                show-search :placeholder="`请选择付款状态`" style="width: 150px" :options="options.payment_status"
                :filter-option="filterOption" @change="handleChangeSelect('payment_status',$event)"></a-select>
            </td> -->
          </tr>

          <tr>
            <td colspan="5"
                style="height: 5px;"></td>
          </tr>

          <tr>
            <td class="column">附件</td>
            <td colspan="4">
              <div>
                <uploadComponent :fileIds='fileList.contacts'
                                 :freshFlag='uploadFresh'
                                 fileType='合同'
                                 @update:fileIds='(event)=>fileChange(event,"contacts")'
                                 buttonName='上传合同'></uploadComponent>

                <uploadComponent :fileIds='fileList.licenses'
                                 fileType='营业执照'
                                 :freshFlag='uploadFresh'
                                 @update:fileIds='(event)=>fileChange(event,"licenses")'
                                 buttonName='上传营业执照'></uploadComponent>
                <uploadComponent :fileIds='fileList.others'
                                 fileType='一般文件'
                                 :freshFlag='uploadFresh'
                                 @update:fileIds='(event)=>fileChange(event,"others")'
                                 buttonName='上传其它'></uploadComponent>

                <!-- <a-upload v-model:file-list="fileList.contacts"
                          :data='{
                  file_type:"contract_attachment",
                  attachment_type:"合同"}'
                          :action=uploadUrl
                          list-type="picture"
                          :headers="headers">
                  <a-button>
                    <upload-outlined></upload-outlined>
                    上传合同
                  </a-button>
                </a-upload>
                <a-upload v-model:file-list="fileList.licenses"
                          :data='{ file_type:"contract_attachment",attachment_type:"营业执照"}'
                          :headers="headers"
                          :action=uploadUrl
                          list-type="picture">
                  <a-button>
                    <upload-outlined></upload-outlined>
                    上传营业执照
                  </a-button>
                </a-upload>
                <a-upload v-model:file-list="fileList.others"
                          :data='{ file_type:"contract_attachment",attachment_type:"其他"}'
                          :headers="headers"
                          :action=uploadUrl
                          list-type="picture">
                  <a-button>
                    <upload-outlined></upload-outlined>
                    上传其他
                  </a-button>
                </a-upload> -->

              </div>
            </td>

          </tr>
          <tr>
            <td class="column">备注</td>
            <td colspan="4">
              <a-input v-model:value="baseData.desc"
                       class='td-input'
                       :bordered="false"
                       placeholder="请输入备注" />
            </td>
          </tr>

        </table>
      </a-form>

    </div>
  </a-modal>
  <a-modal :maskClosable="false"
           title="确认客户信息"
           v-model:open="showModal.message"
           width="700px"
           @ok="handleOkMessage">
    <div>
      <table class="mailTable"
             cellspacing="0"
             cellpadding="0">

        <tr>
          <td class="column">品牌</td>
          <td>{{dataSource.brand}}</td>
          <td class="column">区域</td>
          <td>{{dataSource.messageRegion}}</td>

        </tr>

        <tr>
          <td class="column">门店名称</td>
          <td>{{baseData.storeName}}</td>
          <td class="column">品牌公司</td>
          <td>{{baseData.officialName}}</td>
        </tr>
        <tr>
          <td class="column">商圈</td>
          <td>{{dataSource.businessCluster}}</td>
        </tr>
        <tr>
          <td class="column">门店地址</td>
          <td colspan="3">{{dataSource.messageRegion}} {{baseData.address}}</td>

        </tr>

        <tr>

          <td class="column">门店固话</td>
          <td>{{baseData.telephone}}</td>
          <!-- <td class="column">运行状态</td>
            <td >运行中 <div class="green-light"></div></td> -->
          <td class="column">联系人</td>
          <td>{{baseData.contactName}}</td>
        </tr>

        <tr>

          <td class="column">联系方式</td>
          <td>{{baseData.phone}}</td>
          <td class="column">计划开通时间</td>
          <td>{{baseData.planBeginDate}}</td>
        </tr>
        <tr>

          <td class="column">实际开通时间</td>
          <td>{{baseData.actualDate}}</td>
          <td class="column">实际结束时间</td>
          <td>{{baseData.endDate}}</td>
        </tr>
        <tr>
          <td class="column">
            签约公司
          </td>
          <td>{{baseData.signed_company}}

          </td>
          <td class="column">
            签约人
          </td>
          <td>{{baseData.signed_person}}
          </td>
        </tr>

        <tr>
          <td class="column">付费方式</td>
          <td>{{baseData.paymentType}}</td>
          <td class="column">合同签约周期</td>
          <td>{{baseData.duration}}{{baseData.duration_unit}}</td>
        </tr>

        <tr>
          <td class="column">签约公司</td>
          <td>{{baseData.signed_company}}</td>
          <td class="column">签约人</td>
          <td>{{baseData.signed_person}}</td>
        </tr>
        <tr>
          <td class="column">付费金额</td>
          <td>{{decimalUtils.ensureAtLeastTwoDecimals(baseData.paymentAmount)}}元</td>
        </tr>
        <tr>

          <td class="column">产品服务</td>
          <td colspan="3"
              v-if="dynamicValidateForm.product_services.length>0">
            <thead>
              <tr>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">产品名称</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">计量单位</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">单价（元）</td>
              </tr>
            </thead>
            <tbody v-for="production in dynamicValidateForm.product_services"
                   :key="production.id">
              <tr>
                <td scope="row">{{production.name}}</td>
                <td>{{production.billing_mode}}</td>
                <td> {{ decimalUtils.ensureAtLeastTwoDecimals(production.unit_price)}}</td>
              </tr>
            </tbody>

            <div style="display: flex; justify-content: flex-end;  margin-right:45px">
              <div>总价：<strong>{{baseData.total_price}}</strong> 元</div>
            </div>
          </td>
          <td colspan="3"
              v-else></td>
        </tr>

        <tr>
          <td class="column">描述</td>
          <td colspan="3">{{contractInfoData.desc}}</td>
        </tr>

      </table>

    </div>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.storeName"
           width="700px">
    <div>
      <div style="margin:10px">
        <!-- <a @click="spanClick">用户报修 >  </a> -->
        <!-- <a>用户过户 >  </a>
    <a>用户移机 >  </a> -->
        <a>用户修改 > </a>
        <!-- <a>更改宽带 >  </a>
    <a>用户重建 >  </a>
    <a>更换产品 >  </a> -->
        <a>用户续费 > </a>
        <!-- <a>用户充值   </a>
    <a>人工收费 >  </a>
    <a>停机保号 >  </a> -->
        <a>用户销户 > </a>
        <!-- <a>流量时间 >  </a>
        <a>用户账单 >  </a>
        <a>上网记录 >  </a> -->
        <a @click="ticketsRecord(contractInfoData.contract_user_id)">订单记录 > </a>
        <a @click="pushTickets">添加工单 > </a>
        <!-- <a>打印（未结束||当前||代运行||所有）订单 >  </a> -->

      </div>
      <table class="mailTable"
             cellspacing="0"
             cellpadding="0">

        <tr>
          <td class="column">ID</td>
          <td>{{contractInfoData.id}}</td>
          <td class="column">品牌</td>
          <td>{{contractInfoData.brand}}</td>

        </tr>

        <tr>
          <td class="column">区域</td>
          <td>{{contractInfoData.region}}</td>
          <td class="column">门店名称</td>
          <td>{{contractInfoData.storeName}}</td>

        </tr>
        <tr>
          <td class="column">商圈</td>
          <td colspan="3">{{contractInfoData.businessCluster}}</td>

        </tr>
        <tr>
          <td class="column">门店地址</td>
          <td colspan="3">{{contractInfoData.region}} {{contractInfoData.address}}</td>

        </tr>

        <tr>
          <td class="column">品牌公司</td>
          <td>{{contractInfoData.officialName}}</td>
          <td class="column">门店固话</td>
          <td>{{contractInfoData.telephone}}</td>

        </tr>

        <tr>
          <td class="column">联系人</td>
          <td>{{contractInfoData.contactName}}</td>
          <td class="column">联系方式</td>
          <td>{{contractInfoData.phone}}</td>
        </tr>
        <tr>
          <td class="column">计划开通时间</td>
          <td>{{contractInfoData.planBeginDate}}</td>
          <td class="column">运行状态</td>
          <td>运行中 <div class="green-light"></div>
          </td>
        </tr>
        <tr>

          <td class="column">实际开通时间</td>
          <td>{{contractInfoData.actualDate}}</td>
          <td class="column">实际结束时间</td>
          <td>{{contractInfoData.endDate}}</td>
        </tr>

        <tr>
          <td class="column">付费方式</td>
          <td>{{contractInfoData.paymentType}}</td>
          <td class="column">合同签约周期</td>
          <td>{{contractInfoData.duration}}{{contractInfoData.duration_unit}}</td>
        </tr>
        <tr>
          <td class="column">
            签约公司
          </td>
          <td>{{contractInfoData.signed_company}}</td>
          <td class="column">
            签约人
          </td>
          <td>{{contractInfoData.signed_person}}</td>
        </tr>
        <tr>
          <!-- <td class="column">付款状态</td>
          <td>{{contractInfoData.payment_status}}</td> -->
          <td class="column">付费金额</td>
          <td>{{contractInfoData.paymentAmount}}元</td>
        </tr>
        <!-- <tr>
          <td class="column">发票类型</td>
          <td>{{contractInfoData.invoice_type}}</td>
          <td class="column">发票号</td>
          <td>{{contractInfoData.invoice_number}}</td>
        </tr> -->
        <tr>

          <td class="column">产品服务</td>
          <td colspan="3"
              v-if="contractInfoData.productService.length>0">
            <thead>
              <tr>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">产品名称</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">计量单位</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">单价(元)</td>
              </tr>
            </thead>
            <tbody v-for="production in contractInfoData.productService"
                   :key="production.id">
              <tr>
                <td scope="row">{{production.name}}
                </td>
                <td>{{production.quantity}}{{production.billing_mode}}</td>
                <td>{{production.unit_price}}</td>
              </tr>

            </tbody>
            <div style="display: flex; justify-content: flex-end;  margin-right:45px">
              <div>总价：<strong>{{contractInfoData.total_price}}</strong> 元</div>
            </div>
            <!-- {{contractInfoData.productService}} -->

          </td>
          <td colspan="3"
              v-else></td>
        </tr>
        <tr>
          <td class="column">付款信息</td>
          <td colspan="3">

            <thead>
              <tr>

                <td v-if="revenue_records.data.length >0"
                    class='th-product'>类型</td>
                <td v-if="revenue_records.data.length >0"
                    class='th-product'>价格（元）</td>
                <td v-if="revenue_records.data.length >0"
                    class='th-product'>周期</td>
                <td v-if="revenue_records.data.length >0"
                    class='th-product'>付款时间</td>
                <td v-if="revenue_records.data.length >1"
                    style="width:100px;"><a style="color:#1884ea"
                     @click="toggleExpand">{{ isExpanded ? '展开' : '收起' }}</a>
                </td>
                <td v-if="revenue_records.data.length==0">无付款信息</td>
              </tr>
            </thead>
            <template v-if="isExpanded &&revenue_records.data.length!=0 ">
              <tbody>
                <tr>
                  <td> {{revenue_records.data[0].invoice_account_name}}</td>
                  <td> {{ decimalUtils.ensureAtLeastTwoDecimals(revenue_records.data[0].pay_amount) }}</td>
                  <td> {{revenue_records.data[0].pay_duration}}</td>
                  <td> {{revenue_records.data[0].pay_time}}</td>
                </tr>

              </tbody>
            </template>

            <template v-if="!isExpanded">
              <tbody v-for="(item) in revenue_records.data"
                     :key="item.id">
                <tr>
                  <td> {{revenue_records.data[0].invoice_account_name}}</td>
                  <td> {{ decimalUtils.ensureAtLeastTwoDecimals(revenue_records.data[0].pay_amount) }}</td>
                  <td> {{revenue_records.data[0].pay_duration}}</td>
                  <td> {{revenue_records.data[0].pay_time}}</td>
                </tr>

              </tbody>
            </template>

          </td>
        </tr>
        <tr>
          <td class="column">描述</td>
          <td colspan="3">{{contractInfoData.desc}}</td>
        </tr>

      </table>

    </div>
    <template #footer>
      <label style="margin:30px;color:red">状态:****</label>
      <a-button @click="handleCanceltest">申请编辑</a-button>
    </template>

  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="columnShowModal"
           title="选择列显示"
           footer="">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns"
                      :key="column.key"
                      :value="column.key">
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
  <div style="padding:10px">

    <div style='display:flex;margin:5px'
         v-if='searchData.searchViewFlag'>
      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label>关键字</label>
          </div>
          <a-input v-model:value="searchData['keyword']"
                   :placeholder="`请输入关键字`"
                   style="width: 150px"></a-input>
        </a-input-group>
      </div>
      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label>品牌名称</label>
          </div>
          <a-select v-model:value="searchData['brand']"
                    show-search
                    :placeholder="`请选择品牌`"
                    style="width: 150px"
                    :options="options['brand']"
                    :filter-option="filterOption"
                    @change="handleChangeSelect('brand',$event)"></a-select>
        </a-input-group>
      </div>

      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label>区域</label>
          </div>
          <a-tree-select v-model:value="searchData['region']"
                         show-search
                         style="width: 180px"
                         :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder="选择区域"
                         allow-clear
                         :tree-data="options['region']"
                         tree-node-filter-prop="label">
          </a-tree-select>
        </a-input-group>
      </div>
      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label> 计划开通时间</label>
          </div>
          <a-config-provider :locale="locale">
            <a-space direction="vertical"
                     :size="8">
              <a-range-picker @change="time=>onAdvanceChange(time,'planBeginDate')"
                              valueFormat='YYYY-MM-DD'
                              v-model:value="searchData.planBeginDate" />
            </a-space>
          </a-config-provider>
        </a-input-group>
      </div>
      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label>实际开通时间</label>
          </div>
          <a-space direction="vertical"
                   :size="8">
            <a-config-provider :locale="locale">

              <a-range-picker valueFormat='YYYY-MM-DD'
                              @change="time=>onAdvanceChange(time,'actualDate')"
                              v-model:value="searchData.actualDate" />

            </a-config-provider>
          </a-space>
        </a-input-group>
      </div>
    </div>
    <div style='display:flex;margin:10px 5px 5px 5px'
         v-if='searchData.searchViewFlag'>

      <div class="search-item">
        <a-input-group compact>
          <div class='searchLabel'>
            <label>实际结束时间</label>
          </div>
          <a-space direction="vertical"
                   :size="8">
            <a-config-provider :locale="locale">

              <a-range-picker valueFormat='YYYY-MM-DD'
                              @change="time=>onAdvanceChange(time,'actualOverDate')"
                              v-model:value="searchData.actualOverDate" />

            </a-config-provider>
          </a-space>
        </a-input-group>
      </div>
      <div>
        <a-button type="primary"
                  @click="sift"
                  style="margin: 0px;margin-left:10px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 0px;margin-left:5px">重置</a-button>

      </div>
    </div>

    <a-table :pagination="false"
             :scroll="ne"
             :columns="filteredColumns"
             :data-source="dataSource.data"
             :row-selection="rowSelection"
             bordered
             @change="handleTableChange">
      <template #bodyCell="{ column, record }">
        <template v-if="['storeName'].includes(column.dataIndex)">
          <div>
            <a @click="storeNameClick(record.id)">{{ record.storeName }}</a>
          </div>
        </template>
        <template v-if="['operationalStatus'].includes(column.dataIndex)">
          <div class="status-light">
            <a @click="operationalStatusClick(record.id)"
               v-if='record.operationalStatus'
               class="green-light"></a>
            <a @click="operationalStatusClick(record.id)"
               v-if='!record.operationalStatus'
               class="red-light"></a>

          </div>
        </template>

        <template v-else-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
            <a style="margin:2px;color: #1884ea;"
               @click="edit(record)">编辑</a>
            <a style="margin:2px;color: #1884ea;"
               @click="deleteData(record.key)">删除</a>
          </div>
        </template>
      </template>
      <template #title>

        <div class='searchDivContainer'
             style="margin-bottom:5px">

          <a-button type="primary"
                    @click="columnShowModal = true"
                    style="margin: 5px">筛选列显示</a-button>

          <a-button type="primary"
                    @click="openSearchView"
                    style="margin: 5px">{{searchData.buttonValue}}</a-button>

          <div class="right-buttons">
            <a-button class="addDataButton"
                      type="primary"
                      @click="exportData">导出</a-button>
            <a-button v-if="buttonShow.contractAdd"
                      class="addDataButton"
                      type="primary"
                      @click="addData">新增</a-button>
            <a-button v-if="buttonShow.contractDelete"
                      class="addDataButton"
                      type="primary"
                      @click="deleteData">删除
            </a-button>
          </div>

        </div>
        <!-- <table v-if="searchData.searchViewFlag"
               class="customerSearchTable">
          <tr>
            <td class="column">品牌名称</td>
            <td style="width: 50px">
              <a-select :bordered="false"
                        :showArrow="false"
                        v-model:value="searchData['brand']"
                        show-search
                        :placeholder="`请选择品牌`"
                        style="width: 100px"
                        :options="options['brand']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('brand',$event)"></a-select>

            </td>
            <td class="column">区域</td>
            <td>
              <a-tree-select :bordered="false"
                             :showArrow="false"
                             v-model:value="searchData['region']"
                             show-search
                             style="width: 100%"
                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                             placeholder="选择区域"
                             allow-clear
                             :tree-data="options['region']"
                             tree-node-filter-prop="label">

              </a-tree-select>

            </td>
            <td class="column">产品服务</td>
            <td>
              <a-select :bordered="false"
                        :showArrow="false"
                        v-model:value="searchData['allproductService']"
                        show-search
                        :placeholder="`请选择产品服务`"
                        style="width: 200px"
                        :options="options['allproductService']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('allproductService',$event)"></a-select>

            </td>
            <td class="column">计划开通时间</td>
            <td>
              <a-config-provider :locale="locale">
                <a-space direction="vertical"
                         :size="12">
                  <a-range-picker :bordered="false"
                                  :showArrow="false"
                                  @change="time=>onAdvanceChange(time,'planBeginDate')"
                                  v-model:value="searchData.planBeginDate" />
                </a-space>
              </a-config-provider>
            </td>
            <td class="column">实际开通时间</td>
            <td>
              <a-config-provider :locale="locale">
                <a-space direction="vertical"
                         :size="12">
                  <a-range-picker :bordered="false"
                                  :showArrow="false"
                                  @change="time=>onAdvanceChange(time,'actualDate')"
                                  v-model:value="searchData.actualDate" />
                </a-space>
              </a-config-provider>

            </td>
          </tr>
          <tr>

            <td class="column">最小付款金额</td>
            <td>
              <a-input-number :bordered="false"
                              :showArrow="false"
                              style="width:100%"
                              :controls="false"
                              :placeholder="`请输入最小付款金额`"
                              v
                              v-model:value="searchData.payment_amount_min"></a-input-number>

            </td>
            <td class="column">最大付款金额</td>
            <td>
              <a-input-number :bordered="false"  
                              :showArrow="false"
                              style="width:100%"
                              :controls="false"
                              :placeholder="`请输入最大付款金额`"
                              v-model:value="searchData.payment_amount_max"></a-input-number>

            </td>
          </tr>
        </table> -->

      </template>
      <template #footer>
        <div style="display:flex">
          <div style="margin-left:auto">
            <a-pagination size="small"
                          v-model:current="pagination.current"
                          show-quick-jumper
                          :total="pagination.total"
                          @change="pageOnChange"
                          :show-total="total => `总数： ${total} 条`" />
          </div>
        </div>
      </template>
    </a-table>
  </div>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'
import { reactive, ref, computed, onMounted, onBeforeUpdate, watch } from "vue";

import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { selector } from "@/utils/selector"
import { message } from "ant-design-vue";
import interfaces from "@/config";
import { checkTree } from "@/utils/regionTree";
import check from "@/utils/checkLocalStorage";
import router from "@/router";
import dayjs from "dayjs";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import { decimalUtils } from "@/utils/decimalUtils";

import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
const route = useRoute();

import { useScreenSize } from '@/utils/useScreenSize';
const { windowWidth, windowHeight } = useScreenSize();

// console.log("1", windowWidth, windowHeight)



onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  const localMessage = check.checkData();
  console.log("localMessage", localMessage)
  // 检查用户信息是否存在  
  if (!localMessage) {
    // 如果不存在，则跳转到登录页面  
    router.push('/login');
  }
});

dayjs.locale("zh-cn");

//按钮的显示
let buttonShow = reactive({
  contractAdd: true,
  contractDelete: true,
  contractEdit: true,
  contractPage: true,
})

const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}





let pustTicketList = [] //添加工单时所需的数据

const antdLocale = ref(zhCN);
//图片上传路径
const uploadUrl = process.env.VUE_APP_URL + interfaces.commonUpload

const lookPic = process.env.VUE_APP_URL + interfaces.fileGet

const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
  // "Content-Type": "multipart/form-data"
})


//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
});

// 处理表格分页变化
// const handleTableChange = async (paginationNew) => {
//   const { current, pageSize } = paginationNew;

//   pagination.current = current;
//   pagination.pageSize = pageSize;
//   searchFileds.page_num = current;
//   searchFileds.page_size = pageSize;
//   queryPage();

// };

const pageOnChange = (pageNumber, pageSize) => {
  searchFileds.page_num = pagination.current;
  pagination.pageSize = pageSize
  searchFileds.page_size = pageSize;
  queryPage()
  // console.log('Page: ', pageNumber);

}


const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "id",
  "storeName",
  "brand",
  "officialName",
  // "telephone",
  "contactName",
  "phone",
  "planBeginDate",
  // "actualDate",
  "endDate",
  "region",
  "full_address",
  "paymentType",
  "duration",
  "payment_status",
  "paymentAmount",
  "invoice_type",
  "invoice_number",
  // 'business_cluster_name',
  // "desc",
  // "productService",
  "create_time",
  "owner",
  "operationalStatus",
  "operation"


]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);
const ne = reactive({
  x: "",
  y: 650
})
// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  const data = columns.filter((column) => selectedColumns.value.includes(column.key))
  console.log('data')
  return data;
});



// 处理模态框确定按钮点击事件
// const columnHandleOk = () => {
//   columnShowModal.value = false;
//   let x = 0
//   data.forEach(item => {
//     x += item.width
//   })
//   ne.x = x
//   console.log(ne.x)
// };

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

let showModal = reactive({
  show: false,
  advancedSearch: false,
  storeName: false,
  buttonShow: localStorage.getItem("name") == 'root' ? true : false, //控制删除和编辑按钮的显示
  message: false,
  isDeleteModalVisible: false,
  // test: true
});




//列名字段
const columns = [
  // { title: "ID", dataIndex: "id", key: "id", fixed: "left", width: 80 },
  { title: "门店名称", dataIndex: "storeName", key: "storeName", width: 150, ellipsis: true, },

  {
    title: "品牌名称",
    dataIndex: "brand",
    key: "brand",
    width: 150,
    ellipsis: true,
  },
  {
    title: "区域",
    dataIndex: "region",
    key: "region",
    // fixed: "left",
    width: 200,
    ellipsis: true,
  },

  // {
  //   title: "品牌公司",
  //   dataIndex: "officialName",
  //   key: "officialName",
  //   width: 200,
  //   ellipsis: true,
  // },


  {
    title: "商圈",
    dataIndex: "business_cluster_name",
    key: "business_cluster_name",
    width: 200,
    ellipsis: true,
  },

  { title: "门店固话", dataIndex: "telephone", key: "telephone", width: 150, ellipsis: true, },
  { title: "联系人", dataIndex: "contactName", key: "contactName", width: 100, ellipsis: true, },
  { title: "联系方式", dataIndex: "phone", key: "phone", width: 120, ellipsis: true, },
  {
    title: "计划开通时间",
    dataIndex: "planBeginDate",
    key: "planBeginDate",
    width: 120,
    ellipsis: true,
  },
  {
    title: "实际开通时间",
    dataIndex: "actualDate",
    key: "actualDate",
    width: 120,
    ellipsis: true,
  },
  {
    title: "实际结束时间",
    dataIndex: "endDate",
    key: "endDate",
    width: 120,
    ellipsis: true,
  },

  { title: "门店地址", dataIndex: "full_address", key: "full_address", width: 200, ellipsis: true },
  { title: "付费方式", dataIndex: "paymentType", key: "paymentType", width: 100, ellipsis: true, },
  { title: "合约签约周期", dataIndex: "duration", key: "duration", width: 120, ellipsis: true, },
  // { title: "付款状态", dataIndex: "payment_status", key: "payment_status", width: 100, ellipsis: true, },

  { title: "付费金额", dataIndex: "paymentAmount", key: "paymentAmount", width: 100, ellipsis: true, },
  // { title: "发票类型", dataIndex: "invoice_type", key: "invoice_type", width: 150, ellipsis: true, },
  // { title: "发票号", dataIndex: "invoice_number", key: "invoice_number", width: 150, ellipsis: true, },
  { title: "描述", dataIndex: "desc", key: "desc", width: 200, ellipsis: true, },
  { title: "产品服务", dataIndex: "productService", key: "productService", width: 150, ellipsis: true, },
  // { title: "附件类型", dataIndex: "attachmentsType", key: "attachmentsType" },
  { title: "操作人", dataIndex: "owner", key: "owner", width: 100, ellipsis: true, },

  // { title: "附件", dataIndex: "attachments", key: "attachments" },
  // {
  //   title: "运行状态",
  //   dataIndex: "operationalStatus",
  //   key: "operationalStatus",
  //   width: 100,
  // },

  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: 200,
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    width: 200,
  },
];
//model表格字段
const formName = [
  {
    title: "品牌名称",
    dataIndex: "brand",
    key: "brand",
    tips: "请输入品牌名称",
  },
  { title: "区域", dataIndex: "region", key: "region", tips: "请选择区域" },
  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    tips: "请输入门店名称",
  },
  {
    title: "品牌公司",
    dataIndex: "officialName",
    key: "officialName",
    tips: "请输入品牌公司",
  },
  {
    title: "门店地址",
    dataIndex: "address",
    key: "address",
    tips: "请输入门店地址",
  },
  {
    title: "门店固话",
    dataIndex: "telephone",
    key: "telephone",
    tips: "请输入门店固话",
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    tips: "请输入联系人",
  },
  {
    title: "联系方式",
    dataIndex: "phone",
    key: "phone",
    tips: "请输入联系方式",
  },
  {
    title: "计划开通时间",
    dataIndex: "planBeginDate",
    key: "planBeginDate",
    tips: "请选择计划开通时间",
  },

  {
    title: "付费方式",
    dataIndex: "paymentType",
    key: "paymentType",
    tips: "请选择付费方式",
  },
  {
    title: "合约签约周期",
    dataIndex: "duration",
    key: "duration",
    tips: "请输入合约签约周期",
  },
  {
    title: "付款状态",
    dataIndex: "payment_status",
    key: "cost_amount",
    tips: "请选择付费状态",
  },
  {
    title: "实际开通时间",
    dataIndex: "actualDate",
    key: "actualDate",
    width: 120,
  },
  {
    title: "实际结束时间",
    dataIndex: "endDate",
    key: "endDate",
    width: 120,
  },
  { title: "付费金额", dataIndex: "paymentAmount", key: "paymentAmount" },
  { title: "发票类型", dataIndex: "invoice_type", key: "invoice_type" },
  { title: "发票号", dataIndex: "invoice_number", key: "invoice_number" },
  { title: "描述", dataIndex: "desc", key: "desc" },
  { title: "产品服务", dataIndex: "productService", key: "productService" },
  // { title: "附件类型", dataIndex: "attachmentsType", key: "attachmentsType" },
  { title: "附件", dataIndex: "attachments", key: "attachments" },
];
const rules = {
  planBeginDate: [
    {
      required: true,
      message: "",
    },
  ],
  brand: [
    {
      required: true,
      message: "",
    },
  ],
  region: [
    {
      required: true,
      message: "",
    },
  ],
  storeName: [
    {
      required: true,
      message: "",
    },
  ],
  // officialName: [
  //   {
  //     required: true,
  //     message: "",
  //   },
  // ],
  address: [
    {
      required: true,
      message: "",
    },
  ],
  telephone: [
    {
      required: true,
      message: "",
    },
  ],
  contactName: [
    {
      required: true,
      message: "",
    },
  ],
  phone: [
    {
      required: true,
      message: "",
    },
  ],
  duration: [
    {
      required: true,
      message: "",
    },
  ],
  payment_status: [
    {
      required: true,
      message: "",
    },
  ],
  paymentType: [
    {
      required: true,
      message: "",
    },
  ],
};
//table数据源
let dataSource = reactive({
  data: [],
  actualDate: '',
  endDate: "",
  planBeginDate: "",
  region: "",
  brand: "",   //确认客户信息的品牌字段
  messageRegion: "",  //确认客户信息的区域字段
  productions: "", //确认客户信息的产品字段
  businessCluster: "",//确认客户信息的商圈字段

});

// 添加弹框模型
let baseData = reactive({
  id: "",
  signed_company: '',//签约抬头 乙方
  signed_person: "",//签约人 乙方
  business_cluster_id: "", //商圈id
  brand: "",
  brandId: "",
  region: "",
  storeName: "",
  officialName: "",
  address: "",
  telephone: "",
  contactName: "",
  phone: "",
  productService: "",
  planBeginDate: "",
  actualDate: "",
  endDate: "",
  paymentType: "",
  paymentAmount: "",
  invoice_type: "",
  invoice_number: "",
  payment_status: "",
  duration: "",
  desc: "",
  attachments: "",
  attachmentsType: "",
  contract_user_id: "",
  total_price: "",

  duration_unit: "年",

});

let searchData = reactive({
  brand: null,
  region: null,
  allproductService: null,
  paymentType: null,
  payment_status: "",

  payment_amount_min: "",
  payment_amount_max: "",

  invoice_type: "",
  invoice_number: "",
  desc: "",
  planBeginDate: "",
  actualDate: "",
  begin_date_min: "",
  begin_date_max: "",
  planed_begin_date_min: "",
  planed_begin_date_max: "",
  buttonValue: "关闭搜索",
  searchViewFlag: true, //搜索框是否打开

  keyword: '',
  end_date_start: "",
  end_date_min: "",

});


//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}



//页面筛选
const sift = () => {
  pagination.current = 1;
  pagination.pageSize = 10;
  const data = {
    keyword: searchData.keyword,
    planed_begin_date_min: searchData.planed_begin_date_min,
    planed_begin_date_max: searchData.planed_begin_date_max,
    begin_date_min: searchData.begin_date_min,
    begin_date_max: searchData.begin_date_max,
    payment_type: searchData.paymentType,
    payment_amount_min: searchData.payment_amount_min,
    payment_amount_max: searchData.payment_amount_max,
    end_date_start: searchData.end_date_start,
    end_date_min: searchData.end_date_min,
    desc: searchData.desc,
    customer_type: "行业客户",
    customer_ids: searchData.brand,
    product_ids: searchData.allproductService,
    region_ids: [searchData.region],
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };

  for (let key in data) {
    console.log(key, data[key]);
    data[key] = data[key] == undefined || data[key] == "" ? null : data[key];
  }
  data.customer_ids = data.customer_ids != null ? [data.customer_ids] : null;
  data.product_ids = data.product_ids != null ? [data.product_ids] : null;
  searchFileds = data;
  console.log(data);
  queryPage();
};

//重置筛选
const resetSift = () => {
  pagination.current = 1;
  pagination.pageSize = 10;
  let data = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  searchFileds = data;
  queryPage();
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null;

    }
  }
  message.success("重置成功")
};

let options = reactive({
  subTemplate: [],
  subProductType: [],
  duration_unit: [{ label: "年", value: "年" }, { label: "月", value: "月" }],

  search: [
    {
      label: "模糊搜索",
      value: "keyword",
    },
  ], //搜索框的options
  region: [], //地区
  brand: [
    {
      name: "已支付",
      value: "已支付",
    },
    {
      name: "未支付",
      value: "未支付",
    },
  ], //品牌
  productService: [], //产品
  productSubService: [],//子产品
  allproductService: [], //所有的产品
  attachmentsType: [],
  payment_status: [
    {
      name: "已支付",
      value: "已支付",
    },
    {
      name: "未支付",
      value: "未支付",
    },
  ],
  paymentType: [
    {
      name: "年",
      value: "年",
    },
    {
      name: "月",
      value: "月",
    },
  ],
  invoice_type: [
    {
      name: "增值税专用发票(6%)",
      value: "增值税专用发票(6%)",
    },
    {
      name: "增值税专用发票(3%)",
      value: "增值税专用发票(3%)",
    },
    {
      name: "增值税普通发票(6%)",
      value: "增值税普通发票(6%)",
    },
    {
      name: "增值税普通发票(3%)",
      value: "增值税普通发票(3%)",
    },
  ],
  billing_mode: []
});


const getRegionTree = async () => {

  const tree = await checkTree()
  options.region = tree[0]["children"]
}

let url = "";
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData.duration_unit = '年'
  baseData.paymentAmount = 0;
  baseData.desc = "";
  // console.log(dayjs())

  // dataSource.planBeginDate = dayjs();
  // dataSource.actualDate = dayjs();
  // dataSource.endDate = dayjs();


  dataSource.planBeginDate = "";
  dataSource.actualDate = "";
  dataSource.endDate = "";

  dataSource.messageRegion = "";
};

const exportData = () => {
  serviceAxios({
    url: interfaces.custmerExport,
    method: "post",
    data: {},
  })
    .then((res) => {
      let excelName = res.data.data.excel_name;

      serviceAxios({
        url: interfaces.contractFileExcel,
        method: "get",
        responseType: "blob",
        params: {
          file_name: excelName,
        },
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // 创建一个a标签
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob); // 创建指向blob对象的URL
          link.setAttribute("download", excelName); // 设置下载的文件名
          document.body.appendChild(link); // 将a标签添加到页面中
          link.click(); // 触发下载
          document.body.removeChild(link); // 下载完成后移除a标签
        })
        .catch();
    })
    .catch();
};

//添加
const addData = () => {
  uploadFresh.value = !uploadFresh.value
  url = interfaces.contractAdd;
  // dataSource.actualDate = "";
  // dataSource.planBeginDate = "";
  dataSource.address = "";
  baseData.contract_user_id = null; //新用户
  showModal.show = true;
  resetForm();
  dynamicValidateForm.product_services = [];
  for (let key in fileList) {
    fileList[key] = [];
  }
};



//编辑数据
const edit = (record) => {
  uploadFresh.value = !uploadFresh.value
  url = interfaces.contractEdit;
  showModal.show = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData["region"] = record["regionIds"];
  baseData["brand"] = record["brandId"];
  baseData["business_cluster_id"] = record["business_cluster_id"];
  dataSource.businessCluster = record["business_cluster"] != undefined ? record["business_cluster"].name : "";
  dataSource.brand = record.brand
  dataSource.messageRegion = record.region
  dataSource.address = record.region
  dataSource["planBeginDate"] = record["planBeginDate"]
  dataSource["endDate"] = record["endDate"]
  let actualDate = record["actualDate"] == undefined ? "" : record["actualDate"];
  getProductService()
  getdetailsService()
  dataSource["actualDate"] = actualDate;
  baseData.contract_user_id = record["contract_user_id"];
  let product_services = [];
  record["productData"].forEach(item => {
    if (item.details) {
      item.details.forEach(detail => {
        detail['product_id'] = detail.product_id
        detail['name'] = detail.product_name
        detail['product_name'] = detail.product_name
        detail['unit_price'] = detail.unit_price
      })
    }

    product_services.push({
      product_id: item.product_id,
      id: item.id,
      quantity: item.quantity,
      name: item.name,
      product_name: item.name,
      unit_price: item.unit_price,
      billing_mode: item.billing_mode,
      product_sub_type: item.product_sub_type,
      details: item.details
    })

  })
  dynamicValidateForm.product_services = product_services;
  console.log('dynamicValidateForm.product_services', dynamicValidateForm.product_services)
  baseData.total_price = decimalUtils.ensureAtLeastTwoDecimals(priceChange())
  for (let key in fileList) {
    fileList[key] = [];
  }
  if (record["attachments"].length != 0) {
    record["attachments"].forEach((item) => {
      console.log('item', item)
      if (item.type.includes('合同')) {
        // fileList.contacts.push(item.id
        // );

        fileList.contacts.push({
          file_id: item.id,
          owner_class: item.owner_class,
          owner_id: item.id
        });




      } else if (item.type.includes('营业执照')) {
        // fileList.licenses.push(
        //   item.id,
        // );
        fileList.licenses.push({
          file_id: item.id,
          owner_class: item.owner_class,
          owner_id: item.id
        });


      } else {
        // fileList.others.push(
        //   item.id,
        // );
        fileList.others.push({
          file_id: item.id,
          owner_class: item.owner_class,
          owner_id: item.id
        });
      }
    });
  }

  console.log('fileList', fileList)

  revenue_records = record.revenue_records
}



//编辑数据
const editData = () => {
}

//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: interfaces.contractDelete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          selectedRowKey.value = []
          message.success("删除成功");
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};
const handleCancelDelete = () => {
  // 取消删除操作，关闭模态框
  showModal.isDeleteModalVisible = false;
};


//提交
const handleOk = () => {
  console.log('baseData', baseData)
  let prodcut = []
  let flag = 1
  let mustInputFields = ["brand", "storeName", "region", "address", "signed_company", "signed_person",
    "contactName", "phone", "paymentType", "duration", "planBeginDate"] //"payment_status"
  mustInputFields.forEach(item => {
    if (baseData[item] == "" || baseData[item] == null) {
      flag = 0
    }
  })


  if (dynamicValidateForm.product_services.length > 0) {
    dynamicValidateForm.product_services.forEach(item => {
      for (let key in item) {
        if (!['id', 'details'].includes(key)) {
          if (item[key] == "" || item[key] == null) {
            message.error(`${key}数据不能为空`)
            console.log("item", item)
            flag = 0
            return
          }
        }
      }
      if (item.details) {
        item.details.forEach(subProduct => {
          for (let key in subProduct) {
            if (!['contract_id', 'key', 'desc', 'operator_id', 'is_default', 'is_deleted', 'is_package', 'product_id'].includes(key)) {
              if (subProduct[key] == "" || subProduct[key] == null) {
                message.error("子产品数据不能为空")
                console.log("子产品数据不能为空", key, subProduct)
                flag = 0
                return
              }
            }

          }
        })

      }

    })
  }
  if (dynamicValidateForm.product_services.length > 0 && flag == 1) {
    dynamicValidateForm.product_services.forEach(item => {
      prodcut.push({
        name: item.name,
        quantity: item.quantity,
        unit_price: item.unit_price,
        id: item.id,
      })
    })

  }
  if (flag == 1) {
    showModal.message = true
    dataSource.productions = prodcut
    console.log("values", dataSource.productions);
  }
  // else {
  //   message.error("请填写完整信息")
  // }

};


//提交
const handleOkMessage = () => {
  let fileListArr = [];
  console.log(fileList)
  for (let key in fileList) {
    if (fileList[key].length > 0) {
      fileList[key].forEach((item) => {
        fileListArr.push(item.file_id == undefined ? item : item.file_id);
      })
    }
  }
  baseData.attachments = fileListArr

  baseData.productService = dynamicValidateForm.product_services;
  baseData.productService.forEach((item) => {
    if (String(item.id).length == 10) {
      item.id = null;
    }
    item.product_id = item.product_id;
    item.product_name = item.name;
    if (item.details) {
      console.log('item.details', item.details)
      item.details = item.details.map(subItem => ({
        product_id: subItem.product_id == "" ? null : subItem.product_id,
        product_name: subItem.name,
        billing_mode: subItem.billing_mode,
        unit_price: subItem.unit_price,
        product_sub_type: subItem.product_sub_type,
        quantity: subItem.quantity
      }))
    }
  });
  const data = {
    id: baseData.id,
    customer_id: baseData.brand,
    region_id: baseData.region[baseData.region.length - 1],
    contract_user_id: baseData.contract_user_id,
    contract_user_name: baseData.storeName,
    official_name: baseData.officialName,
    address: baseData.address,
    contact_name: baseData.contactName,
    phone_number: baseData.phone,
    telephone: baseData.telephone,
    product_services: baseData.productService,
    payment_type: baseData.paymentType,
    duration: baseData.duration,
    duration_unit: baseData.duration_unit,
    payment_amount: baseData.paymentAmount,
    signed_company: baseData.signed_company,
    signed_person: baseData.signed_person,
    business_cluster_id: baseData.business_cluster_id,
    planed_begin_date: baseData.planBeginDate,
    desc: baseData.desc,
    attachment_ids: baseData.attachments,
  };

  data["begin_date"] = baseData.actualDate != null ? baseData.actualDate : null
  data["end_date"] = baseData.endDate != null ? baseData.endDate : null


  serviceAxios({
    url: url,
    method: "post",
    data: data,
  })
    .then((result) => {
      try {
        if (result.data.message == 'success') {
          message.success("成功")
          showModal.show = false;
          showModal.message = false;
          queryPage();
          selectedRowKey.value = [];
        }
        else {
          message.error(result.data.message)
        }
      }
      catch (error) {
        message.error('请求错误')
      }




    })
    .catch((err) => {
      // message.error("请求异常，请联系管理员");
      message.error(err.response.data ? err.response.data.detail : "请求异常，请联系管理员");
      console.log(err);
    });
  queryPage();
}
const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

//区域改变事件
const regionOnChange = (value, selectedOptions) => {
  console.log("selectedOptions", selectedOptions);
  if (selectedOptions != undefined) {
    let region = ''
    selectedOptions.forEach(item => {
      region += item.label
    })
    dataSource.messageRegion = region
  }
  else {
    dataSource.messageRegion = ""
  }
  // console.log("value", value);

  if (value != undefined) {
    baseData.region = value;
    let address = "";
    selectedOptions.forEach((item) => {
      address += item.label + "/";
    });
    dataSource.address = address;
  }
  getProductService()
  getdetailsService()
};

const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};

const onOpenChange = (time, dataIndex) => {
  console.log("onOpenChange.status", time, dataIndex);
  baseData[dataIndex] = time
  time_flag.value = false
};

let time_flag = ref(false)
const openChange = (status, type) => {
  console.log("test", status, dataSource[type])
  if (status == true) {
    // dataSource[type] = dayjs()
    //   time_flag.value = true
    if (dataSource[type] == "") {
      dataSource[type] = dayjs()
      time_flag.value = true
    }
  }
  else {
    if (time_flag.value == true) {
      dataSource[type] = ""
      console.log("last", dataSource[type])
      time_flag.value == false
    }

  }

};
watch(time_flag, (new1, old1) => {
  console.log(old1, new1)
})

const fileList = reactive({
  contacts: [],
  licenses: [],
  others: [],
});





const uploadFresh = ref(true)
const getBrand = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.brand = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};


//品牌等字段改变事件
const handleChangeSelect = (dataIndex, value) => {
  if (dataIndex === "brand") {
    options.productService = [];
    getProductService();
    getdetailsService()

    const selectedOption = options.brand.find(option => option.value === value);
    dataSource.brand = selectedOption.label
  }


};

const handleChangeSearch = (value) => {
  searchData.searchFiled = value;
  // console.log(baseData);
};

//获取包含产品包的产品信息
const getProductService = () => {
  if (commonFun.checkFields(baseData, ['brand', 'region'])) {
    serviceAxios({
      url: interfaces.productPage,
      method: "get",
      params: {
        customer_id: Number(baseData.brand),
        region_id: baseData.region[2],
        product_type: "行业信息化",
        page_num: 1,
        page_size: 99999,
        is_package: 1,
      },
    })
      .then((res) => {
        let data = [];
        let product_tree = {}



        res.data.data.list.forEach((item) => {
          data.push({
            keyword: "",
            label: item.name,
            value: item.id,
            name: item.name,
            unit_price: item.unit_price,
            billing_mode: item.billing_mode,
            product_sub_type: item.product_sub_type, //产品类型
            details: item.children ? item.children.map(child => ({
              product_id: child.id,
              billing_mode: child.billing_mode,
              id: child.id,
              name: child.name,
              product_name: child.name,
              unit_price: child.unit_price,
            })) : item.children,
          });
        });
        options.productService = data;


      })
      .catch((err) => {
        console.log(err)
        message.error("请求异常，请联系管理员");
      });
  }

};

//获取默认产品包的信息
const getdetailsService = () => {
  if (commonFun.checkFields(baseData, ['brand', 'region'])) {
    serviceAxios({
      url: interfaces.productPage,
      method: "get",
      params: {
        customer_id: Number(baseData.brand),
        region_id: baseData.region[2],
        product_type: "行业信息化",
        page_num: 1,
        page_size: 99999,
        // is_package: 0,
      },
    })
      .then((res) => {

        let product_tree = {}

        //获取默认产品
        let defaultProduct = res.data.data.list.filter(item => item.is_default == 1)
        console.log('默认产品', defaultProduct)
        if (defaultProduct.length != 0 && dynamicValidateForm.product_services.length == 0) {
          let defaultData = defaultProduct[0]
          dynamicValidateForm.product_services.push({
            // product_id: [defaultData.product_sub_type, defaultData.id],
            product_id: defaultData.id,
            quantity: defaultData.quantity,
            unit_price: defaultData.unit_price,
            billing_mode: defaultData.billing_mode,
            details: defaultData.children,
          });
          baseData.paymentAmount = defaultData.unit_price;
          baseData.total_price = defaultData.unit_price;

        }

        //获取子产品
        // res.data.data.list.forEach((item) => {
        //   data.push({
        //     keyword: "",
        //     label: item.name,
        //     value: item.id,
        //     name: item.name,
        //     unit_price: item.unit_price,
        //     billing_mode: item.billing_mode,
        //     product_sub_type: item.product_sub_type, //产品类型
        //     details: item.children,
        //   });
        // });

        // //将原来的数据改为二级树
        // data.forEach(item => {
        //   if (product_tree[item.product_sub_type] == undefined) {
        //     product_tree[item.product_sub_type] = {}
        //     product_tree[item.product_sub_type]["children"] = []
        //   }
        //   product_tree[item.product_sub_type]["children"].push(
        //     {
        //       keyword: "",
        //       label: item.label,
        //       value: item.value,
        //       name: item.name,
        //       unit_price: item.unit_price,
        //       billing_mode: item.billing_mode,
        //       details: item.details,
        //     }
        //   )

        // })
        // console.log("product_tree", product_tree)
        // let pro_tree = []
        // for (let key in product_tree) {
        //   pro_tree.push({
        //     value: key,
        //     label: key,
        //     children: product_tree[key]["children"],
        //   })
        // }
        // options.productSubService = pro_tree;
      })
      .catch((err) => {
        console.log(err)
        message.error("请求异常，请联系管理员");
      });
  }

};

let searchFileds = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

const queryPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: searchFileds,
  })
    .then((res) => {
      pagination.total = res.data.data.total;

      let data = [];
      res.data.data.list.forEach((item) => {
        let productData = [];
        item.product_services.forEach((item1) => {
          productData.push({
            product_id: item1.product_id,
            quantity: item1.quantity,
            unit_price: item1.unit_price,
            product_sub_type: item1.product_sub_type,
            billing_mode: item1.billing_mode,
            id: item1.id,
            details: item1.children,
            name: item1.product_name,
          });
        });
        console.log('item', item)
        data.push({
          id: item.id,
          key: item.id,
          contract_user_id: item.contract_user_id,
          brand: item.customer.name,
          brandId: item.customer.id,
          region: item.region.friendly_name,
          regionIds: item.region.full_path_ids,
          business_cluster_id: item.contract_user.business_cluster_id,
          business_cluster: item.contract_user.business_cluster,
          business_cluster_name: item.business_cluster != undefined ? item.business_cluster.name : null,
          storeName: item.contract_user.name,
          officialName: item.contract_user.official_name,
          phone: item.contract_user.phone_number,
          address: item.contract_user.address,  //item.region.friendly_name + 
          full_address: item.region.friendly_name + item.contract_user.address,
          telephone: item.contract_user.telephone,
          contactName: item.contract_user.contact_name,
          signed_company: item.signed_company,
          signed_person: item.signed_person,
          duration_unit: item.duration_unit,
          productService: "productStrList",
          productData: productData,
          planBeginDate: item.planed_begin_date,
          // actualDate: item.begin_date + "至" + item.end_date,
          actualDate: item.begin_date,
          endDate: item.end_date,
          paymentType: item.payment_type,
          duration: item.duration,
          // payment_status: item.payment_status,
          paymentAmount: item.payment_amount,
          // invoice_type: item.invoice_type,
          // invoice_number: item.invoice_number,
          attachments: item.attachments,
          owner: item.owner.username,
          desc: item.desc,
          operationalStatus: "true",
          create_time: item.create_time,

          revenue_records: item.revenue_records,  //付款信息
        });
      });



      // serviceAxios({
      //   url: interfaces.ops_zabbix_trigger_problems,
      //   method: "get",

      // }).then(
      //   result => {
      //     result.data.data.forEach(item => {
      //       let foundItem = data.find(element => element.id == item.id)
      //       if (foundItem) {
      //         foundItem.operationalStatus = false;
      //       } else {
      //         // console.log("");
      //       }
      //     })
      //   }
      // ).catch(err => { console.log(err), message.error("获取警报信息异常，请联系管理员") })


      dataSource.data = data;
    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
};

// const formRef111 = ref();

const dynamicValidateForm = reactive({
  product_services: [
    // {    product_id: "1",
    // quantity: "1",
    // unit_price:"1",
    // id: 1}
  ],
});

const addProduct = (type, index) => {
  // type:区分添加产品还是子产品
  // index：确定产品的下标
  const flag = commonFun.checkFields(baseData, ['brand', 'region'])
  if (flag) {
    if (type == 'details') {
      //添加子产品
      dynamicValidateForm.product_services[index].details.push({
        product_id: "",
        quantity: 1,
        unit_price: "",
        billing_mode: "",
        id: Math.round(Date.now() / 1000),
      })
      console.log('添加产品', type, index, dynamicValidateForm)

    }
    else {
      //添加产品
      dynamicValidateForm.product_services.push({
        product_id: "",
        quantity: 1,
        unit_price: "",
        billing_mode: "",
        id: Math.round(Date.now() / 1000),
        details: []
      });
      console.log(dynamicValidateForm);
    }

  }
  else {
    message.error("请先选择品牌和区域")
  }

};
const onFinish = (values) => {
  console.log("Received values of form:", values);
  console.log(
    "dynamicValidateForm.product_services:",
    dynamicValidateForm.product_services
  );
};

//获取附件类型
const getAttachmentsType = () => {
  serviceAxios({
    url: interfaces.contractAttachmentType,
    method: "get",
    params: {},
  })
    .then((res) => {
      options.attachmentsType = res.data.data;
    })
    .catch();
};


//产品服务下拉改变事件
const handleChangeServer = (value, option, index, type, subIndex) => {
  console.log('产品服务-产品名称change', value, option, index, type, subIndex)
  if (value != undefined) {
    console.log(value, option, index);
    if (type != 'details') {
      let data = dynamicValidateForm.product_services[index]
      data.unit_price = option.unit_price;
      data.quantity = 1;
      data.billing_mode = option.billing_mode;
      data.product_name = option.name;
      data.name = option.name;
      data.product_id = value;
      if (option.details) {
        option.details.forEach(item => {
          item["quantity"] = 1

        })
        data.details = option.details;

      }
      else[
        data.details = []
      ]

    }
    else {
      let data = dynamicValidateForm.product_services[index]['details'][subIndex]
      data.unit_price = option.unit_price;
      data.quantity = 1;
      data.billing_mode = option.billing_mode;
      data.name = option.name;
      data.product_id = value;
      data.product_name = option.name;
      // dynamicValidateForm.product_services[index].details = option[1].details;
    }

    console.log(dynamicValidateForm)

    let amount = priceChange();
    baseData.paymentAmount = amount;
    baseData.total_price = decimalUtils.ensureAtLeastTwoDecimals(amount);

  }

};

//产品服务移除服务
const removeUser = (item, type, subIndex) => {
  // item:要删除的数据
  // type：区分是删除子产品还是产品
  if (type != 'details') {
    const index = dynamicValidateForm.product_services.indexOf(item);
    if (index !== -1) {
      dynamicValidateForm.product_services.splice(index, 1);
    }
  }
  else {
    const index = dynamicValidateForm.product_services[subIndex]['details'].indexOf(item);
    if (index !== -1) {
      dynamicValidateForm.product_services[subIndex]['details'].splice(index, 1);
    }
  }

  priceChange();
};

//产品服务统计价格
const priceChange = () => {
  console.log('dynamicValidateForm.product_services', dynamicValidateForm.product_services)
  let amount = 0;
  if (dynamicValidateForm.product_services.length != 0) {
    dynamicValidateForm.product_services.forEach((item) => {
      console.log('item', item, item.quantity != "" && item.unit_price != "")
      // if (item.quantity != "" && item.unit_price != "" && item.quantity != null && item.unit_price != null) {
      //   amount = decimalUtils.addWithPrecision(amount, decimalUtils.multiplyWithPrecision(item.quantity, item.unit_price))
      // }
      item.unit_price = 0
      item.details.forEach(child => {
        try {
          item.unit_price += child.quantity * child.unit_price
        }
        catch (e) {
          console.log("计算错误", e, item.details)
          item.unit_price += 0
        }

      })
      amount += item.unit_price

    });
  }

  return amount
};

//产品服务价格变化事件
const handleChangePrice = (e) => {
  console.log(e)
  if (e != null && e != "") {
    let amount = priceChange();
    baseData.paymentAmount = amount;
    baseData.total_price = decimalUtils.ensureAtLeastTwoDecimals(amount);
  }
  else {
    baseData.paymentAmount = 0;
    baseData.total_price = 0;
  }
  // console.log("eeee", e)

};

//高级搜索字段

const advanceSearchFileds = [
  {
    title: "品牌名称",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "区域",
    dataIndex: "region",
    key: "region",
  },
  {
    title: "产品服务",
    dataIndex: "allproductService",
    key: "allproductService",
  },
  { title: "付费方式", dataIndex: "paymentType", key: "paymentType" },
  { title: "付款状态", dataIndex: "payment_status", key: "cost_amount" },

  { title: "发票类型", dataIndex: "invoice_type", key: "invoice_type" },
  { title: "发票号", dataIndex: "invoice_number", key: "invoice_number" },
  { title: "描述", dataIndex: "desc", key: "desc" },
  {
    title: "计划开通时间",
    dataIndex: "planBeginDate",
    key: "planBeginDate",
  },

  {
    title: "实际开通时间",
    dataIndex: "actualDate",
    key: "actualDate",
  },
  {
    title: "最小付款金额",
    dataIndex: "payment_amount_min",
    key: "payment_amount_min",
  },
  {
    title: "最大付款金额",
    dataIndex: "payment_amount_max",
    key: "payment_amount_max",
  },
];


//搜索时间字段改变事件
const onAdvanceChange = (time, dataIndex) => {
  console.log("onAdvanceChange.status", time, dataIndex);
  if (time != null) {
    if (dataIndex == "planBeginDate") {
      searchData.planed_begin_date_min = time[0];
      searchData.planed_begin_date_max = time[1];
    } else if (dataIndex == "actualDate") {
      searchData.begin_date_min = time[0];
      searchData.begin_date_max = time[1];
    }
    else if (dataIndex == "actualOverDate") {
      searchData.end_date_start = time[0];
      searchData.end_date_min = time[1];
    }
  }
};




//重置高级搜索
const handleCanceladvancedSearch = () => { };

const getAllProduct = () => {
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: {
      product_type: "行业信息化",
      page_num: 1,
      page_size: 99999,
    },
  }).then((res) => {
    let data = [];
    res.data.data.list.forEach((item) => {
      data.push({
        label: item.name,
        value: item.id,
      });
    });
    options.allproductService = data;
  });
};




//点击表格门店名称字段
const storeNameClick = (id) => {
  console.log(id, showModal.storeName)
  getContractInfo(id)
  console.log(contractInfoData)

}

let contractInfoData = reactive({
  id: "",
  brand: "",//品牌名称
  region: "",//区域
  storeName: "",//门店名称
  officialName: "",//品牌公司
  address: "",//门店地址
  telephone: "",//门店固话
  contactName: "",//联系人
  phone: "",//联系方式
  planBeginDate: "",//计划开通时间
  actualDate: "",//实际开通时间
  endDate: "",//合约到期时间
  paymentType: "",//付费方式
  duration: "",//合约签约周期
  payment_status: "",//付款状态
  paymentAmount: "",//付费金额
  invoice_type: "",//发票类型
  invoice_number: "",//发票号
  signed_company: "",//签约公司
  signed_person: "",//签约人
  desc: "",//描述
  productService: "",//产品服务
  attachments: "",//附件
  revenue_records: "",
});



//使用id查看合约信息
const getContractInfo = (id) => {
  serviceAxios({
    url: interfaces.contractGetID,
    method: 'get',
    params: {
      id: id
    }
  }).then(res => {
    console.log(res)
    let data = res.data.data
    contractInfoData.id = data.id
    contractInfoData.brand = data.customer.name
    contractInfoData.region = data.region.friendly_name
    contractInfoData.storeName = data.contract_user.name
    contractInfoData.officialName = data.contract_user.official_name
    contractInfoData.address = data.contract_user.address
    contractInfoData.telephone = data.contract_user.telephone
    contractInfoData.contract_user_id = data.contract_user.id
    contractInfoData.contactName = data.contract_user.contact_name
    contractInfoData.phone = data.contract_user.phone_number
    contractInfoData.planBeginDate = data.planed_begin_date
    contractInfoData.actualDate = data.begin_date
    contractInfoData.endDate = data.end_date
    contractInfoData.paymentType = data.payment_type
    contractInfoData.duration = data.duration
    contractInfoData.duration_unit = data.duration_unit
    contractInfoData.payment_status = data.payment_status
    contractInfoData.paymentAmount = decimalUtils.ensureAtLeastTwoDecimals(data.payment_amount)
    contractInfoData.invoice_type = data.invoice_type
    contractInfoData.invoice_number = data.invoice_number
    contractInfoData.desc = data.desc
    contractInfoData.signed_company = data.signed_company
    contractInfoData.signed_person = data.signed_person
    try {
      contractInfoData.businessCluster = data.contract_user.business_cluster.name


    }

    catch {
      contractInfoData.businessCluster = ''
    }
    revenue_records.data = data.revenue_records
    console.log("revenue_records.data", revenue_records.data)
    let productName = []
    contractInfoData.total_price = 0
    if (data.product_services.length != 0) {
      data.product_services.forEach(item => {
        // productName.push(item.product.name)
        productName.push({
          id: item.id,
          name: item.product.name,
          quantity: item.quantity,
          unit_price: decimalUtils.ensureAtLeastTwoDecimals(item.unit_price),
          billing_mode: item.product.billing_mode
        })
        contractInfoData.total_price = decimalUtils.addWithPrecision(contractInfoData.total_price, item.unit_price)
      })
    }
    contractInfoData.total_price = decimalUtils.ensureAtLeastTwoDecimals(contractInfoData.total_price),
      pustTicketList = [data.customer.id, data.contract_user.id]   //添加工单所需数据
    contractInfoData.productService = productName

    showModal.storeName = !showModal.storeName;

  }).catch(err => {
    console.log(err)
  })
}

const spanClick = () => {
  console.log(11111)
}


const pushTickets = () => {
  //添加工单时所需的数据
  router.push({ path: "/addOrder", query: { brand: pustTicketList[0], storeId: pustTicketList[1] } })

}



//实际结束时间=时间开通时间加上合约签约周期
// const watchEndDate = () => {
//   let paymentType = baseData['paymentType'] //付费方式 年或者月
//   let actualDate = dataSource['actualDate'] //实际结束时间
//   let duration = baseData['duration'] //合约签约周期

//   if (paymentType != '' && actualDate != '' && duration != '') {
//     let num = paymentType == '年' ? 12 * duration : 1 * duration
//   }
// }

//订单记录点击事件
const ticketsRecord = (id) => {
  searchFileds = ({
    page_num: 1,
    page_size: pagination.pageSize,
    contract_user_ids: [id]
  });
  showModal.storeName = false
  queryPage()
}




const isExpanded = ref(true)
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}

//付款信息
let revenue_records = reactive({
  data: []
})


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};



// select选择器
const selectData = () => {
  let selectorFields = {
    businessCluster: interfaces.business_cluster_page, //商圈

  }
  for (let key in selectorFields) {
    serviceAxios({
      url: selectorFields[key],
      method: "get"
    }).then(result => {
      options[key] = result.data.data.list.map(item => ({
        label: item.name,
        value: item.id,
      }))
    }).catch((error) => { message.error(error), console.log(error) })
  }
  console.log("options", options.key)
}
selectData()


const businessClusterChange = (value, option) => {
  // console.log(value, option)
  dataSource.businessCluster = option.label
}

const tableHeight = () => {
  const searchDivContainer = document.querySelector('.searchDivContainer');
  const divHeight = searchDivContainer.offsetHeight + 3;
  const searchTableContainer = document.querySelector('.ant-table-title');
  const searchTableHeight = searchTableContainer.offsetHeight;
  // ne.y = windowHeight.value - 428
  console.log(windowHeight.value, divHeight, searchTableHeight)
}
onMounted(() => {
  tableHeight()

})

const storeBlur = (event) => {
  if (url == interfaces.contractAdd) {
    console.log("失去焦点", event, baseData.storeName, baseData.brand)
    serviceAxios({
      url: interfaces.customerUserPage,
      method: 'get',
      params: {
        customer_id: baseData.brand,
        keyword: baseData.storeName,
        page_num: 1,
        page_size: 10,
      }
    }).then(result => {
      console.log('门店查询', result.data)
      if (result.data.data) {

      }
    }).catch(error => {
      console.log(error)
      message.error('请求错误')
    })
  }

}

//文件上传change事件
const fileChange = (event, type) => {
  console.log(event, type)
  fileList[type] = []

  event.forEach(item => {
    fileList[type].push(item.isOld == true ? item.uid : item.response.data[0].id)
  })

  console.log('fileList', fileList)
}

//定义设备信息字段
let deviceMessage = []
//查看设备
const lookDevices = (devicesData) => {
  console.log('设备信息', devicesData)
  deviceMessage = []
  deviceMessage = devicesData

  showModal.deviceShow = true
}

//定义失去焦点字段变量
let onblurSubValue = ref([])  //sub产品
// 创建一个 10x10 的二维数组
function create2DArray (rows, cols) {
  let arr = [];
  for (let i = 0; i < rows; i++) {
    arr[i] = [];
    for (let j = 0; j < cols; j++) {
      // 可以根据需要初始化每个元素
      arr[i][j] = null; // 或者初始化为具体的值
    }
  }
  return arr;
}

// 使用函数创建一个 10x10 的二维数组
onblurSubValue.value = create2DArray(10, 10);

//模板selector改变
const templateChange = (value, option, index, childrenIndex, type) => {
  console.log(value, option)
  if (type == 'sub') {
    dynamicValidateForm.product_services[index].details[childrenIndex].product_sub_type = option.data.product_sub_type
    dynamicValidateForm.product_services[index].details[childrenIndex].name = option.data.name
    dynamicValidateForm.product_services[index].details[childrenIndex].quantity = 1
    onblurSubValue.value[index][childrenIndex] = value

  }

}
const onBlur = (value, index, childrenIndex, type) => {
  console.log('失去焦点', value, index, childrenIndex, type, onblurSubValue.value[index][childrenIndex])
  if (type == 'sub') {
    dynamicValidateForm.product_services[index].details[childrenIndex].name = onblurSubValue.value[index][childrenIndex]
  }
}

const onSearch = (value, index, childrenIndex, type) => {
  if (value != '') {
    if (type == 'sub') {
      onblurSubValue.value[index][childrenIndex] = value
      console.log('搜索sub', "onblurSubValue.value[index]", onblurSubValue.value[index])
    }

  }

}



(async () => {
  getRegionTree();
  getBrand();
  queryPage();
  getAttachmentsType();
  getAllProduct();
  getButtonRules();
  options.billing_mode = await selector.billingMode()
  options.subTemplate = await selector.subTemplate();
  options.subProductType = await selector.subProductType()
  options.subProductType = options.subProductType.filter(item => item.label != '设备租赁')
})()
</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

.red-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(252, 4, 8);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.mailTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.mailTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.mailTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 18%;
}

.customerTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.customerTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.customerTable tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.customerSearchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.customerSearchTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.customerSearchTable tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 5%;
}

.customerSearchTable tr td.column2 {
  background-color: #eff3f6;
  color: #111111;
  width: 5%;
}

p {
  font-size: 12px;
}

a {
  color: black;
}

.td-input {
  padding-left: 0px;
}

.td-select {
  width: auto;
  margin-left: -11px;
}

.th-product {
  width: 200px;
  background-color: rgb(241, 241, 248);
}

.th-sub-product {
  background-color: rgb(105, 105, 133);
  white-space: nowrap;
}

.ant-drawer-mask {
  pointer-events: none !important; /* 允许鼠标穿透 */
}

.search-item {
  margin-left: 10px;
}
</style>