<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class='container'
       ref='containerRef'>

    <div class='logout-box'>
      <div class='userName'>{{userName}}</div>
      <div class='logout'
           @click='handleLogout'><img src='@/static/home/关机.png'
             alt='退出'
             title='退出'></div>
      <!-- <div>退出</div> -->
    </div>
    <transition name="slide-fade">
      <div v-if='menuShow'
           class='menu-box'
           ref="menuRef">
        <div class='menu-title'>
          <img class='menu-title-image'
               src='@/static/demo/customer/菜单.png' />
          <div class='menu-title-name'>菜单</div>
        </div>

        <a-menu class="current-page-menu"
                v-model:openKeys="state.openKeys"
                v-model:selectedKeys="state.selectedKeys"
                @openChange="onOpenChange"
                @click="handleClickSide"
                @mouseleave="startHideTimer"
                @mouseenter="clearHideTimer"
                :items="navigation_sides"
                mode="inline"
                theme="dark">

        </a-menu>
      </div>
    </transition>
    <transition name="slide-lan">
      <div class='menu-lan'
           @click='viewMenu'
           @mouseenter="viewMenu"
           v-if='!menuShow'>
      </div>
    </transition>

    <div class='container-title'>
      <!-- <img src='@/static/home/标题.png'
           class='container-title-style'> -->
      <div class='container-title-left'>{{brandRef}}</div>
      <div class='container-title-right'>AIOps</div>
    </div>
    <div class='content-container'>
      <router-view></router-view>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, nextTick, onUnmounted, h, reactive } from "vue";
import * as echarts from "echarts";
import china from "@/static/china.json";
import serviceAxios from "@/utils/serviceAxios";
import proxyRequest from "@/utils/proxyRequest";
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll';
import interfaces from "@/config";
import { message } from "ant-design-vue";

import { getCurrentInstance } from "vue";

import { useRouter, useRoute } from 'vue-router';
import { specialRules } from '@/configuration/customer'
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';

const route = useRoute()
const router = useRouter()

const containerRef = ref(null);
let brandRef = ref('域禾集控')
let userName = ref()
const originalWidth = 1920; // 原始宽度
const originalHeight = 1080; // 原始高度

function init (width, height) {
  const element = containerRef.value;

  if (!element) {
    console.error('Container element not found.');
    return;
  }

  // 计算缩放比例
  // const hScale = visibleHeight / height;
  // const wScale = visibleWidth / width;
  console.log('window.inner宽高', window.innerWidth, window.innerHeight)
  const hScale = window.innerHeight / height;
  const wScale = window.innerWidth / width;
  console.log('高宽比', hScale, wScale)
  element.style.transform = `scale(${wScale}, ${hScale})`;
  element.style.transformOrigin = "left top";
  element.style.overflow = 'hidden';

}



onMounted(() => {
  init(1920, 1080);
  window.addEventListener('resize', handleResize);
});
const lazyFun = ref(null);

const handleResize = () => {
  clearTimeout(lazyFun);
  lazyFun.value = setTimeout(() => {
    init(1920, 1080);
  }, 600);
};


onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});



//菜单数据
let navigation_sides = specialRules
const state = reactive({
  rootSubmenuKeys: [],
  collapsed: false,
  selectedKeys: [],
  openKeys: [],
  preOpenKeys: [],
  // mode: 'inline',
  // theme: 'light',
  // selectedKeys: ['1'],
  // openKeys: ['sub1'],
});
state.rootSubmenuKeys = specialRules.map(item => item.id)
const handleClickSide = (menuInfo) => {
  console.log('click ', menuInfo, menuInfo.key);

  if (menuInfo.key != undefined && !menuInfo.key.includes('/noopen')) {
    if (!menuInfo.key.includes('/xinfa')) {
      router.push({ path: menuInfo.key });

    }
    else {
      const token = localStorage.getItem('token')
      window.open(`https://www.ihcc.com.cn/v1/info_dis/point_show/redirect?token=${token}`);

    }
    // window.open('https://www.pointshow.net/#/login', '_blank');
  }
  else {
    message.error('你尚未开通此项服务')
  }


  // breadcrumb_info.value = menuInfo.label;
};
const onOpenChange = (keys) => {
  console.log('侧边栏改变', state.rootSubmenuKeys, keys)
  const latestOpenKey = state.rootSubmenuKeys.filter(item => item == keys[keys.length - 1]);
  if (latestOpenKey.length != 0) {
    state.openKeys = [latestOpenKey[0]];
  }
  else {
    state.openKeys = keys;
  }
}


const menuRef = ref(null);
let menuShow = ref(false);
const viewMenu = () => {
  setTimeout(() => {
    menuShow.value = true
  }, 200)
}

const hideMenu = () => {
  setTimeout(() => {
    menuShow.value = false
  }, 200)
}
let hideTimer = null; // 用于存储定时器的引用
const startHideTimer = () => {
  // 清除之前的定时器
  if (hideTimer) {
    clearTimeout(hideTimer);
  }

  // 启动新定时器
  hideTimer = setTimeout(() => {
    menuShow.value = false;
    hideTimer = null; // 清除定时器引用
  }, 2000); // 2000毫秒后执行
};

const clearHideTimer = () => {
  // 清除定时器
  if (hideTimer) {
    clearTimeout(hideTimer);
    hideTimer = null; // 清除定时器引用
  }
};

// 监听点击事件
const handleClickOutside = event => {
  console.log('点击')
  if (!menuRef.value || menuRef.value.contains(event.target)) {

    return;
  }
  else {
    menuShow.value = false
    clearHideTimer()
  }

  // 关闭侧边菜单
  state.openKeys.value = [];
};

// 初始化监听器
const initClickOutsideListener = () => {
  document.addEventListener('click', handleClickOutside);
};

// 清除监听器
const cleanupClickOutsideListener = () => {
  document.removeEventListener('click', handleClickOutside);
};

// 在组件挂载时添加监听器
onMounted(() => {
  initClickOutsideListener();
});

// 在组件卸载时移除监听器
onUnmounted(() => {
  cleanupClickOutsideListener();
});
//查询用户信息
const queryUser = () => {
  serviceAxios({
    url: interfaces.systemMe,
    method: "get",
    // data:''
  })
    .then((result) => {
      try {
        brandRef.value = result.data.tenant.name
        userName.value = result.data.real_name ? result.data.real_name : result.data.username

        localStorage.setItem('username', userName.value)
        console.log('设置用户名缓存成功')
      }
      catch (error) {
        console.log('获取品牌名称异常', error)
        brandRef.value = '域禾集控'
      }
    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err)
    });
};
queryUser()
const handleLogout = () => {
  serviceAxios({
    url: interfaces.logout,
    method: 'get'
  }).then(
    result => {
      localStorage.clear()
      router.push("/loginView")
    }
  ).catch()
};

</script>

<style lang='less' scoped>
.container {
  font-family: "Microsoft YaHei";
  width: 1920px;
  height: 1080px;
  background: url("@/static/home/大屏切.png") center 0;
  background-repeat: no-repeat;
  transform-origin: 0 0;
  transform: scale(0.975, 0.233333);
  display: flex;
  flex-direction: column;
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  letter-spacing: 8px;
  .container-title-style {
    margin-top: 20px;
  }
  .container-title-left {
    font-family: PangMenZhengDaoBiaoTiTiMianFeiBan-4;
    font-weight: 400;
    font-size: 50px;
    color: #00b4ff;
    font-weight: 700;
    background: linear-gradient(0deg, #37b2ff 0%, #4feefc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container-title-right {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 45px;
    color: #00b4ff;
    margin: 10px 0 0 10px;
    background: linear-gradient(0deg, #37b2ff 0%, #4feefc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.content-container {
  display: flex;
  flex: 1;
}
.left-container {
  width: 410px;
  height: 100%;
  opacity: 0.9;
}
.middle-container {
  width: 1052px;
  height: 100%;
  margin-left: 20px;
}
.right-container {
  width: 414px;
  height: 100%;
  margin-left: 23px;
}
.left-up {
  background: url("@/static/home/左上.png");
  width: 410px;
  height: 294px;
  display: flex;
  flex-direction: column;
  .gateway-chart {
    margin: 66px 0 0 24px;
  }
  .left-up-content-box {
    display: flex;
  }
  .left-up-rank {
    margin: 47px 0 0 31px;
  }
  .rank-item {
    margin-bottom: 20px;
  }
  .rank-item-up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .type-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #6889c0;
  }
  .type-percent {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #00cccd;
  }
  .rank-line {
    width: 164px;
    height: 14px;
    background: #3e5596;
    border-radius: 6px;
  }
}
.left-down {
  margin-top: 15px;
  background: url("@/static/home/左下.png");
  width: 410px;
  height: 656px;
  .alert-down-up-box {
    height: 250px;
    padding-top: 32px;
  }
  .alert-type {
    width: 294px;
    height: 38px;
    background: #2941a7;
    border-radius: 19px;
    margin: 30px 39px 0 33px;
    display: flex;
    padding: 0 23px 0 21px;
    justify-content: space-between;
    .alert-type-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cycle-point {
      width: 10px;
      height: 10px;
      background: #7fc458;
      border-radius: 50%;
      margin-right: 12px;
    }
    .alert-type-item-name {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #9ac3f1;
    }
  }
  .alert-type-statistics {
    display: flex;
    padding: 0 20px 0 20px;
    justify-content: space-between;
  }

  .alert-type-statistics-item {
    width: 88px;
    height: 62px;

    /* 创建从上到下的线性渐变 */
    // background: linear-gradient(
    //   to bottom,
    //   #1a3b96,
    //   #00b8d4
    // ); /* 从#66F6E9到另一个颜色 */
    background: #1a3b96;

    border-radius: 10px 0px 10px 0px;
    opacity: 0.95;
  }

  .alert-type-statistics-number {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 18px;
    color: #00d5e9;
    margin: 10px 0 0px 0;
  }
  .alert-type-statistics-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 14px;
    color: #85a9d7;
  }

  .left-down-down-title {
    display: flex;
    margin: 25px 0 0 23px;
    align-items: center;
    .left-down-down-title-line {
      width: 3px;
      height: 11px;
      background: #00cccd;
      margin-right: 10px;
    }
    .left-down-down-title-name {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 16px;
      color: #00d5e9;
    }
  }
}

.middle-up {
  // background: url("@/static/home/框.png") center 0;
  // background-size: cover;
  width: 1052px;
  height: 688px;
  position: relative;
  .map-click {
    position: absolute;
    z-index: 1000;
    margin: 24px;
  }
  .map-back {
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    right: 0;
    margin: 24px 24px 0 0;
  }
  .map-show-box {
    position: absolute;
    z-index: 1000;
    left: 80%;
    top: 50%;

    // transform: translateX(-50%);
  }
  a {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 22px;
    color: #00d5e9;
  }
  .status-text {
    width: 179px;
    height: 76px;
    background: rgba(2, 18, 51, 0);
    border: 1px solid #41ecf2;
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-text-item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  .map-icon {
    width: 41px;
    height: 39px;
    margin-left: 30px;
  }
  .label {
    font-family: "Microsoft YaHei";
    font-weight: 400;
    font-size: 14px;
    color: #39eaff;
  }
  .percentage {
    font-family: Source Han Sans CN;
    font-weight: bold;
    width: 100px;
    font-size: 34px;
    color: #39eaff;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 隐藏超出的内容 */
    text-overflow: ellipsis; /* 超出的部分显示为省略号 */
  }
}

.middle-down {
  display: flex;
  justify-content: space-between;
  // margin-top: 30px;
  .middle-down-left {
    width: 515px;
    height: 276px;
    background: url("@/static/home/表格.png") center / 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  .middle-down-right {
    width: 515px;
    height: 276px;
    background: url("@/static/home/表格.png") center / 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .middle-down-title-box {
    margin-top: 18px;
  }
  .content-item {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #bad2f5;
    margin-top: 21px;
  }
  .middle-list {
    height: 200px;
    overflow-y: scroll; /* 允许垂直滚动 */
    overflow-x: hidden; /* 隐藏水平滚动条 */

    /* 使用透明边框或背景来隐藏滚动条 */
    -ms-overflow-style: none; /* IE 和 Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.right-all {
  background: url("@/static/home/右侧.png") no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.span-it {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #d3d9ec;
}

.small-title-box {
  display: flex;
  margin: 17px 0 0 23px;
  align-items: center;
  .alert-line {
    width: 3px;
    height: 11px;
    background: #00cccd;
    margin-right: 10px;
  }
  .title-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #00d5e9;
    letter-spacing: 3px;
  }
}
.order-container {
  cursor: pointer;
}

.down-title {
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 18px;
  color: #2ef6f7;
  display: flex;
  margin: 5px 0 0 20px;
}
.item-title {
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 18px;
  color: #2ef6f7;
  display: flex;
  margin: 3px 0 0 155px;
}
.logout-box {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  margin: 25px 25px 0 0;
  display: flex;
  align-items: center;
  .userName {
    font-weight: 400;
    font-size: 20px;
    color: #3dc2fe;
    margin: -8px 10px 0 0;
  }
  .logout {
    cursor: pointer;
  }
}
.menu-box {
  background: url("@/static/home/边框2.png") no-repeat;
  background-size: cover;
  // opacity: 1.6;
  // background-color: rgba(0, 0, 0, 0.5); /* 添加半透明的黑色背景 */
  // background-blend-mode: overlay; /* 默认混合模式 */
  width: 226px;
  height: 1080px;
  z-index: 1000;
  // margin: 25px 25px 0 0;
  position: fixed;
  transition: 0.3s ease-in-out;

  .menu-title {
    width: 226px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .menu-title-image {
      margin: 60px 0px 0 -15px;
      width: 22px;
      height: 23px;
    }
    .menu-title-name {
      font-family: Alimama ShuHeiTi;
      font-weight: bold;
      font-size: 22px;
      color: #3abbff;
      margin: 60px 0 0 10px;
    }
  }
  .current-page-menu {
    // background-color: transparent !important; /* 设置透明背景 */
    width: 226px;
    height: 870px;
    border: none; /* 移除默认边框 */
  }
}

.slide-fade-enter-active {
  transition: transform 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-200px);
}
.menu-lan {
  background: url("@/static/home/边栏.png") center / 100% 100%;
  height: 300px;
  width: 20px;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 360px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  // opacity: 0;
}
.slide-lan-enter-active {
  transition: transform 0.3s ease-in-out;
}

.slide-lan-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-lan-enter-from,
.slide-lan-leave-to {
  transform: translateX(-20px);
}

.current-page-menu .ant-menu-item {
  background-color: transparent !important; /* 子项也设置透明背景 */
}

.current-page-menu .ant-menu-submenu-menu {
  background-color: transparent !important; /* 子菜单背景也透明 */
}

.current-page-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.05); /* 选中项稍微有点颜色 */
}

.current-page-menu .ant-menu,
.current-page-menu .ant-menu-sub,
.current-page-menu .ant-menu-inline {
  color: white;
  background-color: transparent !important; /* 子菜单背景也透明 */
}
.current-page-menu .ant-menu .ant-menu-sub .ant-menu-inline {
  color: white;
  background-color: transparent !important; /* 子菜单背景也透明 */
}
.current-page-menu .ant-menu-inline {
  text-align: left;
  padding-left: 0px !important; /* 根据需要调整 */
}

.current-page-menu .ant-menu-inline > .ant-menu-item {
  text-align: left;
  padding-left: 0px !important; /* 根据需要调整 */
}

.current-page-menu .ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #73b8ff;
  letter-spacing: 2px;
}

.current-page-menu .ant-menu-title-content {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #73b8ff;
  letter-spacing: 2px;
}

.current-page-menu .ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  color: #73b8ff !important;
}
// .current-page-menu .ant-menu-dark .ant-menu-item-selected {
//   background-color: #4feefc !important;
// }
</style>