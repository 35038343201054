<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>

  <div class='content-container'>
    <div class='left-container'>
      <div class='left-up'>
        <div class='item-title'>网关设备</div>
        <div class='left-up-content-box'>
          <div id='gatewayChart'
               class='gateway-chart'
               style='width: 150px;height: 150px;'></div>
          <div class='left-up-rank'>
            <div class='rank-item'>
              <div class='rank-item-up'>
                <div class='type-name'>在线:{{gatewayRef.online.value}}</div>
                <div class='type-percent'>{{gatewayRef.online.percent}}%</div>
              </div>
              <div class='rank-line'>
                <div class='rank-line'
                     :style="{background: '#407FFF',width:gatewayRef.online.percent +'%'}"></div>
              </div>
            </div>
            <div class='rank-item'>
              <div class='rank-item-up'>
                <div class='type-name'>离线:{{gatewayRef.offline.value}}</div>
                <div class='type-percent'>{{gatewayRef.offline.percent}}%</div>
              </div>
              <div class='rank-line'>
                <div class='rank-line'
                     :style="{background: '#00CCCD',width:gatewayRef.offline.percent +'%'}"></div>
              </div>
            </div>

            <div class='rank-item'>
              <div class='rank-item-up'>
                <div class='type-name'>未知:{{gatewayRef.unknown.value}}</div>
                <div class='type-percent'>{{gatewayRef.unknown.percent}}%</div>
              </div>
              <div class='rank-line'>
                <div class='rank-line'
                     :style="{background: '#7FC458',width:gatewayRef.unknown.percent +'%'}"></div>
              </div>

            </div>

            <div class='rank-item'>
              <div class='rank-item-up'>
                <div class='type-name'>未安装:{{gatewayRef.unInstall.value}}</div>
                <div class='type-percent'>{{gatewayRef.unInstall.percent}}%</div>
              </div>
              <div class='rank-line'>
                <div class='rank-line'
                     :style="{background: '#7FC458',width:gatewayRef.unInstall.percent +'%'}"></div>
              </div>

            </div>

          </div>
        </div>

      </div>
      <div class='left-down'>
        <div class='item-title'>告警统计</div>
        <div class='alert-down-up-box'>
          <div id='alertPieChart'
               class='alert-statistics-echart'
               style='width: 100%;height: 160px'></div>
          <div class='alert-type'>
            <div class='alert-type-item'>
              <div class='cycle-point'
                   :style="{background:alertStatisticsColor['一般']}"></div>
              <div class='alert-type-item-name'>一般</div>
            </div>

            <div class='alert-type-item'>
              <div class='cycle-point'
                   :style="{background:alertStatisticsColor['修复']}"></div>
              <div class='alert-type-item-name'>修复</div>
            </div>

            <div class='alert-type-item'>
              <div class='cycle-point'
                   :style="{background:alertStatisticsColor['警告']}"></div>
              <div class='alert-type-item-name'>警告</div>
            </div>

            <div class='alert-type-item'>
              <div class='cycle-point'
                   :style="{background:alertStatisticsColor['严重']}"></div>
              <div class='alert-type-item-name'>严重</div>
            </div>
          </div>
        </div>
        <div class='alert-type-statistics'>
          <div class='alert-type-statistics-item'>
            <div class='alert-type-statistics-number'>{{alertRef.deviceOffline}}条</div>
            <div class='alert-type-statistics-name'>设备离线</div>
          </div>
          <div class='alert-type-statistics-item'>
            <div class='alert-type-statistics-number'>{{alertRef.interfaceOffline}}条</div>
            <div class='alert-type-statistics-name'>未安装</div>
          </div>
          <div class='alert-type-statistics-item'>
            <div class='alert-type-statistics-number'>{{alertRef.weakSignal}}条</div>
            <div class='alert-type-statistics-name'>其他</div>
          </div>
          <div class='alert-type-statistics-item'>
            <div class='alert-type-statistics-number'>{{alertRef.flowAnomaly}}条</div>
            <div class='alert-type-statistics-name'>修复</div>
          </div>
        </div>
        <div class='left-down-down-title'>
          <div class='left-down-down-title-line'></div>
          <div class='left-down-down-title-name'>近七日警告统计</div>
          <!-- <img src='@/static/home/'></img> -->
        </div>
        <div id='alertZhuChart'
             style='height:230px;width:100%'></div>
      </div>
    </div>
    <div class='middle-container'>
      <div class='middle-up'>
        <div class='map-click'><a>区域预览</a></div>
        <div class='map-back'>
          <img src='@/static/home/返回.png'
               @click='generalViewClick'>
        </div>
        <img class='dian'
             src='@/static/home/反光栏.png' />

        <div class='map-show-box'>
          <div class="status-text">
            <img src='@/static/home/区域.png'
                 class='map-icon'>
            <div class='status-text-item'>
              <span class="label">地区</span>
              <span class="percentage"
                    style='font-size: 24px;'>{{inMapDataRef.region}}</span>
            </div>

          </div>

          <div class="status-text">
            <img src='@/static/home/门店数量.png'
                 class='map-icon'>
            <div class='status-text-item'>
              <span class="label">门店数量</span>
              <span class="percentage">{{inMapDataRef.storeCount}}</span>
            </div>

          </div>

          <div class="status-text">
            <img src='@/static/home/报警数量.png'
                 class='map-icon'>
            <div class='status-text-item'>
              <span class="label">离线数量</span>
              <span class="percentage">{{inMapDataRef.all_offline_total}}</span>
            </div>

          </div>

          <div class="status-text">
            <img src='@/static/home/报警数量.png'
                 class='map-icon'>
            <div class='status-text-item'>
              <span class="label">未安装数量</span>
              <span class="percentage">{{inMapDataRef.unInstallCount}}</span>
            </div>

          </div>

        </div>
        <div style='width: 1052px;
height: 688px;'
             id='mapChart'></div>
      </div>
      <div class='middle-down'>
        <div class='middle-down-left'>
          <div class='down-title'>告警事件</div>
          <div class='middle-down-content'>
            <div class='middle-down-title-box'>
              <a-row :gutter="24">
                <a-col :span='6'>
                  <span class='span-it'>店名</span>
                </a-col>
                <a-col :span='5'>
                  <span class='span-it'>告警类型</span>
                </a-col>
                <a-col :span='4'>
                  <span class='span-it'>触发类型</span>
                </a-col>
                <a-col :span='4'>
                  <span class='span-it'>状态</span>
                </a-col>
                <a-col :span='5'>
                  <span class='span-it'>发生时间</span>
                </a-col>
              </a-row>
            </div>
            <div class='middle-list'>
              <a-row :gutter="24"
                     v-for="item in alertList"
                     :key="item"
                     class='content-item'>
                <a-col :span="6">
                  <div style='white-space:nowrap;overflow:hidden;'>
                    {{ item.storeName }}
                  </div>
                </a-col>
                <a-col :span="5">
                  <div>
                    {{ item.alertType }}
                  </div>
                </a-col>
                <a-col :span="4">
                  <div>
                    {{ item.alertThing }}
                  </div>
                </a-col>
                <a-col :span="4">
                  <div>
                    处理中
                  </div>
                </a-col>
                <a-col :span="5">
                  <div>
                    {{ item.alertTime }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <div class='middle-down-right'>
          <div class='down-title'>资产统计</div>
          <div class='middle-down-content'>
            <div class='middle-down-title-box'>
              <a-row :gutter="24">
                <a-col :span='8'>
                  <span class='span-it'>设备类型</span>
                </a-col>
                <a-col :span='8'>
                  <span class='span-it'>总计</span>
                </a-col>
                <a-col :span='8'>
                  <span class='span-it'>离线</span>
                </a-col>
              </a-row>
            </div>
            <div class='middle-list'>
              <a-row :gutter="24"
                     class='content-item'
                     v-for="(item, index) in assetsList"
                     :key="index">
                <a-col :span='8'
                       class="column-title">
                  {{ item.title }}
                </a-col>
                <a-col :span='8'
                       class="column-count">
                  {{ item.count }}
                </a-col>
                <a-col :span='8'
                       class="column-offline">
                  {{ item.offline }}
                </a-col>
              </a-row>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class='right-container'>
      <div class='right-all'>
        <div class='item-title'
             style='margin-left:175px'>能耗统计</div>
        <div style='display:flex'>
          <div id='energyLeftChart'
               style='width: 50%;height: 150px;'></div>
          <div id='energyRightChart'
               style='width:50%;height: 150px;'></div>
        </div>
        <div>
          <div class='small-title-box'>
            <div class='alert-line'></div>
            <div class='title-name'>电量统计</div>
          </div>
          <div>
            <div id='energyLineChart'
                 style='width: 100%;height: 210px;'></div>
          </div>
        </div>

        <div>
          <div class='small-title-box'
               style='margin-top:27px'>
            <div class='alert-line'></div>
            <div class='title-name'>客流分析</div>
          </div>
          <div>
            <div id='passengerFlowLineChart'
                 style='width: 100%;height: 225px;'></div>
          </div>
        </div>
        <div class='order-container'>
          <div class='small-title-box'
               style='margin-top:27px'
               @click='toWrokOrder'>
            <div class='alert-line'></div>
            <div class='title-name'>工单统计</div>
          </div>
          <div style='display:flex'>
            <div id='orderChart'
                 style='height:210px;width:100%'>
            </div>
            <!-- <div class='order-statistics'>
                <div v-for="(item, index) in orderRef"
                     :key='index'>
                  {{ item.status }}:{{ item.count }}
                </div>
              </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, nextTick, onUnmounted, h, reactive } from "vue";
import * as echarts from "echarts";
import china1 from "@/static/china.json";
import shanghai from "@/static/shanghai.json"
import serviceAxios from "@/utils/serviceAxios";
import proxyRequest from "@/utils/proxyRequest";
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll';
import interfaces from "@/config";
import { message } from "ant-design-vue";

import { getCurrentInstance } from "vue";

import { useRouter, useRoute } from 'vue-router';
import { specialRules } from '@/configuration/customer'
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';

const username = ref()
let china = ''
username.value = localStorage.getItem('username')
if (username.value == 'xiaocaiyuan') {
  china = shanghai
}
else {
  china = china1
}
console.log('username.value', username.value)
//查询用户信息
const queryUser = async () => {
  const result = await serviceAxios({
    url: interfaces.systemMe,
    method: "get",
    // data:''
  })

  try {
    brandRef.value = result.data.tenant.name
    console.log('品牌名称', brandRef.value)

    console.log('名称', username.value)
  }
  catch (error) {
    console.log('获取品牌名称异常', error)
    brandRef.value = '域禾集控'
  }

};
queryUser()


const route = useRoute()
const router = useRouter()

const containerRef = ref(null);
let brandRef = ref('域禾集控')
const originalWidth = 1920; // 原始宽度
const originalHeight = 1080; // 原始高度

function init (width, height) {
  const element = containerRef.value;

  if (!element) {
    console.error('Container element not found.');
    return;
  }

  // 计算缩放比例
  // const hScale = visibleHeight / height;
  // const wScale = visibleWidth / width;
  console.log('window.inner宽高', window.innerWidth, window.innerHeight)
  const hScale = window.innerHeight / height;
  const wScale = window.innerWidth / width;
  console.log('高宽比', hScale, wScale)
  element.style.transform = `scale(${wScale}, ${hScale})`;
  element.style.transformOrigin = "left top";
  element.style.overflow = 'hidden';

}



onMounted(() => {
  init(1920, 1080);
  window.addEventListener('resize', handleResize);
});
const lazyFun = ref(null);

const handleResize = () => {
  clearTimeout(lazyFun);
  lazyFun.value = setTimeout(() => {
    init(1920, 1080);
  }, 600);
};


onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

//定义网关设备状态字段
let gatewayRef = ref({
  unknown: { value: '0', percent: '0' },
  offline: { value: '0', percent: '0' },
  online: { value: '0', percent: '0' },
  unInstall: { value: '0', percent: '0' }
})

//定义告警统计颜色字段
let alertStatisticsColor = {
  '严重': '#FFB135',
  '一般': '#7FC458',
  '警告': '#00CCCD',
  '修复': '#407FFF',

}
//定义警告数据
const alertRef = ref({
  deviceOffline: "", //设备离线
  interfaceOffline: "",//接口断开
  weakSignal: "",//信号弱
  flowAnomaly: "",//流量异常
})

//定义地图中的显示数据
const inMapDataRef = ref({
  region: '全国',
  storeCount: "",
  all_offline_total: "",
  unInstallCount: ''
})


//定义滚动图步长
const step = 0.3

//定义省市
let province = ''
let city = ''
//定义饼图数据
let gatewayChart = ""
let alertPieChart = ""
let alertZhuChart = ""
let energyLeftChart = ""
let energyRightChart = ""
let energyLineChart = ""
let passengerFlowLineChart = ""
let mapChart = ""
let mpdata = ""
let orderChart = ""
//网关
const gatewayOption = {
  tooltip: {
    trigger: 'item'
  },

  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['60%', '100%'],
      avoidLabelOverlap: false,
      label: {
        show: true, // 显示标签
        position: 'inside', // 将标签放在扇区内
        formatter: ' {d}%', // 自定义标签格式为百分比形式
        // 直接设置字体样式
        fontSize: 11,
        color: '#FFFFFF',
        fontFamily: 'Arial',
        fontWeight: 400,
      },

      labelLine: {
        show: false // 不显示指向标签的线
      },
      data: [
        { value: 0, name: '在线' },
        { value: 0, name: '离线' },
        { value: 0, name: '未知' },

      ]
    }
  ]
};
//预警
const alertPieOption = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)' // 这里的formatter用于格式化tooltip显示的内容
  },

  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['70%', '100%'],
      avoidLabelOverlap: false,
      padAngle: 5,
      itemStyle: {
        borderRadius: 10
      },
      label: {
        show: true, // 显示标签
        position: 'outside', // 将标签放在扇区外
        formatter: function (params) {
          // 使用 rich 文本格式化
          return `{name|${params.name}}\n\n{percent|${parseFloat(params.percent).toFixed(2)}%}`;
        },
        rich: {
          name: {
            // 类型的字体格式
            fontSize: 13,
            color: '#BAD1E7',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 'bold',
            align: 'center', // 水平居中对齐
            verticalAlign: 'middle' // 垂直居中对齐
          },
          percent: {
            // 百分比的字体格式
            fontSize: 17,
            color: '#00CCCD',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 'bold',
            align: 'center', // 水平居中对齐
            verticalAlign: 'middle' // 垂直居中对齐
          }
        }
      },
      labelLine: {
        show: true, // 显示指向标签的线
        smooth: true, // 使线条平滑
        lineStyle: {
          type: 'dashed' // 设置线条为虚线
        }
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ]
    }
  ]
};
const alertZhuOption = {
  // title: {
  //   text: '近7天警告统计',
  //   left: 'center',
  //   textStyle: {
  //     fontSize: 14,
  //     color: '#ccc'
  //   }
  // },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: '15%',
    bottom: '0%',
    left: '3%',
    right: '10%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        alignWithLabel: true
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      },
      splitLine: {
        show: false
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#6c757d',
          width: 1,
          type: 'solid'
        }
      },

    }
  ],
  series: [
    {
      name: '告警数量',
      type: 'bar',
      barWidth: '30%',
      data: [10, 52, 200, 334, 390, 330, 220],
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#3f51b5' },
              { offset: 1, color: '#673ab7' }
            ],
            false
          ),
          barBorderRadius: 5
        }
      }
    }
  ]
};

//能耗
const energyLeftOption = {
  title: {
    text: '智能设备',
    left: 'center',
    top: 'bottom',
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   top: '5%',
  //   left: 'center'
  // },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 0, name: 'Search Engine' },
        { value: 0, name: 'Direct' },
        { value: 0, name: 'Email' },
        { value: 0, name: 'Union Ads' },
        { value: 0, name: 'Video Ads' }
      ]
    }
  ]
};
const energyRightOption = {
  title: {
    text: '能耗分类',
    left: 'center', // 水平居中
    top: 'bottom', // 使用CSS calc来计算位置，距离底部20像素
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  grid: {
    // 设置上、右、下、左的边距
    top: '5%',
    right: '5%',
    bottom: '20%',
    left: '0%'
  },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   top: '5%',
  //   left: 'center'
  // },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    axisLabel: {
      show: false // 隐藏x轴数据
    }
  },
  yAxis: {
    type: 'value',
    axisLine: { // 去掉y轴的刻度线
      show: false
    },
    axisTick: { // 去掉y轴的刻度标记
      show: false
    },
    splitLine: { // 去掉y轴的分割线
      show: false
    }
  },
  series: [
    {
      name: '能耗',
      type: 'bar',
      barWidth: '30%', // 缩小柱子的宽度
      barBorderRadius: 5, // 设置柱体的圆角半径
      data: [0, 0, 0, 0, 0],
      itemStyle: {
        color: function (params) {
          // 设置每根柱子的颜色
          var colors = ['#FFF2B8', '#7FC458', '#00CCCD', '#407FFF', '#FFB135'];
          return colors[params.dataIndex];
        }
      }
    }
  ]
};
const energyLineOption = {
  // title: {
  //   text: '电量统计',
  //   left: 'left',
  //   textStyle: {
  //     fontSize: 14,
  //     color: '#ccc'
  //   }
  // },
  grid: {
    top: '15%', // 顶部边距
    bottom: '0%', // 底部边距
    left: '5%', // 左侧边距
    right: '5%', // 右侧边距
    containLabel: true // 是否包含坐标轴的标签
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
    splitLine: {
      lineStyle: {
        color: '#6c757d',
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      name: 'Energy Consumption',
      data: [0, 0, 0, 0, 0, 0, 0],
      type: 'line',
      smooth: true,
      lineStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#3f51b5' }, // 蓝紫色渐变开始颜色
              { offset: 1, color: '#673ab7' }  // 蓝紫色渐变结束颜色
            ],
            false
          )
        }
      },
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: 'rgba(63, 81, 181, 0.2)' },
              { offset: 1, color: 'rgba(103, 58, 183, 0.2)' }
            ],
            false
          )
        }
      }
    }
  ]
};

//客流
const passengerFlowLineOption = {
  grid: {
    top: '15%', // 顶部边距
    bottom: '0%', // 底部边距
    left: '5%', // 左侧边距
    right: '5%', // 右侧边距
    containLabel: true // 是否包含坐标轴的标签
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#ccc', // 添加y轴线颜色
      },
    },
    splitLine: {
      lineStyle: {
        color: '#6c757d',
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      data: [0, 0, 0, 0, 0, 0, 0],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#4caf50', // 添加线条颜色
        width: 3, // 添加线条宽度
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: 'rgba(76, 175, 80, 0.8)' },
          { offset: 1, color: 'rgba(76, 175, 80, 0.2)' },
        ]), // 添加区域填充样式
      },
    },
  ],
};

//定义门店坐标信息字段

const coordinateRef = ref([])
const initcoordinateRef = ref([])
//地图
const points = ref([
  // 散点图数据
  {
    name: "上海",
    value: [121.399244, 31.325152],
    itemStyle: { color: "#00EEFF" },
  }, // 广东
]);
const linesData = ref([
  {
    coords: [
      [121.399244, 31.325152]
    ]
  }, // 北京->广东
]);
const planePath = // 飞机svg
  "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";

const mapOption = {

  backgroundColor: "transparent", // 背景颜色
  geo: {
    left: '10%', // 可以使用百分比或具体的像素值

    // 地图配置
    zoom: 1,
    roam: true, // 允许缩放和平移
    map: "china",
    label: {
      // 图形上的文本标签
      normal: {
        // 通常状态下的样式
        show: false,
        textStyle: {
          color: "#fff",
        },
      },
      emphasis: {
        // 鼠标放上去高亮的样式
        textStyle: {
          color: "#fff",
        },
      },

    },
    // 设置地图的水平位置，往左移动一点
    itemStyle: {
      // 地图区域的样式设置
      normal: {
        // 通常状态下的样式
        borderColor: "#898686",
        borderWidth: 1,
        areaColor: '#084187'
        // areaColor: {
        //   //地图区域的颜色
        //   type: "radial", // 径向渐变
        //   x: 0.5, // 圆心
        //   y: 0.5, // 圆心
        //   r: 0.8, // 半径
        //   colorStops: [
        //     {
        //       // 0% 处的颜色
        //       offset: 0,
        //       color: "rgba(0, 102, 154, 0)",
        //     },
        //     {
        //       // 100% 处的颜色
        //       offset: 1,
        //       color: "rgba(0, 102, 154, .4)",
        //     },
        //   ],
        // },
      },
      // 鼠标放上去高亮的样式
      emphasis: {
        areaColor: "#2386AD",
        borderWidth: 1,
      },
    },
  },
  // visualMap: {
  //   min: 0, // 最小值
  //   max: 20, // 最大值
  //   calculable: true, // 是否启用拖拽重计算
  //   piecewise: true, // 使用分段视觉映射
  //   pieces: [
  //     { gte: 20, color: '#ff0000' }, // 红色
  //     { gte: 10, lte: 20, color: '#ff9900' }, // 橙色
  //     { gte: 5, lte: 10, color: '#ffcc00' }, // 黄橙色
  //     { gte: 1, lte: 5, color: '#00ff00' }, // 绿色
  //     { lte: 1, color: '#99ff99' } // 浅绿色
  //   ],

  //   textStyle: {
  //     color: '#fff'
  //   },
  //   show: true,
  //   orient: 'vertical', // 方向：水平或垂直
  //   left: 'left', // 位置：左边
  //   top: 'middle' // 位置：中间
  // },
  series: [
    {
      // 散点系列数据
      type: "effectScatter", // 带有涟漪特效动画的散点（气泡）图
      coordinateSystem: "geo", //该系列使用的坐标系:地理坐标系
      // 特效类型,目前只支持涟漪特效'ripple'，意为“涟漪”
      effectType: "ripple",
      // 配置何时显示特效。可选'render'和'emphasis' 。
      showEffectOn: "render",
      rippleEffect: {
        // 涟漪特效相关配置。
        period: 10, // 动画的周期，秒数。
        scale: 1, // 动画中波纹的最大缩放比例。
        // 波纹的绘制方式，可选 'stroke' 和 'fill'。
        brushType: "fill",
      },
      label: {
        show: false,
        emphasis: { // 文本标签
          show: true, // 是否显示文本标签
          position: 'left', // 标签相对于图形的位置
          formatter: '{b}', // 标签内容格式器
          // textStyle: {
          //   fontSize: 12,
          //   color: '#fff',
          // }
        },
      },

      zlevel: 1, // 所有图形的 zlevel 值。
      data: coordinateRef.value,
    },
    // {
    //   // 线条系列数据
    //   type: "lines",
    //   zlevel: 2,
    //   symbol: ["none", "arrow"], // 标记的图形: 箭头
    //   symbolSize: 10, // 标记的大小
    //   effect: {
    //     // 线条特效的配置
    //     show: true,
    //     period: 6, // 特效动画的时间，单位s
    //     trailLength: 0, // 特效尾迹的长度。取值[0,1]值越大，尾迹越重
    //     symbol: planePath, // 特效图形的标记 可选'circle'等
    //     symbolSize: 15, // 特效标记的大小
    //   },
    //   lineStyle: {
    //     // 线条样式
    //     normal: {
    //       color: "#93EBF8",
    //       width: 2.5, // 线条宽度
    //       opacity: 0.6, // 尾迹线条透明度
    //       curveness: 0.2, // 尾迹线条曲直度
    //     },
    //   },
    //   data: linesData.value,
    // },
  ],
};

//工单
const orderRef = ref([])
const orderOption = {
  grid: {
    top: '15%',
    bottom: '0%',
    left: '5%',
    right: '5%',
    containLabel: true // 保持标签包含
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    // formatter: function (params) {
    //   return params.value + '1单'; // 自定义提示信息
    // }
  },
  xAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        color: "#ccc",
      },
      splitLine: {
        lineStyle: {
          color: '#6c757d',
          width: 1,
          type: 'solid'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'category', // x轴变为分类轴
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        color: "#ccc",
      },
    }
  ],
  series: [
    {
      name: '工单数量',
      type: 'bar',
      barWidth: '30%', // 减少柱状图宽度
      data: [10, 52, 200, 334, 390, 330, 220],
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 1, 0,
            [
              { offset: 0, color: '#08d9d6' }, // 蓝绿渐变开始颜色
              { offset: 1, color: '#ff2e63' }  // 蓝绿渐变结束颜色
            ],
            false
          ),
          barBorderRadius: 5 // 可选地添加圆角效果
        }
      },
      label: {
        normal: {
          show: true,
          position: 'right', // 显示在柱状图的右侧
          formatter: '{c}', // 自定义标签文本
          color: '#f0f0f0',
          fontSize: 14
        }
      }
    }
  ]
};
const updateWindowSize = () => {
  nextTick(() => {
    const gatewayHeight = document.getElementsByClassName('left-up')[0].scrollHeight - document.getElementsByClassName('header')[0].scrollHeight
    gatewayChart.resize({ height: gatewayHeight });
    console.log("gatewayHeight", gatewayHeight)
  })
}




// 定义设备列表数据
const list = ref([]);
let onlineRate = 0;
const assetsList = ref([])
const alertList = ref([])
// 定义滚动组件 就是我们上边在dom上定义的ref
const cScroll = ref();
const assetsScroll = ref()
//获取网关统计信息
const gatewayData = async () => {
  const response = await serviceAxios({
    url: "/v1/ops/zabbix/get_statistical_data",
    method: 'post',
  })
  console.log(response)
  try {

    if (username.value == 'xiaocaiyuan') {
      gatewayOption.series[0].data = [
        { value: 70, name: "在线", itemStyle: { color: '#407FFF' } }
        , { value: 0, name: "离线", itemStyle: { color: '#00CCCD' } },
        { value: 0, name: "未知", itemStyle: { color: '#7FC458' } },
        { value: 1, name: "未安装" }
      ]
      gatewayRef.value.unknown.value = 0;
      gatewayRef.value.offline.value = 0;
      gatewayRef.value.online.value = 70;
      gatewayRef.value.unInstall.value = 1;


      gatewayRef.value.unknown.percent = 0;
      gatewayRef.value.offline.percent = 0;
      gatewayRef.value.online.percent = ((70 / 71) * 100).toFixed(2);
      gatewayRef.value.unInstall.percent = ((1 / 71) * 100).toFixed(2);
      onlineRate = 70 / 71;
    }
    else {
      let gatewayStatictics = response.data.data
      gatewayOption.series[0].data = [
        { value: gatewayStatictics.other_count, name: "在线", itemStyle: { color: '#407FFF' } }
        , { value: gatewayStatictics.offline_count, name: "离线", itemStyle: { color: '#00CCCD' } },
        { value: gatewayStatictics.raw_offline_count, name: "未知", itemStyle: { color: '#7FC458' } },
        { value: gatewayStatictics.without_gateway, name: "未安装" }
      ]

      gatewayRef.value.unknown.value = gatewayStatictics.raw_offline_count;
      gatewayRef.value.offline.value = gatewayStatictics.offline_count;
      gatewayRef.value.online.value = gatewayStatictics.other_count;
      gatewayRef.value.unInstall.value = gatewayStatictics.without_gateway;

      const total = gatewayStatictics.total_count

      gatewayRef.value.unknown.percent = ((gatewayStatictics.raw_offline_count / total) * 100).toFixed(2);
      gatewayRef.value.offline.percent = ((gatewayStatictics.offline_count / total) * 100).toFixed(2);
      gatewayRef.value.online.percent = ((gatewayStatictics.other_count / total) * 100).toFixed(2);
      gatewayRef.value.unInstall.percent = ((gatewayStatictics.without_gateway / total) * 100).toFixed(2);
      onlineRate = gatewayStatictics.online / total;
    }


  }
  catch (error) {
    console.log(error)
  }

}

//获取预警信息
const getAlertData = async () => {
  let response = await serviceAxios({
    url: '/v1/ops/statistics/store/zabbix/alert',
    method: 'get'
  })
  let data = response.data.data.list;
  console.log('data alert', data)

  if (username.value == 'xiaocaiyuan') {

    data = [
      {
        "type": "未安装网关",
        "level": "信息",
        "count": 1,
        "repaired": 0
      },
      {
        "type": "设备离线",
        "level": "严重",
        "count": 0,
        "repaired": 2
      },
      {
        "type": "接口断开",
        "level": "一般",
        "count": 0,
        "repaired": 0
      },
      {
        "type": "RSRP信号弱",
        "level": "警告",
        "count": 0,
        "repaired": 3
      },
      {
        "type": "流量异常",
        "level": "一般",
        "count": 0,
        "repaired": 0
      },
      {
        "type": "其他",
        "level": "一般",
        "count": 0,
        "repaired": 0
      }
    ]
  }

  alertRef.value.deviceOffline = data.filter(item => item.type == '设备离线')[0].count;
  alertRef.value.interfaceOffline = data.filter(item => item.type == '未安装网关')[0].count;
  try {
    alertRef.value.weakSignal = data.filter(item => item.type.includes('其他'))[0].count;
    alertRef.value.flowAnomaly = data.filter(item => item.type == '修复')[0].count;
  }
  catch {
    alertRef.value.weakSignal = 0;
    alertRef.value.flowAnomaly = 0;
  }

  //小菜园

  let c = []
  if (username.value != 'xiaocaiyuan') {
    c = response.data.data.dict
  }
  else {
    c = {
      "信息": 1,
      "严重": 0,
      "一般": 0,
      "警告": 0,
      "修复": 0
    }
  }

  let alertTypeData = Object.entries(c).map(([key, value]) => ({
    name: key,
    value: value,
    itemStyle: { color: alertStatisticsColor[key] }
  }));
  console.log('alertType', alertTypeData)
  alertTypeData = alertTypeData.filter(item => item.name != "修复")
  alertTypeData.forEach(item => {
    if (item.name == '信息') {
      item.name = '未安装'
    }

  })
  alertPieOption.series[0].data = alertTypeData
  console.log('alertPieOption',alertPieOption)
  response = await serviceAxios({
    url: '/v1/ops/sequence/zabbix/offline_alert/group_by_time',
    method: 'get',
    params: {
      step: 2,
      num: 7
    }
  })
  data = response.data.data;
  alertZhuOption.xAxis[0].data = data.map(item => item.name)
  alertZhuOption.series[0].data = data.map(item => item.alert_total)


}

//资产统计 
const assetsStatictics = async () => {
  const response = await serviceAxios({
    url: interfaces.assetsStatictics,
    method: 'get'
  })

  assetsList.value = response.data.data.detail.map(item => ({
    title: item.name,
    count: item.count,
    offline: item.offline
  }))
  console.log('ssetsList.value', assetsList.value)
  // assetsScroll.value.Reset();
}

//工单统计 
const orderStatictics = async () => {
  let response = await serviceAxios({
    url: interfaces.workOrderStatictics,
    method: 'get'
  })

  orderRef.value = response.data.data.detail

  response = await serviceAxios({
    url: interfaces.workOrderGroupByTime,
    method: 'get',
    params: {
      step: 1,
      num: 5
    }
  })

  let week = response.data.data.map(item => item.week + '周')
  let count = response.data.data.map(item => item.count)
  week[0] = '本周'
  week = week.slice().reverse();
  count = count.slice().reverse();
  orderOption.yAxis[0].data = week
  orderOption.series[0].data = count

  // assetsScroll.value.Reset();
}

// alertGroupByRegion: "/v1/ops/count/store/zabbix/alert/group_by_region",
//获取区域警报
const alertGroupByRegion = async (province, city) => {
  //获取地区预警数据，将含有警报的地区变红
  console.log(province, city)
  province = province == '' ? null : province
  city = city == '' ? null : city
  const response = await serviceAxios({
    url: "/v1/ops/count/store/zabbix/alert/group_by_region",
    method: 'get',
    params: {
      province: province,
      city: city,

    }
  })
  if (response.data.message == 'success') {
    const data = response.data.data.detail

    //将含有预警的门店区域颜色变红
    const validData = data.filter(item => item.alert_count != 0 && item.store_count != 0).map(item => ({
      name: item.name,
      severity: Math.floor((item.alert_count * 100) / item.store_count)
    }))
    const colors = {
      red: '#FF0000',
      orange: '#FFA500',
      yellowOrange: '#FFCC00',
      green: '#00FF00',
      lightGreen: '#99FF99'
    };
    mapOption.geo.regions = []
    validData.forEach(item => {
      mapOption.geo.regions.push({
        name: item.name, itemStyle: { normal: { areaColor: '#FF0000' } } //红色
      })
    })


    inMapDataRef.value.unInstallCount = 0
    //统计门店数量和预警数量
    inMapDataRef.value.storeCount = response.data.data.all_store_total;
    inMapDataRef.value.all_offline_total = response.data.data.all_offline_total;

    if (username.value == 'xiaocaiyuan') {
      inMapDataRef.value.unInstallCount = 1
    }
    else {
      inMapDataRef.value.unInstallCount = response.data.data.all_without_gateway

    }

  }
  else {
    // message.error(response.data.message)
    message.error('该区域不可进入')
  }




}
// alertGroupByRegion()
//总览点击事件
const generalViewClick = async () => {
  if (username.value == 'xiaocaiyuan') {
    china = await getRegionData(310000)
    china = china.data
    console.log('china1', china)

    mapOption.geo.map = '上海市'

    inMapDataRef.value.region = '上海市'

    await getcoordinateAll('上海市')
    mapChart.setOption(mapOption)
    return
  }
  mpdata = china
  // echarts.registerMap("china", /**/ china);
  // mapOption.series[0].map = "china"
  province = ""
  city = ""
  // coordinateRef.value = []



  await alertGroupByRegion(province, city)
  mapOption.geo.map = 'china'
  mapOption.series[0].data = []
  mapChart.setOption(mapOption);

  inMapDataRef.value.region = '全国'

}

//获取阿里地区数据
const getRegionData = async (adcode) => {
  // const regionResponse = await proxyRequest({
  //   url: '/areas_v3/bound/' + adcode + '_full.json',
  //   method: 'get'
  // })

  // return { data: regionResponse.data }
  // const filePath = `region_data/${adcode}.json`;
  // const regionResponse = await proxyRequest({
  //   url: filePath,//同文件夹下的json文件路径
  //   method: "get",
  //   // dataType: "json",
  // })

  const regionResponse = await serviceAxios({
    url: interfaces.aliMap + '/' + String(adcode) + '.json',
    method: 'get',
  })

  return { data: regionResponse.data.data }

  // return { data: regionResponse.data }




}
//定义预警门店
const alertStore = ref([])
const getAlertHistory = async () => {
  let response = await serviceAxios({
    url: '/v1/ops/zabbix_trigger/problems',
    method: 'get',

  })
  alertList.value = response.data.data.map(item => ({
    storeName: item.host_name,
    alertType: item.severity,
    alertTime: item.event_time.split(' ')[1],
    alertThing: item.trigger_type,
    status: ' ',
    store_id: item.store_id
    // duration: item.message['Problem duration'],
    // problem: item.message['Problem name'],
  }))

}



//  获取门店坐标 定义 getcoordinateAll 函数
const getcoordinateAll = async (province, city) => {
  const data = {
    province: province,
    city: city,
  }
  serviceAxios({
    url: "/v1/ops/store/coordinate/all",
    method: 'get',
    params: data
  }).then((result) => {
    if (result && result.data && result.data.data) {
      coordinateRef.value = result.data.data.map((item) => ({
        name: item.name,
        value: [item.location.lng, item.location.lat],
        store_id: item.id,
        itemStyle: { color: "#00EEFF" },
      }));

      initcoordinateRef.value = coordinateRef.value //存储坐标
      mapOption.series[0].data = coordinateRef.value


    } else {
      console.log('Invalid response:', result);
    }
  }).catch((error) => {
    console.error('Failed to fetch coordinates:', error);
  });
};

//预警检测
const reflushAlert = async () => {
  await getAlertHistory()
  let alertStoreList = alertList.value.map(item => item.store_id)
  alertStoreList.push(223)
  console.log("alertStoreList", alertStoreList)
  console.log("coordinateRef", coordinateRef.value)
  coordinateRef.value.forEach(item => {
    if (alertStoreList.includes(item.store_id)) {
      item.itemStyle = { color: "#e60000" }
      console.log('异常门店')
    }
    else {
      item.itemStyle = { color: "#00EEFF" }
    }
  })
  mapOption.series[0].data = coordinateRef.value
  mapChart.setOption(mapOption)
}
// 设置定时器，每隔 180000 毫秒（即 3 分钟）执行一次 executeEveryThreeMinutes 函数
const intervalId = setInterval(reflushAlert, 1800000);

//跳转到工单
const toWrokOrder = () => {
  router.push({ path: "/customerWorkOrder" })
}

//自执行函数
(async () => {
  for (let i = 0; i < 10; i++) {
    list.value.push({ title: '类型' + i, online: '在线' + i, offline: '离线' + i },)
  }
  // getcoordinateAll()
  // await getAlertHistory()
  // reflushAlert()
  nextTick(() => {

    // 注意这个重置方法是大写的R -> Reset,而不是小写的reset();
    // 很多教程写错了，这里就会报错
    // cScroll.value.Reset();
  })
  // getAlertData()alertHistories

})()



onMounted(async () => {

  // 小菜园单独代码

  if (username.value == 'xiaocaiyuan') {
    china = await getRegionData(310000)
    china = china.data
    inMapDataRef.value.region = '上海市'
    getcoordinateAll('上海市')
  }




  mpdata = china
  nextTick(async () => {

    await getAlertData()
    await gatewayData()

    await assetsStatictics()
    await orderStatictics()

    // getcoordinateAll()
    await getAlertHistory()

    // let maxDataIndex = 0;
    // let maxValue = 0;
    // gatewayOption.series[0].data.forEach((item, index) => {
    //   if (item.value > maxValue) {
    //     maxValue = item.value;
    //     maxDataIndex = index;
    //   }
    // });

    var chartDom = document.getElementById('gatewayChart');
    gatewayChart = echarts.init(chartDom);

    gatewayChart.setOption(gatewayOption);




    //告警

    // maxDataIndex = 0;
    // maxValue = 0;
    // alertPieOption.series[0].data.forEach((item, index) => {
    //   if (item.value > maxValue) {
    //     maxValue = item.value;
    //     maxDataIndex = index;
    //   }
    // });
    chartDom = document.getElementById('alertPieChart');
    alertPieChart = echarts.init(chartDom);
    // const alertHeight = document.getElementsByClassName('left-middle')[0].scrollHeight - document.getElementsByClassName('header')[0].scrollHeight
    // alertPieChart.resize({ height: alertHeight * 0.4 });
    alertPieChart.setOption(alertPieOption)
    // alertPieChart.dispatchAction({
    //   type: 'highlight',
    //   seriesIndex: 0, // 0 表示第一个 series
    //   dataIndex: maxDataIndex // 设置最大值数据项为强调状态
    // });

    chartDom = document.getElementById('alertZhuChart');
    alertZhuChart = echarts.init(chartDom);
    // alertZhuChart.resize({ height: alertHeight * 0.5 });
    alertZhuChart.setOption(alertZhuOption)

    //实时能耗

    chartDom = document.getElementById('energyLeftChart');
    energyLeftChart = echarts.init(chartDom);
    // let chartHeight = document.getElementsByClassName('right-up')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    // energyLeftChart.resize({ height: chartHeight * 0.5 });
    energyLeftChart.setOption(energyLeftOption)

    chartDom = document.getElementById('energyRightChart');
    energyRightChart = echarts.init(chartDom);
    // energyRightChart.resize({ height: chartHeight * 0.5 });
    energyRightChart.setOption(energyRightOption)

    chartDom = document.getElementById('energyLineChart');
    energyLineChart = echarts.init(chartDom);
    // energyLineChart.resize({ height: chartHeight * 0.5 });
    energyLineChart.setOption(energyLineOption)

    //客流
    chartDom = document.getElementById('passengerFlowLineChart');
    passengerFlowLineChart = echarts.init(chartDom);
    // chartHeight = document.getElementsByClassName('right-middle-box')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    // passengerFlowLineChart.resize({ height: chartHeight });
    passengerFlowLineChart.setOption(passengerFlowLineOption)


    //工单
    // orderChart

    chartDom = document.getElementById('orderChart');
    orderChart = echarts.init(chartDom);
    // chartHeight = document.getElementsByClassName('right-down')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    // let chartWeight = document.getElementsByClassName('right-down')[0].scrollWidth - 10 - document.getElementsByClassName('order-statistics')[0].scrollWidth
    // console.log('right-down', document.getElementsByClassName('right-down')[0].scrollHeight, document.getElementsByClassName('header')[0].scrollHeight)

    // orderChart.resize({ height: chartHeight, width: chartWeight });
    orderChart.setOption(orderOption)

    //地图
    mapChart = echarts.init(document.getElementById('mapChart'));
    // const mapchartWidth = document.getElementsByClassName('middle-middle')[0].scrollWidth - 10
    // const mapchartHeight = document.getElementsByClassName('middle-middle')[0].scrollHeight - 10

    //小菜园特殊代码
    if (username.value != 'xiaocaiyuan') {
      console.log('china username', china)
      echarts.registerMap("china", /**/ china);

    }
    else {
      mapOption.geo.map = '上海市'
      echarts.registerMap("上海市", /**/ china);

    }


    await alertGroupByRegion()  //获取异常警报的地区

    // mapChart.resize({ height: mapchartHeight, width: mapchartWidth });
    console.log('mapOption', mapOption)
    mapChart.setOption(mapOption)

    console.log('预警监测')
    reflushAlert()  //预警监测

    //点击进入地区事件
    if (username.value == 'xiaocaiyuan') {
      return
    }
    mapChart.on("click", async function (params) {
      if (province == "") {
        province = params.name

        inMapDataRef.value.region = province  //修改地图地区字段的显示
      } else {
        city = city == "" ? params.name : city
        inMapDataRef.value.region = city  //修改地图地区字段的显示
      }
      await alertGroupByRegion(inMapDataRef.value.region, city) //获取地区预警数据，将含有警报的地区变红
      await getcoordinateAll(province, city)
      console.log(params.name, mpdata)
      reflushAlert()

      let filterRegion = mpdata.features.filter(item =>
        item.properties.name == params.name
      );
      mpdata = await getRegionData(filterRegion[0].properties.adcode)  //获取ali地图数据
      mpdata = mpdata.data
      console.log(filterRegion[0].properties.adcode, params.name, mpdata)

      mapOption.geo.map = params.name
      echarts.registerMap(params.name, /**/ mpdata);
      // mapOption.series[0].map = params.name
      if (params.name === '海南省') {
        console.log('access current')
        mapOption.geo.center = [109.844902, 19.0392];//中心位置
        mapOption.geo.layoutCenter = ['50%', '40%'];//地图相对容器偏移
        mapOption.geo.layoutSize = "380%";//地图放大比例
      } else { //非显示海南时，将设置的参数恢复默认值
        mapOption.geo.center = undefined;
        mapOption.geo.layoutCenter = undefined;
        mapOption.geo.layoutSize = undefined;
      }
      console.log('mapOption', mapOption)



      mapChart.setOption(mapOption, true);

      mapOption.geo.center = undefined;
      mapOption.geo.layoutCenter = undefined;
      mapOption.geo.layoutSize = undefined;



    });



  })
  window.addEventListener('resize', updateWindowSize)
})
onUnmounted(() => {
  if (gatewayChart) gatewayChart.dispose();
  if (alertPieChart) alertPieChart.dispose();
  if (alertZhuChart) alertZhuChart.dispose();
  if (energyLeftChart) energyLeftChart.dispose();
  if (energyRightChart) energyRightChart.dispose();
  if (energyLineChart) energyLineChart.dispose();
  if (passengerFlowLineChart) passengerFlowLineChart.dispose();
  if (mapChart) mapChart.dispose();
  // clearInterval(intervalId);
  window.removeEventListener('resize', updateWindowSize)
})



//ttttest
//菜单数据
let navigation_sides = specialRules
const state = reactive({
  rootSubmenuKeys: [],
  collapsed: false,
  selectedKeys: [],
  openKeys: [],
  preOpenKeys: [],
  // mode: 'inline',
  // theme: 'light',
  // selectedKeys: ['1'],
  // openKeys: ['sub1'],
});
state.rootSubmenuKeys = specialRules.map(item => item.id)
const handleClickSide = (menuInfo) => {
  if (menuInfo.key != undefined)
    console.log('click ', menuInfo, menuInfo.key);
  // breadcrumb_info.value = menuInfo.label;
  router.push({ path: menuInfo.key });
};
const onOpenChange = (keys) => {
  console.log('侧边栏改变', state.rootSubmenuKeys, keys)
  const latestOpenKey = state.rootSubmenuKeys.filter(item => item == keys[keys.length - 1]);
  if (latestOpenKey.length != 0) {
    state.openKeys = [latestOpenKey[0]];
  }
  else {
    state.openKeys = keys;
  }
}


const menuRef = ref(null);
let menuShow = ref(false)
const viewMenu = () => {
  setTimeout(() => {
    menuShow.value = true
  }, 200)
}

const hideMenu = () => {
  setTimeout(() => {
    menuShow.value = false
  }, 200)
}
let hideTimer = null; // 用于存储定时器的引用
const startHideTimer = () => {
  // 清除之前的定时器
  if (hideTimer) {
    clearTimeout(hideTimer);
  }

  // 启动新定时器
  hideTimer = setTimeout(() => {
    menuShow.value = false;
    hideTimer = null; // 清除定时器引用
  }, 2000); // 2000毫秒后执行
};

const clearHideTimer = () => {
  // 清除定时器
  if (hideTimer) {
    clearTimeout(hideTimer);
    hideTimer = null; // 清除定时器引用
  }
};

// 监听点击事件
// const handleClickOutside = event => {
//   console.log('点击')
//   if (!menuRef.value || menuRef.value.contains(event.target)) {

//     return;
//   }
//   else {
//     menuShow.value = false
//   }

//   // 关闭侧边菜单
//   state.openKeys.value = [];
// };

// // 初始化监听器
// const initClickOutsideListener = () => {
//   document.addEventListener('click', handleClickOutside);
// };

// // 清除监听器
// const cleanupClickOutsideListener = () => {
//   document.removeEventListener('click', handleClickOutside);
// };

// // 在组件挂载时添加监听器
// onMounted(() => {
//   initClickOutsideListener();
// });

// // 在组件卸载时移除监听器
// onUnmounted(() => {
//   cleanupClickOutsideListener();
// });

const handleLogout = () => {
  serviceAxios({
    url: interfaces.logout,
    method: 'get'
  }).then(
    result => {
      localStorage.clear()
      router.push("/loginView")
    }
  ).catch()
};











</script>

<style lang='less' scoped>
.content-container {
  display: flex;
}
.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  letter-spacing: 8px;
  .container-title-style {
    margin-top: 20px;
  }
  .container-title-left {
    font-family: PangMenZhengDaoBiaoTiTiMianFeiBan-4;
    font-weight: 400;
    font-size: 50px;
    color: #00b4ff;
    font-weight: 700;
    background: linear-gradient(0deg, #37b2ff 0%, #4feefc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container-title-right {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 45px;
    color: #00b4ff;
    margin: 10px 0 0 10px;
    background: linear-gradient(0deg, #37b2ff 0%, #4feefc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.left-container {
  width: 410px;
  height: 100%;
  opacity: 0.9;
}
.middle-container {
  width: 1052px;
  height: 100%;
  margin-left: 20px;
}
.right-container {
  width: 414px;
  height: 100%;
  margin-left: 23px;
}
.left-up {
  background: url("@/static/home/左上.png");
  width: 410px;
  height: 294px;
  display: flex;
  flex-direction: column;
  .gateway-chart {
    margin: 66px 0 0 24px;
  }
  .left-up-content-box {
    display: flex;
  }
  .left-up-rank {
    margin: 15px 0 0 31px;
  }
  .rank-item {
    margin-bottom: 20px;
  }
  .rank-item-up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .type-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #6889c0;
  }
  .type-percent {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #00cccd;
  }
  .rank-line {
    width: 164px;
    height: 14px;
    background: #3e5596;
    border-radius: 6px;
  }
}
.left-down {
  margin-top: 15px;
  background: url("@/static/home/左下.png");
  width: 410px;
  height: 656px;
  .alert-down-up-box {
    height: 250px;
    padding-top: 32px;
  }
  .alert-type {
    width: 294px;
    height: 38px;
    background: #2941a7;
    border-radius: 19px;
    margin: 30px 39px 0 33px;
    display: flex;
    padding: 0 23px 0 21px;
    justify-content: space-between;
    .alert-type-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cycle-point {
      width: 10px;
      height: 10px;
      background: #7fc458;
      border-radius: 50%;
      margin-right: 12px;
    }
    .alert-type-item-name {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #9ac3f1;
    }
  }
  .alert-type-statistics {
    display: flex;
    padding: 0 20px 0 20px;
    justify-content: space-between;
  }

  .alert-type-statistics-item {
    width: 88px;
    height: 62px;

    /* 创建从上到下的线性渐变 */
    // background: linear-gradient(
    //   to bottom,
    //   #1a3b96,
    //   #00b8d4
    // ); /* 从#66F6E9到另一个颜色 */
    background: #1a3b96;

    border-radius: 10px 0px 10px 0px;
    opacity: 0.95;
  }

  .alert-type-statistics-number {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 18px;
    color: #00d5e9;
    margin: 10px 0 0px 0;
  }
  .alert-type-statistics-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 14px;
    color: #85a9d7;
  }

  .left-down-down-title {
    display: flex;
    margin: 25px 0 0 23px;
    align-items: center;
    .left-down-down-title-line {
      width: 3px;
      height: 11px;
      background: #00cccd;
      margin-right: 10px;
    }
    .left-down-down-title-name {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 16px;
      color: #00d5e9;
    }
  }
}

.middle-up {
  background: url("@/static/home/框.png") no-repeat;
  background-size: contain;
  width: 1052px;
  height: 688px;
  position: relative;
  .map-click {
    position: absolute;
    z-index: 1000;
    margin: 24px;
  }
  .map-back {
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    right: 0;
    margin: 24px 24px 0 0;
  }
  .map-show-box {
    position: absolute;
    z-index: 1000;
    left: 80%;
    top: 35%;

    // transform: translateX(-50%);
  }
  .dian {
    position: absolute;
    z-index: 1000;
    right: 23px;
    bottom: 18px;
    width: 170px;
    height: 16px;
  }
  a {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 22px;
    color: #00d5e9;
  }
  .status-text {
    width: 179px;
    height: 76px;
    background: rgba(2, 18, 51, 0);
    border: 1px solid #41ecf2;
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-text-item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  .map-icon {
    width: 41px;
    height: 39px;
    margin-left: 30px;
  }
  .label {
    font-family: "Microsoft YaHei";
    font-weight: 400;
    font-size: 14px;
    color: #39eaff;
  }
  .percentage {
    font-family: Source Han Sans CN;
    font-weight: bold;
    width: 100px;
    font-size: 34px;
    color: #39eaff;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 隐藏超出的内容 */
    text-overflow: ellipsis; /* 超出的部分显示为省略号 */
  }
}

.middle-down {
  display: flex;
  justify-content: space-between;
  // margin-top: 30px;
  .middle-down-left {
    width: 515px;
    height: 276px;
    background: url("@/static/home/表格.png") center / 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  .middle-down-right {
    width: 515px;
    height: 276px;
    background: url("@/static/home/表格.png") center / 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .middle-down-title-box {
    margin-top: 18px;
  }
  .content-item {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #bad2f5;
    margin-top: 21px;
  }
  .middle-list {
    height: 200px;
    overflow-y: scroll; /* 允许垂直滚动 */
    overflow-x: hidden; /* 隐藏水平滚动条 */

    /* 使用透明边框或背景来隐藏滚动条 */
    -ms-overflow-style: none; /* IE 和 Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.right-all {
  background: url("@/static/home/右侧.png") no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.span-it {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #d3d9ec;
}

.small-title-box {
  display: flex;
  margin: 17px 0 0 23px;
  align-items: center;
  .alert-line {
    width: 3px;
    height: 11px;
    background: #00cccd;
    margin-right: 10px;
  }
  .title-name {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #00d5e9;
    letter-spacing: 3px;
  }
}
.order-container {
  cursor: pointer;
}

.down-title {
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 18px;
  color: #2ef6f7;
  display: flex;
  margin: 5px 0 0 20px;
}
.item-title {
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 18px;
  color: #2ef6f7;
  display: flex;
  margin: 3px 0 0 155px;
}
.logout-box {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  margin: 25px 25px 0 0;
  cursor: pointer;
}
.menu-box {
  background: url("@/static/home/边框.png") center / 100% 100%;
  // background-color: rgba(0, 0, 0, 0.5); /* 添加半透明的黑色背景 */
  // background-blend-mode: overlay; /* 默认混合模式 */
  height: 1080px;
  width: 200px;
  z-index: 1000;
  margin: 25px 25px 0 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
}

.slide-fade-enter-active {
  transition: transform 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-200px);
}
.menu-lan {
  background: url("@/static/home/边栏.png") center / 100% 100%;
  height: 300px;
  width: 20px;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 360px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  // opacity: 0;
}
.slide-lan-enter-active {
  transition: transform 0.3s ease-in-out;
}

.slide-lan-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-lan-enter-from,
.slide-lan-leave-to {
  transform: translateX(-20px);
}

.current-page-menu {
  background-color: transparent !important; /* 设置透明背景 */
  width: 200px;
  height: 1080px;
  border: none; /* 移除默认边框 */
}

.current-page-menu .ant-menu-item {
  background-color: transparent !important; /* 子项也设置透明背景 */
}

.current-page-menu .ant-menu-submenu-menu {
  background-color: transparent !important; /* 子菜单背景也透明 */
}

.current-page-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.05); /* 选中项稍微有点颜色 */
}

.current-page-menu .ant-menu,
.current-page-menu .ant-menu-sub,
.current-page-menu .ant-menu-inline {
  color: white;
  background-color: transparent !important; /* 子菜单背景也透明 */
}
.current-page-menu .ant-menu .ant-menu-sub .ant-menu-inline {
  color: white;
  background-color: transparent !important; /* 子菜单背景也透明 */
}
.current-page-menu .ant-menu-inline {
  text-align: left;
  padding-left: 0px !important; /* 根据需要调整 */
}

.current-page-menu .ant-menu-inline > .ant-menu-item {
  text-align: left;
  padding-left: 0px !important; /* 根据需要调整 */
}

.hover-show {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;    /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 用省略号表示隐藏的文本 */
  width: 200px;        /* 设置固定宽度 */
  border: 1px solid #000; /* 可选：添加边框以便更清楚地看到效果 */
}

.hover-show:hover {
  white-space: normal; /* 恢复默认换行行为 */
  overflow: visible;   /* 显示所有内容 */
  z-index: 1000;       /* 确保悬停时内容显示在其他内容之上 */
  background-color: #fff; /* 可选：设置背景颜色以提高可读性 */
}
</style>