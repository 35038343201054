import axios from 'axios'
import router from '../router'


const serviceAxios = axios.create({
  //根据运行环境来选择相应接口地址
  baseURL: process.env.VUE_APP_URL,
  // baseURL:'/api',
  timeout: 30000, //设置超时
  withCredntials: true, // 跨域请求是否需要携带 cookie
})


/*------------- 拦截器---------*/
// 请求拦截器
serviceAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config
}, function (err) {
  return Promise.reject(err)
})

// 响应拦截器
serviceAxios.interceptors.response.use((response) => {
  return response
}, (error) => {
  //响应错误
  let message = error.message
  if (error.response && error.response.status) {
    const status = error.response.status
    switch (status) {
      case 400:
        message = '请求错误';
        break;
      case 401:
        message = '请求错误';
        console.log(error)
        // console.log(router)
        // localStorage.clear()
        // router.push({ path: "/login" });
        break;
      case 403:
        message = '无权限';
      case 404:
        message = '请求地址出错';
        break;
      case 408:
        message = '请求超时';
        break;
      case 500:
        message = '服务器内部错误!';
        break;
      case 501:
        message = '服务未实现!';
        break;
      case 502:
        message = '网关错误!';
        break;
      case 503:
        message = '服务不可用!';
        break;
      case 504:
        message = '网关超时!';
        break;
      case 505:
        message = 'HTTP版本不受支持';
        break;
      default:
        message = '请求失败'
    }
    // return Promise.reject(error);
    return error
    // return message
  }
})

export default serviceAxios