<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="1000px"
           v-model:open="showModal.addOrEdit"
           title="门店配置"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 13 }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">

                <a-cascader v-if="['customerAndStore'].includes(item.dataIndex)"
                            v-model:value="baseData.customerAndStore"
                            :options="options.customerAndStore"
                            :show-search="{ filter }"
                            placeholder="选择门店"
                            @change="storeChange"
                            change-on-select />
                <a-select v-else-if="['alert_threshold','alert_offline'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :options="[{ label: '是', value: 1 }, { label: '否', value: 0 }]"
                          :placeholder="`请选择${item.title}`" />
                <a-select v-else-if="['electricity_manufacturer','temperature_manufacturer','electricity_merchant_no','temperature_merchant_no','temperature_store_no','electricity_store_no'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :options="options[item.dataIndex]"
                          @change="(value,option)=>selectChange(value,option,item.dataIndex)"
                          :placeholder="`请选择${item.title}`" />
                <a-input-number v-else-if="['duration','threshold'].includes(item.dataIndex)"
                                v-model:value="baseData[item.dataIndex]"
                                style='width:200px'
                                :placeholder="`请输入${item.title}`" />
                <a-input v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="tableSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="edit(record)">编辑</a>
          <!-- <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a> -->

        </div>
      </template>
      <template v-else-if="column.dataIndex === 'status'">
        <div :style='record.status=="失效"?sizeColor:greenColor'>{{ record.status }}</div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div>
          <table class="customerTable">
            <tr>
              <td class='column'
                  style='width:50px;'>关键字</td>
              <td>
                <a-input v-model:value="siftRef.keyword"
                         placeholder="请输入关键字"
                         :bordered="false"
                         style="width:150px"></a-input>
              </td>
              <td class='column'
                  style='width:50px;'>品牌</td>
              <td> <a-select style='width:200px'
                          v-model:value="siftRef.customer_id"
                          :options="options.brand"
                          :bordered="false"
                          :showArrow="false"
                          show-search
                          :filter-option="filterOption"
                          defaultValue=''
                          placeholder="选择品牌"
                          change-on-select /></td>

            </tr>
          </table>

        </div>

        <a-button style="margin:2px"
                  type="primary"
                  @click="sift">搜索</a-button>
        <a-button style="margin:2px"
                  @click="resetSift">重置</a-button>
        <div class='right-buttons'>
          <a-button class="addDataButton"
                    v-if="buttonShow.edit"
                    @click="refresh">刷新</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>

          <!-- <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button> -->
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import { selector } from '@/utils/selector'
import { useRouter, useRoute } from 'vue-router'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/iot/store_config/add_or_update',
  edit: '/v1/iot/store_config/add_or_update',
  page: '/v1/iot/store_config/page',
  refresh: '/v1/iot/store_config/page',
  get_by_Id: '/v1/iot/store_config/get_by_id'
}
const redColor = { color: 'red' }
const greenColor = { color: 'green' }

//确认加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

const siftRef = ref({
  keyword: null,
  customer_id: null
})

// const getButton = () => {
//   let route = useRoute()
//   buttonShow = commonFun.getButtonRules(route.fullPath, url)
// }
// getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [

    // {
    //   title: "租户",
    //   dataIndex: "tenant_name",
    //   key: "tenant_name",
    //   ellipsis: true,

    // },
    {
      title: "品牌",
      dataIndex: "customer_name",
      key: "customer_name",
      ellipsis: true,

    },
    {
      title: "门店",
      dataIndex: "store_name",
      key: "store_name",
      ellipsis: true,

    },
    {
      title: "温度传感器厂商",
      dataIndex: "temperature_manufacturer",
      key: "temperature_manufacturer",
      ellipsis: true,

    },
    {
      title: "电力传感器厂商",
      dataIndex: "electricity_manufacturer",
      key: "electricity_manufacturer",
      ellipsis: true,

    },
    // {
    //   title: "电力传感器厂商",
    //   dataIndex: "electricity_store_name",
    //   key: "electricity_store_name",
    //   ellipsis: true,

    // },
    //     {
    //   title: "电力传感器厂商",
    //   dataIndex: "temperature_store_name",
    //   key: "temperature_store_name",
    //   ellipsis: true,

    // },
    {
      title: "区域",
      dataIndex: "region",
      key: "region",
      ellipsis: true,

    },



    // {
    //   title: "描述",
    //   dataIndex: "desc",
    //   key: "desc"
    // },
    // {
    //   title: "创建时间",
    //   dataIndex: "create_time",
    //   key: "create_time"
    // },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [


    {
      title: "门店",
      dataIndex: "customerAndStore",
      key: "customerAndStore",
    },
    {
      title: "是否开启离线报警",
      dataIndex: "alert_offline",
      key: "alert_offline",
    },
    {
      title: "是否开启阈值报警",
      dataIndex: "alert_threshold",
      key: "alert_threshold",
    },



    {
      title: "电量传感器生产厂商",
      dataIndex: "electricity_manufacturer",
      key: "electricity_manufacturer",
    },

    {
      title: "电量传感器第三方平台商户号 ",
      dataIndex: "electricity_merchant_no",
      key: "electricity_merchant_no",
    },
    {
      title: "电量传感器第三方平台门店号 ",
      dataIndex: "electricity_store_no",
      key: "electricity_store_no",
    },
    {
      title: "电量传感器第三方平台门店名称 ",
      dataIndex: "electricity_store_name",
      key: "electricity_store_name",
    },



    {
      title: "温度传感器生产厂商",
      dataIndex: "temperature_manufacturer",
      key: "temperature_manufacturer",
    },
    {
      title: "温度传感器第三方平台商户号 ",
      dataIndex: "temperature_merchant_no",
      key: "temperature_merchant_no",
    },
    {
      title: "温度传感器第三方平台门店号 ",
      dataIndex: "temperature_store_no",
      key: "temperature_store_no",
    },
    {
      title: "温度传感器第三方平台门店名称 ",
      dataIndex: "temperature_store_name",
      key: "temperature_store_name",
    },
  ]
})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: [],
})

//增加或者编辑的模态框规则
const rules = {

  customerAndStore: [
    {
      required: true,
      message: "请选择门店",
    },
  ],


}

//选择框数据
const options = reactive({

  tenant: [],
  storeList: [],
  storeAll: [],
  host: [],
  customerAndStore: [],
  yesOrNo: [{ label: '是', value: 1 }, { label: '否', value: 0 }],
  temperature_manufacturer: [],
  electricity_manufacturer: [],
  temperature_merchant_no: null,
  electricity_merchant_no: null,
  temperature_store_no: null,
  electricity_store_no: null,
});

// 数据模型
const baseData = reactive({
  id: null,
  customerAndStore: null,
  customer_id: null,
  store_id: null,
  alert_offline: null,
  alert_threshold: null,
  temperature_manufacturer: null,
  electricity_manufacturer: null,

  electricity_merchant_no: null,
  electricity_store_no: null,
  electricity_store_name: null,
  temperature_merchant_no: null,
  temperature_store_no: null,
  temperature_store_name: null,
})

//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false
})



let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;
    tableSource.data = result.data.data.list.map(item => ({
      id: item.id,
      key: item.id,
      customer_name: item.customer_name,
      tenant_name: item.tenant_name,
      store_name: item.store_name,
      
      temperature_manufacturer: item.temperature_manufacturer,
      electricity_manufacturer: item.electricity_manufacturer,
      electricity_store_name: item.electricity_store_name,
      region: item.region.map(item => item.name).join(" "),
      desc: item.desc,
      create_time: item.create_time,
      item: item

    }));
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
};

const formRef = ref()


//编辑数据
const edit = async (record) => {
  let response = await serviceAxios({
    url: url.get_by_Id,
    method: 'get',
    params: { store_id: record.item.store_id } // 确保参数格式正确
  });
  if (response.data.message == 'success') { // 假设成功响应的状态码为200且返回的data中有一个success标志
    let data = response.data.data;

    if (data) {
      // 更新 baseData 对象中的属性
      for (let key in baseData) {
        if (data.hasOwnProperty(key)) { // 确保 data 对象中有这个属性
          baseData[key] = data[key];
        }
      }
      console.log('baseData', baseData)
      baseData.customerAndStore = [data.customer_id, data.store_id]

      storeChange(baseData.customerAndStore)

      urlFlag = url.edit;
      showModal.addOrEdit = true;


    } else {
      console.error('No valid data returned from the server.');
    }
  } else {
    console.error('Server did not return a success response.');
  }

};
//

//增加数据
const addData = () => {
  resetForm();

  baseData.alert_threshold = 1;
  baseData.alert_offline = 1;
  showModal.addOrEdit = true;
  urlFlag = url.add
};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};

//确认删除数据
// const handleOkDelete = () => {
//   // 执行删除操作
//   // console.log("执行删除操作");
//   let ids = [];
//   rowData.forEach((item) => {
//     ids.push(item.id);
//   });
//   serviceAxios({
//     url: url.delete,
//     method: "post",
//     data: {
//       ids: ids,
//     },
//   })
//     .then(
//       (result) => {
//         queryPage()
//       }

//     )
//     .catch((err) => {
//       message.error("请求异常，请联系管理员");
//       console.log(err);
//     });
//   showModal.isDeleteModalVisible = false;
// };



//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      customer_id: baseData.customerAndStore[0],
      store_id: baseData.customerAndStore[1],
      alert_offline: baseData.alert_offline,
      alert_threshold: baseData.alert_threshold,

      //厂商
      temperature_manufacturer: baseData.temperature_manufacturer,
      electricity_manufacturer: baseData.electricity_manufacturer,

      //平台商户号
      electricity_merchant_no: baseData.electricity_merchant_no,
      electricity_store_no: baseData.electricity_store_no,
      electricity_store_name: baseData.electricity_store_name,
      temperature_merchant_no: baseData.temperature_merchant_no,
      temperature_store_no: baseData.temperature_store_no,
      temperature_store_name: baseData.temperature_store_name



    }
    spinning.value = true
    serviceAxios({
      url: url.add,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch {
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  for (let key in siftRef.value) {
    queryField[key] = siftRef.value[key]

  }
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  siftRef.value.keyword = null
  siftRef.value.customer_id = null
  queryPage()
}



const refresh = () => {
  queryPage()
}





const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

//门店change 获取第三方平台商户
const storeChange = async (value, option) => {
  console.log(value, option)
  if (value && value.length == 2) {
    spinning.value = true
    let res = await selector.third_part_platform_merchant("温度传感器", value[1])
    options.temperature_merchant_no = res == false ? [] : res


    res = await selector.third_part_platform_merchant("电量传感器", value[1])
    options.electricity_merchant_no = res == false ? [] : res
    spinning.value = false
  }

}

const thirdPartData = {
  merchant_no: '',//商户
  store_no: ''//门店
}


const selectChange = async (value, option, text) => {
  console.log(value, option, text)
  spinning.value = true
  console.log('厂商', baseData.temperature_manufacturer, baseData.electricity_manufacturer)
  if (text == 'temperature_merchant_no' && baseData.temperature_manufacturer) {
    let res = await selector.third_part_platform_store("温度传感器", baseData.temperature_manufacturer, value)
    options.temperature_store_no = res == false ? [] : res
    thirdPartData.merchant_no = value
  }

  if (text == "electricity_merchant_no" && baseData.electricity_manufacturer) {
    let res = await selector.third_part_platform_store("电量传感器", baseData.electricity_manufacturer, value)
    options.electricity_store_no = res == false ? [] : res
    thirdPartData.merchant_no = value
  }

  if (text == 'electricity_store_no') {
    baseData.electricity_store_name = option.label
  }

  if (text == 'temperature_store_no') {
    baseData.temperature_store_name = option.label
  }

  spinning.value = false
}


















(async () => {
  options.storeAll = await selector.storesUnderTheBrand()
  options.brand = await selector.brand()
  console.log('options.storeAll', options.storeAll)
  options.customerAndStore = options.storeAll.map(item => ({
    label: item.label,
    value: item.data.brand_id,
    children: item.children,
  }))
  options.temperature_manufacturer = await selector.temperature_manufacturer()
  options.electricity_manufacturer = await selector.electricity_manufacturer()
})()


</script>
<style lang="less" scoped>
@import "@/static/css/table.css";

.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}

.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
</style>