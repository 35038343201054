<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <a-modal :maskClosable="false"
           width="400px"
           v-model:open="modalShow"
           title="节点信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="handleOk">
    <a-form :ref="deviceRef"
            :model="devices"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 16 }">
      <a-form-item label="设备类型"
                   name='type'
                   :rules="[
    {
      required: true,
      message: '请输入设备类型',
    },
  ]">

        <a-select v-model:value="devices.type"
                  show-search
                  placeholder="请选择类型"
                  style="width: 200px"
                  :options="options.type"
                  :filter-option="filterOption"></a-select>
      </a-form-item>
      <a-form-item label="标签"
                   name='label'
                   :rules="[
    {
      required: true,
      message: '请输入标签',
    },
  ]">
        <a-input style="width:200px"
                 v-model:value="devices.label"
                 placeholder="请输入标签" />
      </a-form-item>

      <a-form-item label="IP"
                   name='ip'
                   :rules="[
    {
      required: false,
      message: '请输入IP',
    },
  ]">
        <a-input style="width:200px"
                 v-model:value="devices.ip"
                 placeholder="请输入IP" />
      </a-form-item>

      <a-form-item label="离线报警阈值"
                   name='offline_threshold'
                   :rules="[
    {
      required: false,
      message: '请输入离线报警阈值',
    },
  ]">
        <a-input-number style="width:200px"
                        v-model:value="devices.offline_threshold"
                        placeholder="请输入数字类型" />
      </a-form-item>

      <!-- <a-form-item label="节点标识" :name='label' :rules="[
    {
      required: false,
      message: '请输入节点标识',
    },
  ]">
        <a-input-number style="width:200px" v-model:value="devices.cluster" placeholder="请输入数字类型" />
      </a-form-item> -->

    </a-form>

  </a-modal>
  <div>
    <table class="table">
      <tr>
        <td class="column">门店名称<span style="color: red;margin-left: 0px;">*</span></td>
        <td> <a-cascader :bordered="false"
                      style="width:auto"
                      v-model:value="baseData.contact"
                      :options="options.contract"
                      :show-search="{ filter }"
                      placeholder="请选择门店"
                      @change='onContractChange' /></td>
        <td class="column">回滚模式</td>
        <td>
          <a-select v-model:value="baseData.back_or_reimport"
                    show-search
                    style="width: 200px"
                    placeholder="请选择类型"
                    :options="options.back_or_reimport"
                    :filter-option="filterOption"></a-select>
        </td>
        <td class='column'>拓扑图记录</td>
        <td>
          <div class='glopt-record-box'> <a-select v-model:value="baseData.import_id"
                      show-search
                      placeholder="请选择类型"
                      style="width: 200px"
                      :options="options.import_id"
                      :filter-option="filterOption"></a-select>

            <a-button style='margin:5px;'
                      @click="recordBack"
                      type="primary">回滚</a-button>
          </div>
        </td>
        <td class="column">导入拓扑图</td>
        <td>

          <input type="file"
                 ref="fileInput"
                 @change="importGplot"
                 accept=".xlsx, .xls" />
        </td>
        <!-- <td class="column">保存操作</td> -->
        <td>

          <a-button style='margin:5px;'
                    @click="saveGplot"
                    type="primary">保存拓扑图</a-button>

        </td>
      </tr>
    </table>
  </div>
  <div style="width: 100%; height: 100%;"
       id="container"></div>

</template>

<script setup>
// 假设graph是你的G6 Graph实例，且已经初始化并渲染
// graph.render();

// // 在渲染完成后手动添加边
// const specialEdge = {
//   source: 'c1-2', // 起始节点ID
//   target: 'c3-2-1', // 目标节点ID
//   style: { // 自定义边的样式
//     lineWidth: 3,
//     stroke: '#f00', // 红色边线
//     lineAppendWidth: 5, // 边线末端扩展宽度
//   },
// };

// // 添加边到图中
// graph.addItem('edge', specialEdge);

import { onMounted, nextTick, reactive, ref, onBeforeUnmount, watch } from "vue";
import { message } from "ant-design-vue";
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import svg from "@/utils/svgData";
import interfaces from "@/config";
import G6 from '@antv/g6';
import { useScreenSize } from '@/utils/useScreenSize';

import { storeMessage } from '@/utils/commonData'

import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
//节点数据
let deviceMessage = [
  {
    name: '无线接入控制器(ac)',
    type: 'ac',
    path: 'ap'
  },
  {
    name: '无线接入点(ap)',
    type: 'ap',
    path: 'ap'
  },
  {
    name: '台式机',
    type: 'pc',
    path: 'pc'
  },
  {
    name: '服务器',
    type: 'server',
    path: '服务器'
  },
  {
    name: '数据库服务器',
    type: 'db_server',
    path: '数据库服务器'
  },

  {
    name: '磁盘录像机',
    type: 'dvr',
    path: '磁盘录像机'
  },
  {
    name: '媒体服务器',
    type: 'media_server',
    path: '媒体服务器'
  },
  {
    name: '播放控制器',
    type: 'play_controller',
    path: '播放控制器'
  },
  {
    name: '视频分路器',
    type: 'video_splitter',
    path: '视频分路器'
  },
  {
    name: '视频拼接器',
    type: 'video_splicer',
    path: '视频拼接器'
  },
  {
    name: 'led控制器',
    type: 'led_controller',
    path: 'LED控制器'
  },
  {
    name: 'led播放器',
    type: 'led_player',
    path: 'LED播放器'
  },
  {
    name: 'lcd播放器',
    type: 'lcd_player',
    path: 'lcd'
  },
  {
    name: '防火墙',
    type: 'firewall',
    path: '防火墙'
  },
  {
    name: '传感器',
    type: 'sensor',
    path: '传感器'
  },
  {
    name: '其他',
    type: 'other',
    path: '其他'
  },
]
const { windowWidth, windowHeight } = useScreenSize();
const deviceRef = ref(null) //添加设备表单
const modalShow = ref(null)
const baseData = reactive({
  zabbix_host: null,
  contact: null,
  name: null,
  size: [],
  import_id: null,
  back_or_reimport: 0,
})
let graphInstance = ''
const otherData = reactive({
  host_name: ""
})
let devices = reactive({
  id: "",
  type: "",
  size: "",
  label: "",
  ip: "",
  offline_threshold: "",
  // cluster: "",
})
const options = reactive({
  zabbix_host: [],
  contract: [],
  type: [
    { label: '互联网', value: 'internet' },
    { label: '网关', value: 'gateway' },
    { label: '路由器', value: 'router' },
    { label: '交换机', value: 'switch' },
    { label: '摄像头', value: 'camera' },
    { label: '打印机', value: 'printer' },
    { label: '笔记本', value: 'laptop' }

  ],
  import_id: [],
  back_or_reimport: [
    { label: '回滚到指定导入记录', value: 0 },
    { label: '重新导入指定记录的拓扑图', value: 1 },
  ]

})

//端口默认数据
let interfacePorts = ref([
  { id: 'port2', x: -15, y: 7, status: true },
  { id: 'port3', x: -5, y: 7, status: true },
  { id: 'port4', x: 5, y: 7, status: true },
  { id: 'port5', x: 15, y: 7, status: true },


  { id: 'port6', x: 25, y: 7, status: true },
  { id: 'port1', x: -25, y: 7, status: true },
  { id: 'port7', x: 35, y: 7, status: true },
  { id: 'port0', x: -35, y: 7, status: true },
])




let data = {
  id: `root`,
  label: '因特网',
  type: "internet",
  children: [{
    id: `gateway`,
    label: '网关',
    type: "gateway",
    size: [150, 40],
    ports: [
      { id: 'port2', x: -15, y: 7, status: true },
      { id: 'port3', x: -5, y: 7, status: true },
      { id: 'port4', x: 5, y: 7, status: true },
      { id: 'port5', x: 15, y: 7, status: true },
      { id: 'port0', x: -35, y: 7, status: true },
      { id: 'port6', x: 25, y: 7, status: true },
      { id: 'port1', x: -25, y: 7, status: true },
      { id: 'port7', x: 35, y: 7, status: true },
    ],
    children: [
      {
        id: 'c1',
        label: 'c1',
        type: 'router',
        children: [
          {
            id: 'c1-1',
            label: 'c1-1',
            type: "camera",
          },
          {
            id: 'c1-4',
            label: 'c1-4',
            type: "printer",
          },
          {
            id: 'c1-2',
            label: 'c1-2',
            type: "laptop",
            children: [
              {
                id: 'c1-2-1',
                label: 'c1-2-1',
              },
              {
                id: 'c1-2-2',
                label: 'c1-2-2',
              },
            ],
          },
        ],
      },
      {
        id: 'c2',
        label: 'c2',
        type: 'switch'
      },
      {
        id: 'c3',
        label: 'c3',
        children: [
          {
            id: 'c3-1',
            label: 'c3-1',
          },
          {
            id: 'c3-2',
            label: 'c3-2',
            children: [
              {
                id: 'c3-2-1',
                label: 'c3-2-1',
              },
              {
                id: 'c3-2-2',
                label: 'c3-2-2',
              },
              {
                id: 'c3-2-3',
                label: 'c3-2-3',
              },
            ],
          },
          {
            id: 'c3-3',
            label: 'c3-3',
          },
        ],
      },
    ],
  }]
};


onMounted(() => {
  const COLLAPSE_ICON = function COLLAPSE_ICON (x, y, r) {
    return [
      ['M', x - r, y - r],
      ['a', r, r, 0, 1, 0, r * 2, 0],
      ['a', r, r, 0, 1, 0, -r * 2, 0],
      ['M', x + 2 - r, y - r],
      ['L', x + r - 2, y - r],
    ];
  };
  const EXPAND_ICON = function EXPAND_ICON (x, y, r) {
    return [
      ['M', x - r, y - r],
      ['a', r, r, 0, 1, 0, r * 2, 0],
      ['a', r, r, 0, 1, 0, -r * 2, 0],
      ['M', x + 2 - r, y - r],
      ['L', x + r - 2, y - r],
      ['M', x, y - 2 * r + 2],
      ['L', x, y - 2],
    ];
  };

  const defaultStateStyles = {
    hover: {
      stroke: '#1890ff',
      lineWidth: 2,
    },
  };

  const defaultNodeStyle = {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 5,
  };

  const defaultEdgeStyle = {
    stroke: '#91d5ff',
    endArrow: {
      path: 'M 0,0 L 12, 6 L 9,0 L 12, -6 Z',
      fill: '#91d5ff',
      d: -20,
    },
  };

  const defaultLayout = {
    type: 'compactBox',
    direction: 'TB',
    getId: function getId (d) {
      return d.id;
    },
    getHeight: function getHeight () {
      return 16;
    },
    getWidth: function getWidth () {
      return 16;
    },
    getVGap: function getVGap () {
      return 40;
    },
    getHGap: function getHGap () {
      return 70;
    },
  };

  const defaultLabelCfg = {
    style: {
      fill: '#000',
      fontSize: 12,
    },
  };

  deviceMessage.forEach(
    item => {
      G6.registerNode(item.type, {
        draw (cfg, group) {
          const { ports = [] } = cfg;
          cfg.size = [40, 40];
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;

          const w = styles.width;
          const h = styles.height;

          const keyShape = group.addShape('rect', {
            attrs: {
              // ...styles,

              width: w,
              height: h + 10,
              x: -w / 2,
              y: -h / 2,
            },
          });

          /**
           * leftIcon 格式如下：
           *  {
           *    style: ShapeStyle;
           *    img: ''
           *  }
           */
          //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
          if (cfg.leftIcon) {
            let { style, img } = cfg.leftIcon;

            img = require(`@/assets/glopt/${item.path}.png`)
            group.addShape('rect', {
              attrs: {
                // x: 1 - w / 2,
                // y: 1 - h / 2,
                // width: 48,
                // height: styles.height - 2,
                // fill: '#8c8c8c',
                // ...style,
              },
            });

            group.addShape('image', {
              attrs: {
                x: - w / 2,
                y: - h / 2,
                width: 38,
                height: 38,
                img: img
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'image-shape',
            });
          }

          // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
          group.addShape('marker', {
            attrs: {
              x: 40 - w / 2,
              y: 52 - h / 2,
              r: 6,
              stroke: '#73d13d',
              cursor: 'pointer',
              symbol: EXPAND_ICON,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'add-item',
          });

          group.addShape('marker', {
            attrs: {
              x: 80 - w / 2,
              y: 52 - h / 2,
              r: 6,
              stroke: '#ff4d4f',
              cursor: 'pointer',
              symbol: COLLAPSE_ICON,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'remove-item',
          });

          if (cfg.label) {
            group.addShape('text', {
              attrs: {
                ...labelCfg.style,
                text: cfg.label,
                x: 50 - w / 2,
                y: 25 - h / 2,
              },
            });
          }
          ports.forEach((portPos, index) => {
            const portRadius = 5;
            const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
            group.addShape('circle', {
              attrs: {
                x: portPos.x,
                y: portPos.y,
                r: portRadius,
                fill: portColor,
                stroke: '#fff',
                lineWidth: 1,
              },
              name: `port-${index}`, // 为端口命名，便于后续交互处理
            });
          });

          return keyShape;
        },
        update: undefined,
      },
        'rect',
      );



    }
  )

  G6.registerNode('gateway', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [150, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.echangeRuijieImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 148,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 160 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 200 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 160 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode(
    'internet',
    {
      options: {
        size: [80, 80],
        stroke: '#91d5ff',
        fill: '#91d5ff',
      },
      draw (cfg, group) {
        cfg.size = [50, 50];
        const styles = this.getShapeStyle(cfg);
        const { labelCfg = {} } = cfg;

        const w = styles.width;
        const h = styles.height;

        const keyShape = group.addShape('rect', {
          attrs: {
            ...styles,
            x: -w / 2,
            y: -h / 2,
          },
        });

        /**
         * leftIcon 格式如下：
         *  {
         *    style: ShapeStyle;
         *    img: ''
         *  }
         */
        //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
        if (cfg.leftIcon) {
          let { style, img } = cfg.leftIcon;
          img = svg.svgData.diqiuImg
          // img = ap
          group.addShape('rect', {
            attrs: {
              x: 1 - w / 2,
              y: 1 - h / 2,
              width: 48,
              height: styles.height - 2,
              fill: '#8c8c8c',
              ...style,
            },
          });

          group.addShape('image', {
            attrs: {
              x: - w / 2,
              y: - h / 2,
              width: 50,
              height: 50,
              img:
                img ||
                'https://g.alicdn.com/cm-design/arms-trace/1.0.155/styles/armsTrace/images/TAIR.png',
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'image-shape',
          });
        }

        // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
        group.addShape('marker', {
          attrs: {
            x: 50 - w / 2,
            y: 62 - h / 2,
            r: 6,
            stroke: '#73d13d',
            cursor: 'pointer',
            symbol: EXPAND_ICON,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'add-item',
        });

        group.addShape('marker', {
          attrs: {
            x: 90 - w / 2,
            y: 62 - h / 2,
            r: 6,
            stroke: '#ff4d4f',
            cursor: 'pointer',
            symbol: COLLAPSE_ICON,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'remove-item',
        });

        if (cfg.label) {
          group.addShape('text', {
            attrs: {
              ...labelCfg.style,
              text: cfg.label,
              x: 60 - w / 2,
              y: 35 - h / 2,
            },
          });
        }

        return keyShape;
      },
      update: undefined,
    },
    'rect',
  );

  G6.registerNode('router', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.routerImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 40 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 80 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('switch', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [80, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.exchangeImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 78,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 70 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 110 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 80 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('camera', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.cameraImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 40 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 80 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('printer', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.printer
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 40 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 80 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('laptop', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.hostImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
      group.addShape('marker', {
        attrs: {
          x: 40 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#73d13d',
          cursor: 'pointer',
          symbol: EXPAND_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'add-item',
      });

      group.addShape('marker', {
        attrs: {
          x: 80 - w / 2,
          y: 52 - h / 2,
          r: 6,
          stroke: '#ff4d4f',
          cursor: 'pointer',
          symbol: COLLAPSE_ICON,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'remove-item',
      });

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerBehavior('double-click-node', {
    getEvents () {
      return {
        'node:dblclick': 'doubleClick',
      };
    },
    doubleClick (ev) {
      // console.log(self, graph)
      const node = ev.item; // 从事件中获取边
      console.log("双击节点", node);
      showNodeInfoInput(node)
    },
  });

  //边
  G6.registerEdge('flow-line', {
    draw (cfg, group) {
      const startPoint = cfg.startPoint;
      const endPoint = cfg.endPoint;
      let paths = [
        ['M', startPoint.x, startPoint.y],
        ['L', startPoint.x, (startPoint.y + endPoint.y) / 2],
        ['L', endPoint.x, (startPoint.y + endPoint.y) / 2],
        ['L', endPoint.x, endPoint.y],
      ]
      if (startPoint.x > endPoint.x) {
        paths[0] = ['M', startPoint.x - (startPoint.x - endPoint.x) / 40, startPoint.y]
        paths[1] = ['L', startPoint.x - (startPoint.x - endPoint.x) / 40, ((startPoint.y + endPoint.y) / 2) - (startPoint.x - endPoint.x) / 40]
        paths[2] = ['L', endPoint.x, ((startPoint.y + endPoint.y) / 2) - (startPoint.x - endPoint.x) / 40]

      }
      else if (startPoint.x < endPoint.x) {
        paths[0] = ['M', startPoint.x + (endPoint.x - startPoint.x) / 40, startPoint.y]
        paths[1] = ['L', startPoint.x + (endPoint.x - startPoint.x) / 40, ((startPoint.y + endPoint.y) / 2) - (endPoint.x - startPoint.x) / 40]
        paths[2] = ['L', endPoint.x, ((startPoint.y + endPoint.y) / 2) - (endPoint.x - startPoint.x) / 40]


      }

      const { style } = cfg;
      const shape = group.addShape('path', {
        attrs: {
          stroke: style.stroke,
          // endArrow: style.endArrow,
          path: paths,
        },
      });

      return shape;
    },

    // afterDraw (cfg, group) {
    //   // get the first shape in the group, it is the edge's path here=
    //   const shape = group.get('children')[0];
    //   // the start position of the edge's path
    //   const startPoint = shape.getPoint(0);

    //   // add red circle shape
    //   const circle = group.addShape('circle', {
    //     attrs: {
    //       x: startPoint.x,
    //       y: startPoint.y,
    //       fill: '#1890ff',
    //       r: 3,
    //     },
    //     // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
    //     name: 'circle-shape',
    //   });

    //   // animation for the red circle
    //   circle.animate(
    //     (ratio) => {
    //       // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
    //       // get the position on the edge according to the ratio
    //       const tmpPoint = shape.getPoint(ratio);
    //       // returns the modified configurations here, x and y here
    //       return {
    //         x: tmpPoint.x,
    //         y: tmpPoint.y,
    //       };
    //     },
    //     {
    //       repeat: true, // Whether executes the animation repeatly
    //       duration: 3000, // the duration for executing once
    //     },
    //   );
    // },
  });

  G6.Util.traverseTree(data, (d) => {
    d.leftIcon = {
      style: {
        fill: '#e6fffb',
        stroke: '#e6fffb',
      },
      img: 'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ',
    };
    return true;
  });

  G6.registerNode(
    'icon-node',
    {
      options: {
        size: [60, 20],
        stroke: '#91d5ff',
        fill: '#91d5ff',
      },
      draw (cfg, group) {
        const styles = this.getShapeStyle(cfg);
        const { labelCfg = {} } = cfg;

        const w = styles.width;
        const h = styles.height;

        const keyShape = group.addShape('rect', {
          attrs: {
            ...styles,
            x: -w / 2,
            y: -h / 2,
          },
        });

        /**
         * leftIcon 格式如下：
         *  {
         *    style: ShapeStyle;
         *    img: ''
         *  }
         */
        // console.log('cfg.leftIcon', cfg.leftIcon);
        if (cfg.leftIcon) {
          const { style, img } = cfg.leftIcon;
          group.addShape('rect', {
            attrs: {
              x: 1 - w / 2,
              y: 1 - h / 2,
              width: 38,
              height: styles.height - 2,
              fill: '#8c8c8c',
              ...style,
            },
          });

          group.addShape('image', {
            attrs: {
              x: 8 - w / 2,
              y: 8 - h / 2,
              width: 24,
              height: 24,
              img:
                img ||
                'https://g.alicdn.com/cm-design/arms-trace/1.0.155/styles/armsTrace/images/TAIR.png',
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'image-shape',
          });
        }

        // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker
        group.addShape('marker', {
          attrs: {
            x: 40 - w / 2,
            y: 52 - h / 2,
            r: 6,
            stroke: '#73d13d',
            cursor: 'pointer',
            symbol: EXPAND_ICON,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'add-item',
        });

        group.addShape('marker', {
          attrs: {
            x: 80 - w / 2,
            y: 52 - h / 2,
            r: 6,
            stroke: '#ff4d4f',
            cursor: 'pointer',
            symbol: COLLAPSE_ICON,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'remove-item',
        });

        if (cfg.label) {
          group.addShape('text', {
            attrs: {
              ...labelCfg.style,
              text: cfg.label,
              x: 50 - w / 2,
              y: 25 - h / 2,
            },
          });
        }

        return keyShape;
      },
      update: undefined,
    },
    'rect',
  );

  const container = document.getElementById('container');
  const width = container.scrollWidth;
  const height = container.scrollHeight;

  const minimap = new G6.Minimap({
    size: [150, 100],
  });
  graphInstance = new G6.TreeGraph({
    container: 'container',
    width,
    height,
    linkCenter: true,
    maxZoom: 1,
    plugins: [minimap],
    modes: {
      default: ['drag-canvas', 'zoom-canvas', 'double-click-node',
      ],
    },
    defaultNode: {
      type: 'icon-node',
      size: [40, 40],
      style: defaultNodeStyle,
      labelCfg: defaultLabelCfg,
    },
    defaultEdge: {
      type: 'flow-line',
      style: defaultEdgeStyle,
    },
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    layout: defaultLayout,
  });

  function resizeGraph () {
    try {

      if (graphInstance) {
        const container = document.getElementById('container');
        const newWidth = container.clientWidth;
        const newHeight = container.clientHeight;
        console.log('container', container.children[0])
        graphInstance.changeSize(newWidth, newHeight); // 更改图表的宽高

        // let transform = localStorage.getItem("transform")
        // transform = JSON.parse(transform)
        // console.log('transform', transform)
        // // 如果需要手动调整 transform
        // const wScale = transform.wScale;
        // const hScale = transform.hScale;
        // container.style.transform = `scale(${1 / wScale}, ${1 / hScale})`;
        graphInstance.fitView(); // 自动调整图表的布局

        // container.style.transformOrigin = `${transformOriginX}px ${transformOriginY}px`;

        // 动态设置图表的宽高

        // 适应视图，确保所有的节点都在可视范围内
      }
    }
    catch (error) {
      console.log(error)
    }
  }





  // 添加窗口resize事件监听，当窗口大小改变时重新调整图表尺寸
  window.addEventListener('resize', () => {
    resizeGraph();
  });
  resizeGraph()



  graphInstance.on('node:mouseenter', (evt) => {
    const node = evt.item
    const model = node.getModel()
    // console.log(node, model)
    model.oriLabel = model.label
    model.orilabelCfg = model.labelCfg
    // const status = model.labelCfg.style == '#222831' ? '异常' : '正常'
    const ip = model.ip != undefined ? model.ip : ''
    graphInstance.updateItem(node, {
      label: `${model.label}\n${ip}`,
      labelCfg: {
        style: {
          fill: '#003a8c',
        },
      },
    })
    console.log(node, model)
    graphInstance.setItemState(node, 'hover', true);
  });

  graphInstance.on('node:mouseleave', (evt) => {
    const node = evt.item;
    const model = node.getModel();
    graphInstance.updateItem(node, {
      label: model.oriLabel,
      labelCfg: model.orilabelCfg
    });
    // console.log('2')
    graphInstance.setItemState(node, 'hover', false);
  });

  graphInstance.on('node:click', (evt) => {
    const { item, target } = evt;
    const targetType = target.get('type');
    const name = target.get('name');

    // 增加元素
    if (targetType === 'marker') {
      const model = item.getModel();
      if (name === 'add-item') {
        if (!model.children) {
          model.children = [];
        }
        const id = `n-${Math.random()}`;
        model.children.push({
          id,
          // label: id.substr(0, 8),
          type: devices.type,
          label: devices.label,
          leftIcon: {
            style: {
              fill: '#e6fffb',
              stroke: '#e6fffb',
            },
            img:
              'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ',
          },
        });
        graphInstance.updateChild(model, model.id);
      } else if (name === 'remove-item') {
        graphInstance.removeChild(model.id);
      }
    }
  });

  if (typeof window !== 'undefined')
    window.onresize = () => {
      if (!graphInstance || graphInstance.get('destroyed')) return;
      if (!container || !container.scrollWidth || !container.scrollHeight) return;
      graphInstance.changeSize(container.scrollWidth, container.scrollHeight);
    };

});




let nodeItem = ref()

watch(nodeItem.value, (new1, old1) => {
  console.log(new1, old1)
})
function showNodeInfoInput (node) {
  modalShow.value = true
  nodeItem.value = node
  let nodeData = node._cfg.model
  const label = nodeData.label.split('\n')[0]
  // console.log('qige', nodeData.label.split('\n'))
  console.log(node._cfg)
  devices.label = label
  devices.type = nodeData.type
  devices.ip = nodeData.ip
  devices.offline_threshold = nodeData.offline_threshold
  devices.cluster = nodeData.cluster
};

const handleOk = () => {
  console.log("devices", devices)
  nodeItem.value.update({
    label: devices.label,
    type: devices.type,
    ip: devices.ip,
    offline_threshold: devices.offline_threshold,
    cluster: devices.cluster,
    // size: [width, height]
  });
  modalShow.value = false
  // data.nodes.forEach(item =>id {
  //   item = getSvg(item)
  // })
  graphInstance.data(data);
  // graphInstance.render();
  // graphInstance.fitView()

}






//获取门店合同信息
const contractData = {}
const contractPage = () => {
  storeMessage().then(result => {
    console.log('result', result)
    options.contract = result
  }).catch(error => {
    console.log(error)
  })
}
contractPage()


//清洗拓扑图data，将四层和四层一下的同层中相同node组成一个combo
const clearNodeToCombo = () => {
  let comboList = []
  data.children[0].children[1].children.forEach(item => {
    comboList.push(item.id)
  })
  console.log('comboList', comboList)
  graphInstance.createCombo('combo1', comboList)
  graphInstance.collapseCombo('combo1')
  console.log()
}

const getGplot = (id) => {
  serviceAxios({
    url: interfaces.get_gplot,
    method: 'get',
    params: {
      store_id: id
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let gplotData = result.data.data
      console.log('gplotData', gplotData)
      data = gplotData.topological_structure

      // baseData.zabbix_host = gplotData.ip
      otherData.host_name = gplotData.ip
      baseData.name = gplotData.name
      // clearNodeToCombo()
      console.log('data', data)
      getNodeStatus()
      graphInstance.data(data);
      graphInstance.render();
      graphInstance.fitView();

    }
    else {
      // message.error(result.data.message)
      // baseData.zabbix_host = null
      // baseData.name = null
      saveGplot()

      data.children[0].children = []
      console.log('data1111', data)
      graphInstance.read(data);
      getNodeStatus()
      console.log("初始化数据")
      // graphInstance.render(); // 触发重新渲染
      graphInstance.fitView();



    }
    // console.log("所有的节点数据", graphInstance.getNodes())

  }).catch()
}

const hostChange = (value, option) => {
  console.log(value, option)
  otherData.host_name = option.label
  console.log(otherData.host_name)

}

const onContractChange = async (value, selectedOptions) => {
  console.log(value, selectedOptions)
  let store_id = value[1]
  const result = await serviceAxios({
    url: interfaces.getHostId,
    method: 'get',
    params: {
      "store_id": store_id
    }
  })

  if (result.data.message == 'success') {
    getInterface(result.data.data.id, store_id)
    baseData.zabbix_host = result.data.data.host
  }
  else {
    // message.error(result.data.message)
    interfacePorts.value = interfacePorts.value.slice(0, 4)
    data.children[0].ports = interfacePorts.value
    getGplot(store_id)
  }

  getGloptRecord(value[1])

}


//获取网关的端口数量
const getInterface = (id, store_id) => {
  serviceAxios({
    url: interfaces.interfaceTriggerGetGateway,
    method: 'get',
    params: {
      gateway_id: Number(id)
    }
  }).then(
    result => {

      if (result.data.message == 'success') {
        let numberFlag = 0
        interfacePorts.value = [
          { id: 'port2', x: -15, y: 7, status: true },
          { id: 'port3', x: -5, y: 7, status: true },
          { id: 'port4', x: 5, y: 7, status: true },
          { id: 'port5', x: 15, y: 7, status: true },


          { id: 'port6', x: 25, y: 7, status: true },
          { id: 'port1', x: -25, y: 7, status: true },
          { id: 'port7', x: 35, y: 7, status: true },
          { id: 'port0', x: -35, y: 7, status: true },
        ]
        let portLength = result.data.data.physical_interfaces_count
        interfacePorts.value = interfacePorts.value.slice(0, portLength)
        console.log('interfacePorts', portLength, interfacePorts.value)
        result.data.data.interfaces.forEach(item => {
          if (item.eth_number != null && typeof (item.eth_number) == 'number') {
            interfacePorts.value[numberFlag].id = item.id
            interfacePorts.value[numberFlag].name = item.name
            numberFlag += 1
          }
        })
        data.children[0].ports = interfacePorts.value
        getGplot(store_id)
      }
      else {
        // message.error(result.data.message)
      }


    }
  ).catch(error => {
    console.log(error)
    // message.error("请求异常")
  })
}


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const getDeviceType = () => {
  serviceAxios({
    url: interfaces.gloptDeviceType,
    method: 'get',
  }).then(
    result => {
      if (result.data.message = "success") {
        options.type = result.data.data.map(item => ({
          label: item.name,
          value: item.value
        }))
      }
      else {
        // message.error(result.data.message)
      }
    }
  ).catch(error => {
    // message.error('请求异常')
  })
}
getDeviceType()


//节点状态
const getNodeStatus = () => {
  let nodeIp = [

  ]
  if (baseData.contact != null) {
    serviceAxios({
      url: interfaces.nodePortStatus,
      method: "get",
      params: {
        store_id: baseData.contact[1]
      }
    }).then(
      result => {
        if (result.data.message == 'success') {
          console.log(result.data)
          nodeIp = result.data.data.lan.ip_list == undefined ? null : result.data.data.lan.ip_list
          let online = result.data.data.online
          const nodesList = graphInstance.getNodes()
          if (nodesList.length > 0) {
            nodesList.forEach(item => {
              const model = item.getModel(); // 获取节点的数据模型
              if (!['internet', 'gateway'].includes(model.type)) {
                try {

                  if ((nodeIp != null && nodeIp.includes(model.ip)) || model.ip === undefined) {
                    model.labelCfg.style.fill = "#222831"
                    graphInstance.updateItem(item, model);
                  }

                  else {
                    model.labelCfg.style.fill = "#e23e57"
                    graphInstance.updateItem(item, model);

                  }
                }
                catch (error) {
                  console.log(error)
                }


              }
              else {
                if (model.type == 'gateway') {
                  console.log('gateway', online == '正常', result.data)
                  model.labelCfg.style.fill = online == '正常' ? "#222831" : "#e23e57"
                }
                else {
                  //  console.log("英特网，网关", model)
                  //因特网
                  model.labelCfg.style.fill = "#222831"

                }
                graphInstance.updateItem(item, model);

              }
            })

          }

          //端口

          //getwayModel.
          if (result.data.data.gateway.length > 0) {
            console.log('获取节点数据', graphInstance.findById('root'), graphInstance.findById('gateway'))
            let gateway = graphInstance.findById('gateway');
            let getewayModel = gateway.getModel();
            try {
              result.data.data.gateway.forEach((item, index) => {
                getewayModel.ports[index].status = item.value == "在线" ? true : false
                // graphInstance.updateItem(getwayModel, gateway);
                graphInstance.updateItem(gateway, getewayModel);
              })
            }
            catch (error) {
              console.log(error)
            }
          }

          // graphInstance.render();
          // graphInstance.fitView();
        }
        else {
          clearInterval(timer);
          // message.error(result.data.message)
        }
      }
    ).catch(error => {
      console.log(error)
      clearInterval(timer);
      // message.error("请求错误")
    })

  }

}

//获取拓扑图记录
const getGloptRecord = async (store_id) => {
  const importRecord = await serviceAxios({
    url: '/v1/ops/topological_diagram/import_record/page',
    method: 'get',
    params: {
      store_id: store_id
    }
  })
  console.log('importRecord', importRecord.data.data)
  try {


    if (importRecord.data.message == 'success') {
      options.import_id = importRecord.data.data.list.map(item => ({
        label: item.import_time,
        value: item.record_id
      }))
    }
  }
  catch (error) {
    console.log(error)
    message.error('获取数据失败')
  }
}



let timer = null;
onMounted(() => {
  timer = setInterval(getNodeStatus, 30000);
})
onBeforeUnmount(() => {
  clearInterval(timer);
});
onMounted(() => {
  console.log("route.query", route.query.storeId)

  if (route.query.storeId) {
    let storeId = Number(route.query.storeId)
    baseData.contact = [route.query.brand, storeId]
    serviceAxios({
      url: interfaces.getHostId,
      method: 'get',
      params: {
        "store_id": storeId
      }
    }).then(
      result => {
        if (result.data.message == 'success') {
          getInterface(result.data.data.id, storeId)
          getGloptRecord(storeId)

        }
        else {
          // message.error(result.data.message)
          interfacePorts.value = interfacePorts.value.slice(0, 4)
          data.children[0].ports = interfacePorts.value
          getGplot(storeId)
        }
      }
    )
  }

})



const saveGplot = () => {



  let nodes = graphInstance.getNodes()
  let edges = graphInstance.getEdges()

  let newNodes = []
  let newEdges = []
  nodes.forEach(node => {
    newNodes.push({
      id: node._cfg.model.id,
      label: node._cfg.model.label,
      x: node._cfg.model.x,
      y: node._cfg.model.y,
      size: node._cfg.model.size,
      type: node._cfg.model.type,
      port: node._cfg.model.ports
    })
  })
  edges.forEach(edge => {
    newEdges.push({
      id: edge._cfg.model.id,
      source: edge._cfg.model.source,
      target: edge._cfg.model.target,
      type: edge._cfg.model.type,
      style: { stroke: edge._cfg.model.style.stroke }
    })
  })


  let gplotData = {
    store_id: baseData.contact[1],
    ip: otherData.host_name,
    name: baseData.name,
    topological_structure: data
  }

  console.log("gplotData", gplotData)
  // console.log("gplotData", JSON.stringify(gplotData))
  serviceAxios({
    url: interfaces.save_gplot,
    method: 'post',
    data: gplotData
  }).then(
    result => {
      console.log(result)
      if (result.data.message == 'success') {

        message.success("保存成功")
      }
      else {
        // message.error(result.data.message)
      }
    }
  ).catch(error => {
    console.log(error)
    // message.error("请求异常，请联系管理员") 
  })

}
// 文件输入控件引用
const fileInput = ref(null);
const importGplot = async () => {
  console.log('baseData.contact', baseData.contact)

  const file = fileInput.value.files[0];
  console.log('baseData.contact', baseData.contact, file)
  if (!file) {
    alert('Please select a file first.');
    return;
  }

  if (baseData.contact && baseData.contact.length > 1) {
    const storeId = baseData.contact[1]
    const formData = new FormData();
    formData.append('excel', file);
    // formData.append('store_id', storeId);
    const result = await serviceAxios({
      url: '/v1/ops/topological_diagram/import',
      method: 'POST',
      data: formData,
      params: {
        store_id: storeId
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result.data.message == 'success') {
      getGplot(storeId)
    }
    else {
      message.error(result.data.message)
    }
  }
}


//回滚拓扑图
const recordBack = async () => {
  if (baseData.import_id) {
    const result = await serviceAxios({
      url: '/v1/ops/topological_diagram/import_record/rollback',
      method: 'post',
      data: {
        store_id: baseData.contact[1],
        import_id: baseData.import_id,
        back_or_reimport: baseData.back_or_reimport
      }
    })
    console.log('result.data', result.data)
    if (result.data.message == 'success') {
      message.success('回滚成功')
      getGplot(baseData.contact[1])
    }
  }
  else {
    message.info('请选择拓扑图记录')
  }
}

// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
</script>

<style lang="less" scoped>
@import "@/assets/css/modalTable.css";
.operation-button {
  margin: 2px;
  color: #1884ea;
  cursor: pointer;
}

.import-operation-box {
  display: flex;
  align-items: center;
}

.glopt-record-box {
  display: flex;
  align-items: center;
}
</style>
