<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="电量传感器"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 13 }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">

                <a-select v-if="['send_alert','offline_alert','threshold_alert','is_main'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :options="[{ label: '是', value: 1 }, { label: '否', value: 0 }]"
                          :placeholder="`请选择${item.title}`" />
                <a-select v-else-if="['manufacturer'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          :disabled='true'
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          @change="(value,option)=>selectChange(value,option,item.dataIndex)" />
                <a-select v-else-if="['merchant_no'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :disabled='true'
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          @change="(value,option)=>selectChange(value,option,item.dataIndex)" />
                <a-select v-else-if="['third_part_platform_sensor'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :disabled='baseData.manufacturer && baseData.manufacturer!="沈阳维恩"?false:true'
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          @change="(value,option)=>selectChange(value,option,item.dataIndex)" />
                <a-cascader v-else-if="['customerAndStore'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options[item.dataIndex]"
                            :show-search="{ filter }"
                            @change="storeChange"
                            :placeholder="`请选择${item.title}`" />

                <a-input-number v-else-if="['min_threshold','max_threshold'].includes(item.dataIndex)"
                                v-model:value="baseData[item.dataIndex]"
                                style='width:200px'
                                :placeholder="`请输入${item.title}`" />

                <a-upload v-else-if="['attachments'].includes(item.dataIndex)"
                          v-model:file-list="baseData.fileList"
                          :data="{type:'传感器照片'}"
                          :max-count="1"
                          :action="uploadUrl"
                          list-type="picture-card"
                          :headers="headers"
                          @change="fileHandleChange">
                  <a-button>
                    <upload-outlined></upload-outlined>
                    上传
                  </a-button>
                </a-upload>
                <a-input v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='container'>
    <!-- 详情弹框 -->
    <div class='sensor-detail-modal'
         v-if="showModal.viewSensor">
      <div class='message-box'>
        <img @click='closeModal'
             class='close-image'
             src='@/static/common/关闭.png' />
        <table class='table'
               style='margin-top:10px;'>
          <tr>
            <td class='column'>传感器类型</td>
            <td>{{sensorRef.sensor_category}}</td>
            <td class='column'>别名</td>
            <td>{{sensorRef.alias}}</td>
            <td class='column'>最新数据</td>
            <td>{{sensorRef.last_value.value}} kW·h</td>

          </tr>
        </table>
        <div class='duration-box'>
          <div :class="{
                'selected':item==time_slice,
                'un-selected':item!=time_slice,
               }"
               v-for='item in durationType'
               :key='item.key'
               @click='selectDuration(item)'>{{item}}</div>
        </div>
        <div id='sensorChart'
             class='chart-box'>
          <div class='no-data-style'
               v-if='!sensorRef.data'>无数据传感器数据</div>
        </div>
      </div>
    </div>
    <TableComponent :columns="fieldAll.columns"
                    :data-source="tableSource.data"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    @change="handleTableChange"
                    bordered>
      <template #bodyCell="{ column, record }">
        <template v-if="['names'].includes(column.dataIndex)">
          <div>
            <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
            <a class='editable-row-a'
               @click="edit(record)">编辑</a>
            <a class='editable-row-a'
               @click="deleteData(record.key)">删除</a>
            <a class='editable-row-a'
               @click="viewSensor(record.key)">查看详情</a>

          </div>
        </template>
        <template v-else-if="column.dataIndex === 'status'">
          <div :style='record.status=="失效"?sizeColor:greenColor'>{{ record.status }}</div>
        </template>
      </template>
      <template #title>
        <div class='searchDivContainer'>
          <div>
            <table class="table">
              <tr>
                <td class='column'
                    style='width:50px;'>关键字</td>
                <td>
                  <a-input v-model:value="siftRef.keyword"
                           placeholder="请输入关键字"
                           :bordered="false"
                           style="width:150px"></a-input>
                </td>
                <td class='column'
                    style='width:50px;'>品牌</td>
                <td> <a-select style='width:200px'
                            v-model:value="siftRef.customer_id"
                            :options="options.brand"
                            :bordered="false"
                            :showArrow="false"
                            defaultValue=''
                            show-search
                            :filter-option="filterOption"
                            placeholder="选择品牌"
                            change-on-select /></td>
                <td class='column'
                    style='width:50px;'>门店</td>
                <td>
                  <a-cascader style='width:200px'
                              :bordered="false"
                              :showArrow="false"
                              v-model:value="siftRef.store_id"
                              :options="options.storeAll"
                              defaultValue=''
                              :show-search="{ filter }"
                              placeholder="选择门店"
                              change-on-select />
                </td>
              </tr>
            </table>

          </div>

          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
          <div class='right-buttons'>
            <a-button class="addDataButton"
                      v-if="buttonShow.edit"
                      @click="refresh">刷新</a-button>
            <a-button class="addDataButton"
                      v-if="buttonShow.add"
                      type="primary"
                      @click="addData">添加</a-button>

            <a-button class="addDataButton"
                      v-if="buttonShow.delete"
                      @click="deleteData">删除数据</a-button>
          </div>
        </div>
      </template>
      <!-- <template #footer>Footer</template> -->
    </TableComponent>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import * as echarts from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h, nextTick } from "vue";
import { selector } from '@/utils/selector'
import { useRouter, useRoute } from 'vue-router'
import interfaces from "@/config";
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/iot/electricity_sensor/add',
  delete: '/v1/iot/electricity_sensor/delete',
  edit: '/v1/iot/electricity_sensor/edit',
  page: '/v1/iot/electricity_sensor/page',
  refresh: '/v1/iot/electricity_sensor/page',
  get_by_id: '/v1/iot/sensor/get_by_id'
}
const redColor = { color: 'red' }
const greenColor = { color: 'green' }
const uploadUrl = process.env.VUE_APP_URL + interfaces.ossUpload
const lookPic = process.env.VUE_APP_URL + interfaces.ossGet
const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
})
//确认加载指示符
const spinning = ref(false);
const durationType = ['天', '周', '月', '年']
const time_slice = ref('月')

//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

const siftRef = ref({
  keyword: null,
  customer_id: null,
  store_id: null
})

// const getButton = () => {
//   let route = useRoute()
//   buttonShow = commonFun.getButtonRules(route.fullPath, url)
// }
// getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "品牌",
      dataIndex: "customer_name",
      key: "customer_name",
      ellipsis: true,

    },
    {
      title: "门店",
      dataIndex: "store_name",
      key: "store_name",
      ellipsis: true,

    },
    {
      title: "厂商",
      dataIndex: "manufacturer",
      key: "manufacturer",
      ellipsis: true,

    },
    {
      title: "组",
      dataIndex: "group_id",
      key: "group_id",
      ellipsis: true,

    },

    {
      title: "传感器序列号",
      dataIndex: "sensor_sn",
      key: "sensor_sn",
      ellipsis: true,

    },
    {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
      ellipsis: true,
    },

    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true,
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      ellipsis: true,
    },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [
    {
      title: "门店",
      dataIndex: "customerAndStore",
      key: "customerAndStore",
    },
    {
      title: "传感器厂商",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "商户",
      dataIndex: "merchant_no",
      key: "merchant_no",
    },
    // {
    //   title: "平台门店",
    //   dataIndex: "third_part_platform_store",
    //   key: "third_part_platform_store",
    // },
    {
      title: "第三方平台传感器",
      dataIndex: "third_part_platform_sensor",
      key: "third_part_platform_sensor",
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
    },

    {
      title: "组ID",
      dataIndex: "group_id",
      key: "group_id",
    },
    {
      title: "传感器序列号",
      dataIndex: "sensor_sn",
      key: "sensor_sn",
    },
    {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
    },

    {
      title: "传感器名称",
      dataIndex: "sensor_name",
      key: "sensor_name",
    },
    {
      title: "模式类型",
      dataIndex: "mode_type",
      key: "mode_type",
    },




    {
      title: "是否开启阈值报警",
      dataIndex: "threshold_alert",
      key: "threshold_alert",
    },
    {
      title: "是否开启离线报警",
      dataIndex: "offline_alert",
      key: "offline_alert",
    },
    {
      title: "最小报警阈值",
      dataIndex: "min_threshold",
      key: "min_threshold",
    },
    {
      title: "最大报警阈值",
      dataIndex: "max_threshold",
      key: "max_threshold",
    },
    {
      title: "单位",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "是否为主传感器",
      dataIndex: "is_main",
      key: "is_main",
    },
    {
      title: "传感器照片",
      dataIndex: "attachments",
      key: "attachments",
    },
  ]
})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: [],
})

//增加或者编辑的模态框规则
const rules = {
  customerAndStore: [
    {
      required: true,
      message: "请输入uuid",
    },
  ],
  is_main: [
    {
      required: true,
      message: "请输入uuid",
    },
  ],
  uuid: [
    {
      required: true,
      message: "请输入uuid",
    },
  ],

  group_id: [
    {
      required: true,
      message: "请输入组ID",
    },
  ],
  sensor_sn: [
    {
      required: true,
      message: "请输入传感器SN号",
    },
  ],
  alias: [
    {
      required: true,
      message: "请输入别名",
    },
  ],
  customer_id: [
    {
      required: true,
      message: "请选择品牌",
    },
  ],
  store_id: [
    {
      required: true,
      message: "请选择门店",
    },
  ]
}

//选择框数据
const options = reactive({

  brand: [],
  store_id: [],
  customer_id: [],
  storeList: [],
  storeAll: [],
  host: [],
  manufacturer: [],//厂商
  merchant_no: [],//商户
  third_part_platform_store: [],//第三方门店
  third_part_platform_sensor: [],//第三方传感器
});

// 数据模型
const baseData = reactive({
  id: null,
  customerAndStore: null,
  merchant_no: null,
  manufacturer: null,

  uuid: null,
  group_id: 1,
  sensor_sn: null,
  sensor_name: null,
  mode_type: null,
  alias: null,
  desc: null,
  customer_id: null,
  store_id: null,
  threshold_alert: null,
  offline_alert: null,
  min_threshold: null,
  max_threshold: null,
  unit: '℃',
  is_main: null,
  third_part_platform_sensor: null,
  third_part_platform_store: null,
  third_part_platform_merchant: null,

  picture_id: null,
  fileList: [] //附件信息
})

//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false,
  viewSensor: false,
})



let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = async () => {
  const result = await serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  })
  if (result.data.message == 'success' && result.data.data) {
    pagination.total = result.data.data.total;
    tableSource.data = result.data.data.list.map(item => ({
      id: item.id,
      key: item.id,
      alias: item.alias,
      customer_name: item.customer_name,
      group_id: item.group_id,
      store_id: item.store_id,
      store_name: item.store_name,
      manufacturer: item.manufacturer,
      sensor_sn: item.sensor_sn,
      host_alias: item.host_alias,
      tenant_id: item.tenant_id,
      tenant_name: item.tenant_name,
      desc: item.desc,
      create_time: item.create_time,
      picture_id: item.picture_id,
      item: item
    }))
    console.log('tableSource.data', tableSource.data)
  }
  else {
    message.error('无数据')
  }


}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
};

const formRef = ref()


//编辑数据
const edit = async (record) => {
  console.log('record', record);

  try {
    let response = await serviceAxios({
      url: url.get_by_id,
      method: 'get',
      params: { sensor_id: record.id } // 确保参数格式正确
    });
    console.log('response.data.data.message', response)
    if (response.data.message == 'success') { // 假设成功响应的状态码为200且返回的data中有一个success标志
      let data = response.data.data;

      if (data) {
        console.log("record", data);

        // 更新 baseData 对象中的属性
        for (let key in baseData) {
          if (data.hasOwnProperty(key)) { // 确保 data 对象中有这个属性
            baseData[key] = data[key];
          }
        }
        baseData.customerAndStore = [data.customer_id, data.store_id]
        baseData.fileList = baseData.picture_id ? [{
          uuid: baseData.picture_id,
          name: 'LOGO',
          status: 'done',
          origin: true,
          url: lookPic + '?file_id=' + baseData.picture_id,
        }] : []
        urlFlag = url.edit;
        showModal.addOrEdit = true;


      } else {
        console.error('No valid data returned from the server.');
      }
    } else {
      console.error('Server did not return a success response.');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
//

//增加数据
const addData = () => {
  resetForm();
  baseData.group_id = 1
  baseData.send_alert = 1
  baseData.threshold_alert = 1
  baseData.offline_alert = 1
  baseData.is_main = 0
  baseData.min_threshold = 0
  baseData.max_threshold = 10
  baseData.unit = '℃'
  showModal.addOrEdit = true;
  urlFlag = url.add
};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};

//确认删除数据
// const handleOkDelete = () => {
//   // 执行删除操作
//   // console.log("执行删除操作");
//   let ids = [];
//   rowData.forEach((item) => {
//     ids.push(item.id);
//   });
//   serviceAxios({
//     url: url.delete,
//     method: "post",
//     data: {
//       ids: ids,
//     },
//   })
//     .then(
//       (result) => {
//         queryPage()
//       }

//     )
//     .catch((err) => {
//       message.error("请求异常，请联系管理员");
//       console.log(err);
//     });
//   showModal.isDeleteModalVisible = false;
// };



//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let picture_id = null
    if (baseData.fileList && baseData.fileList.length != 0) {
      if (baseData.fileList[0].origin != true) {
        picture_id = baseData.fileList ? baseData.fileList[0].response.data[0].id : null

      } else {
        picture_id = baseData.fileList[0].uuid
      }
    }
    let data = {
      id: baseData.id,
      merchant_no: baseData.merchant_no,
      customer_id: baseData.customerAndStore[0],
      store_id: baseData.customerAndStore[1],
      uuid: baseData.uuid,
      group_id: baseData.group_id,
      sensor_sn: baseData.sensor_sn,
      sensor_name: baseData.sensor_name,
      mode_type: baseData.mode_type,
      manufacturer: baseData.manufacturer,
      alias: baseData.alias,
      desc: baseData.desc,
      send_alert: baseData.send_alert,
      threshold: baseData.threshold,
      duration: baseData.duration,
      is_main: baseData.is_main,
      unit: baseData.unit,
      picture_id: picture_id,
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch {
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  for (let key in siftRef.value) {
    if (siftRef.value[key]) {
      queryField[key] = key == 'store_id' ? siftRef.value[key][1] : siftRef.value[key]
    }
  }
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  siftRef.value.keyword = null
  siftRef.value.store_id = null
  siftRef.value.customer_id = null


  queryPage()
}



const refresh = () => {
  queryPage()
}





const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

//init 获取选择框数据
(async () => {
  options.storeAll = await selector.storesUnderTheBrand();
  options.brand = await selector.brand();
  options.customerAndStore = options.storeAll.map(item => ({
    label: item.label,
    value: item.data.brand_id,
    children: item.children
  }));

  options.manufacturer = await selector.electricity_manufacturer()

})()


//门店change 获取第三方平台商户
const storeChange = async (value, option) => {
  if (value && value.length == 2) {
    spinning.value = true
    let res = await selector.third_part_platform_merchant("电量传感器", value[1])
    if (res == false) {
      message.error('请配置电量传感器厂商')
      spinning.value = false
      return
    }
    options.merchant_no = res == false ? [] : res
    const result = await getStoreConfig(value[1])
    console.log('result', result)
    baseData.manufacturer = result.electricity_manufacturer
    baseData.merchant_no = result.electricity_merchant_no
    baseData.store_no = result.electricity_store_no
    baseData.store_name = result.electricity_store_name

    if (!baseData.manufacturer) {
      message.error('请配置传感器厂商')
      spinning.value = false
      return
    }
    if (!baseData.merchant_no) {
      spinning.value = false
      return
    }

    let third_part_platform_storeRes = await selector.third_part_platform_store("电量传感器", baseData.manufacturer, baseData.merchant_no)
    options.third_part_platform_store = third_part_platform_storeRes == false ? [] : res
    // thirdPartData.merchant_no = value


    let sensorRes = await selector.third_part_platform_sensor("电量传感器", baseData.manufacturer, baseData.merchant_no, baseData.customerAndStore[0], baseData.customerAndStore[1])
    options.third_part_platform_sensor = sensorRes == false ? [] : sensorRes
    if (options.third_part_platform_sensor.length == 0) {
      message.info('无第三方传感器数据，请手动输入传感器数据')
    }
    spinning.value = false
  }

}

const sensorChartOption = {
  title: {
    text: '电量图'
  },
  grid: {
    top: '15%',
    bottom: '0%',
    left: '10%',
    right: '10%',
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'category',
    // axisLabel: {
    //   fontSize: 13,
    //   color: '#2B7BF8',
    // },
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value',

  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130],
      type: 'bar',
      // showBackground: true,
      // backgroundStyle: {
      //   color: 'rgba(180, 180, 180, 0.2)'
      // },
      barWidth: '40%',
      // label: { // 添加此部分配置
      //   normal: {
      //     show: true,
      //     position: 'top',
      //     formatter: '{c}', // 显示的数据格式
      //     color: '#000', // 文字颜色
      //     fontSize: 12 // 文字大小
      //   }
      // },
      itemStyle: {
        normal: {
          color: '#2B7BF8',
          barBorderRadius: 5,
        },
        emphasis: {

          barBorderRadius: 5,
          shadowBlur: 20,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowOffsetX: 0,
          shadowOffsetY: 10
        }
      },
    }
  ]
};

//根据门店id获取门店物联网服务配置
const getStoreConfig = async (storeId) => {
  let result = null
  const res = await serviceAxios({
    url: '/v1/iot/store_config/get_by_id',
    method: 'get',
    params: {
      store_id: storeId
    }
  })

  if (res.data.message == 'success') {
    result = {
      electricity_manufacturer: res.data.data['electricity_manufacturer'],
      electricity_merchant_no: res.data.data['electricity_merchant_no'],
      electricity_store_no: res.data.data['electricity_store_no'],
      electricity_store_name: res.data.data['electricity_store_name'],
    }

  }
  else {
    message.error(res.data.message)

  }
  return result
}

const thirdPartData = {
  merchant_no: '',//商户
  // store_no: ''//门店
}

const selectChange = async (value, option, text) => {
  console.log(value, option, text)
  spinning.value = true

  if (text == 'third_part_platform_store') {

  }
  if (text == 'third_part_platform_sensor') {
    baseData.uuid = option.data.value.uuid
    baseData.group_id = option.data.value.group_id
    baseData.sensor_sn = option.data.value.sensor_sn
    baseData.alias = option.data.value.alias
    baseData.desc = option.data.value.desc
  }
  spinning.value = false
}

const sensorRef = ref()
const sensorId = ref()
//查看传感器详情
const viewSensor = async (id) => {
  sensorId.value = id
  const res = await serviceAxios({
    url: '/v1/iot/sensor/get_by_id',
    method: 'get',
    params: {
      sensor_id: id,
      time_slice: time_slice.value
    }
  })
  if (res.data.message == 'success') {
    sensorRef.value = res.data.data
    showModal.viewSensor = true

    //获取传感器切片数据
    await energySensorData(id)
    //重载echart
  }
  else {
    message.error(res.data.detail)
  }
}


//获取电量传感器切片数据
const energySensorData = async (id) => {
  const res = await serviceAxios({
    url: '/v1/iot/electricity_sensor/data_slices',
    method: 'get',
    params: {
      time_slice: time_slice.value,
      sensor_id: id,
      duration: 15,
    }
  })
  if (res.data.message == 'success') {
    try {
      const key = Object.keys(res.data.data)[0]
      const data = res.data.data[key]
      sensorChartOption.xAxis.data = data.map(item => item.time)
      sensorChartOption.series[0].data = data.map(item => item.slice_value)
      nextTick(() => {

        let chartDom = document.getElementById('sensorChart')
        console.log('渲染', chartDom)
        initSensorChart();
      });
    }
    catch (error) {
      console.log(error)
      message.error('请求异常，请联系管理员')
    }
  }
  else {
    message.error(res.data.message)
  }
}

let zhuChart = ''
//选择时间周期
const selectDuration = async (type) => {
  console.log(type)
  time_slice.value = type
  await energySensorData(sensorId.value)


  initSensorChart()

}


const initSensorChart = () => {
  let chartDom = document.getElementById('sensorChart')
  console.log('chartDom', chartDom)
  const chart = echarts.init(chartDom);
  chart.setOption(sensorChartOption);
};
//关闭传感器详情 
const closeModal = () => {
  showModal.viewSensor = false
}
</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.sensor-detail-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(27, 27, 27, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .message-box {
    width: 800px;
    height: 600px;
    padding: 40px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .close-image {
      // background: #1890ff;
      margin-left: auto;
      width: 20px;
      height: 20px;

      cursor: pointer;
    }
    .close-image:hover {
      background: gray; /* 当鼠标悬停时，背景颜色变为灰色 */
    }
  }
}
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}

.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}

.chart-box {
  width: 715px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.duration-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  .selected {
    width: 153px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #2b7bf8;
    border: 1px solid #2b7bf8;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  .un-selected {
    width: 153px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #638cbc;
    cursor: pointer;
  }
}
</style>