export const menuIcon = {
  '首页': 'icon-shouye-zhihui',
  '综合管理平台': 'icon-zongheguanli',
  'AIOps智能管理平台': 'icon-AIOPS',
  '资产管理': 'icon-zichanguanli',
  '物联网卡管理': 'icon-wulianwangka',
  '仓储管理': 'icon-cangkucangchu',
  '营帐管理': 'icon-yingzhangxitong',
  '系统管理': 'icon-xitongguanli',
  '泛行业信息管理': 'icon-fanhangye',
  'IOT管理': 'icon-IOT',
  '信发系统': 'icon-navicon-dxfs',
  '人力资源管理': 'icon-renliziyuan'
}