<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <div class='container-page'>
    <div class='title-box'>
      <div class='title'>能耗详情</div>
    </div>
    <div class='main-box'>
      <div class='sensorMessage-box'>
        <div class='alias'>
          {{baseData.alias}}
        </div>
        <div class='right-operation-box'>

          <div class='right-date-box'>

            <!-- <a-space direction="vertical"
                     :size="12">
              <a-config-provider :locale="locale">
                <a-range-picker valueFormat='YYYY-MM-DD'
                                :bordered="false"
                                :showArrow="false"
                                class='date-com'
                                v-model:value="value1" />
              </a-config-provider>
            </a-space> -->
            <view :class="{
                'selected':item==time_slice,
                'un-selected':item!=time_slice,
               }"
                  v-for='item in durationType'
                  :key='item.key'
                  @click='selectDuration(item)'>{{item}}</view>
          </div>

          <img class='left-image'
               @click='setSensor'
               src='@/static/demo/sensorDetails/设置.png' />
          <!-- <img class='right-image'
                 src='@/static/demo/sensorDetails/解除预警.png' /> -->
        </div>

        <div class='up-box'>
          <div class='left-box'>
            <div class='left-up-box'>
              <img class='left-up-image'
                   src='@/static/demo/energy/未标题-1.png' />
              <div class='left-item-content'>
                <div class='item-value'>0</div>
                <div class='item-label'>当前警告数</div>
              </div>
            </div>
            <div class='left-down-box'>
              <img class='left-down-image'
                   src='@/static/demo/energy/趋势.png' />
              <div class='left-item-content'>
                <div class='item-value'>**</div>
                <div class='item-label'>用电趋势</div>
              </div>
            </div>
          </div>
          <div class='box-up-middle'>
            <div>
              <img class='middle-image'
                   src='@/static/demo/sensorDetails/圆.png' />
            </div>
            <div class='now-temperature-value'>
              {{baseData.last_value}}

            </div>
            <div class='now-temperature-title'>
              耗电量(kW·h)({{time_slice}})
            </div>
          </div>
          <div class='right-box'>
            <div id='statisticsChart'
                 style='height:300px;width:100%'
                 :ref='statisticsChart'>
            </div>
          </div>

        </div>

        <div class='down-box'>
          <div class='down-header-box'>
            <view class='header-left-box'>
              <view :class="{
                'selected':item.label==selectedTitle,
                'un-selected':item.label!=selectedTitle,
               }"
                    v-for='item in titleList'
                    :key='item.key'
                    @click='selectItem(item)'>{{item.label}}</view>

            </view>
          </div>

          <div id='sensorChart'
               :ref='sensorChart'
               style='height:300px;width:100%'>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount, nextTick } from "vue";
import { message } from "ant-design-vue";
import * as echarts from "echarts";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;

const route = useRoute();
const router = useRouter();
const customer_id = localStorage.getItem('customer_id')

const routeSensorId = route.query.sensorId
const sensroId = ref(routeSensorId)
const storeId = route.query.storeId
const url = {
  sensorMessage: '/v1/iot/temperature_sensor/get_by_id',//传感器信息
  temFlow: '/v1/iot/temperature_sensor/data' //传感器echart数据
}

const titleList = ref([])
const durationType = ['天', '周', '月', '年']
const time_slice = ref('月')

let selectedTitle = ref()
let pieChart = ''
let zhuChart = ''
const selectItem = async (item) => {
  console.log('item', item)
  selectedTitle.value = item.label
  sensroId.value = item.value
  await getSensorGetById(item.value)
  await getEnergySensorLastdata(item.value)
  zhuChart.setOption(sensorChartOption)

}

//选择时间周期
const selectDuration = async (type) => {
  console.log(type)
  time_slice.value = type
  await getSensorGetById(sensroId.value)
  await getEnergySensorLastdata(sensroId.value)
  try {
    await getPieChart()
  }
  catch (error) {

  }

  pieChart.setOption(statisticsChartOption)
  zhuChart.setOption(sensorChartOption)

}

let statisticsChart = ref()
let statisticsChartOption = {
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   orient: 'vertical',
  //   left: 'left'
  // },
  series: [
    {
      name: '设备用电量(kw·H)',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 1048, name: '排风扇' },
        { value: 735, name: '空调' },
        { value: 580, name: '空调主机' },
        { value: 484, name: '光波炉' },
        { value: 300, name: '瓦锅机' }
      ],
      label: {
        show: true,
        color: '#9eceff',
        fontSize: 21,
        formatter: '{b}: {d}%' // 显示名称和百分比
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
let sensorChart = ref()
let sensorChartOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      fontSize: 13,
      color: '#2B7BF8',
    },
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#FFFFFF'
      }
    },
    splitLine: {
      lineStyle: {
        color: ['rgba(255, 255, 255, 0.1)'],
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130],
      type: 'bar',
      // showBackground: true,
      // backgroundStyle: {
      //   color: 'rgba(180, 180, 180, 0.2)'
      // },
      barWidth: '40%',
      // label: { // 添加此部分配置
      //   normal: {
      //     show: true,
      //     position: 'top',
      //     formatter: '{c}', // 显示的数据格式
      //     color: '#000', // 文字颜色
      //     fontSize: 12 // 文字大小
      //   }
      // },
      itemStyle: {
        normal: {
          color: '#2B7BF8',
          barBorderRadius: 5,
        },
        emphasis: {

          barBorderRadius: 5,
          shadowBlur: 20,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowOffsetX: 0,
          shadowOffsetY: 10
        }
      },
    }
  ]
};

const baseData = reactive({
  last_value: null
})

//获取该门店下的所有传感器数据
const getSensor = async () => {
  const res = await serviceAxios({
    url: '/v1/iot/electricity_sensor/page',
    method: 'get',
    params: {
      store_id: storeId,
      page_num: 1,
      page_size: 99999,
    }
  })
  if (res.data.message == 'success') {
    console.log("传感器信息", res.data)
    titleList.value = res.data.data.list.map(item => ({
      label: item.alias,
      value: item.id
    }))
  }
  else {
    message.error('获取传感器信息失败')
    return
  }
}
getSensor()

const getSensorGetById = async (id) => {
  const res = await serviceAxios({
    url: '/v1/iot/sensor/get_by_id',
    method: 'get',
    params: {
      sensor_id: id,
      time_slice: time_slice.value
    }
  })
  if (res.data.message == 'success') {
    let data = res.data.data
    baseData.last_value = data.last_value.value
    baseData.alias = data.alias
    selectedTitle.value = data.alias
  }
  else {
    message.error('获取传感器信息失败')
    return
  }
}
getSensorGetById(routeSensorId)

const getPieChart = async () => {
  console.log(customer_id, time_slice.value)
  const res = await serviceAxios({
    url: '/v1/iot/electricity_sensor/pie_chart',
    method: 'get',
    params: {
      customer_id: customer_id,
      time_slice: time_slice.value
    }
  })
  if (res.data.message == 'success') {
    statisticsChartOption.series[0].data = res.data.data.map(item => ({
      name: item.category,
      value: item.value
    }))
  }
  else {
    // message.error('请求异常,请联系管理员')
    message.error(res.data.message)
  }
}

const getEnergySensorLastdata = async (id) => {
  const res = await serviceAxios({
    url: '/v1/iot/electricity_sensor/data_slices',
    method: 'get',
    params: {
      sensor_id: id,
      duration: 15,
      time_slice: time_slice.value
    }
  })
  if (res.data.message == 'success') {
    const key = Object.keys(res.data.data)
    const data = res.data.data[key]
    sensorChartOption.xAxis.data = data.map(item => item.slice_name)
    sensorChartOption.series[0].data = data.map(item => item.slice_value)
  }
  else {
    message.error(res.data.message)
  }
}




onMounted(async () => {
  await getPieChart()
  await getEnergySensorLastdata(routeSensorId)
  nextTick(async () => {
    let chartDom = document.getElementById('sensorChart')
    zhuChart = echarts.init(chartDom)
    zhuChart.setOption(sensorChartOption)


    let statistDom = document.getElementById('statisticsChart')
    pieChart = echarts.init(statistDom)
    pieChart.setOption(statisticsChartOption)
  })
  // 在组件挂载时检查 sensorId 是否存在

});
</script>

<style lang='less' scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/common.css";
@import "@/assets/customer/energyDetails.css";
</style>